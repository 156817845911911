import React, {useContext} from "react";
import {MaxLink} from "../../core/components";
import {UserContext} from "../../user/UserContext";

const now = new Date();
export default function EventCompetitions({offers, event, eventJudge, permitted}) {
    const userContext = useContext(UserContext);

    return <>

        {
            offers
                .filter(offer => offer.isCompetitionPart === 2)
                .map(offer => <div key={offer.ID}>
                    <h3>

                        {offer.description}
                    </h3>
                    {
                        (userContext?.user?.role > 10 || permitted || event.eventEnd < now) && <>
                            <MaxLink to={"/bewerbe/" + offer.ID + "/rangliste"}>Rangliste</MaxLink>
                            &nbsp;
                            <MaxLink to={"/bewerbe/" + offer.ID + "/ergebnisse"}>Ergebnisse</MaxLink>
                            &nbsp;
                        </>
                    }
                    {
                        ((event.eventStart < now && now < event.eventEnd && eventJudge) || userContext?.user?.role > 10 || permitted) && <MaxLink to={"/bewerbe/" + offer.ID + "/eingabe"}>Werten</MaxLink>
                    }
                    &nbsp;
                    {
                        (userContext?.user?.role === 100 || permitted) && <MaxLink to={"/bewerbe/" + event.ID}>Bearbeiten</MaxLink>
                    }

                </div>)
        }
    </>
}
