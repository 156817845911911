import {isChrome} from "react-device-detect";

export function downloadAsCsv(content, filename) {
    //const data = new Blob([s2ab(atob(content))], {type: 'text/csv; charset=utf-8', encoding: "UTF-8"});
    const  csvURL = encodeURI(atob(content));
    //const csvURL = window.URL.createObjectURL(data);
    let tempLink = document.createElement('a');
    tempLink.href = "data:text/csv;charset=utf8," + "\uFEFF" + csvURL;
    tempLink.setAttribute('download', filename);
    tempLink.click();
    //document.body.removeChild(tempLink);
}

export function downloadTextAsCsv(content, filename) {
    var csvURL = encodeURI((content["data"]["content"]));

    let tempLink = document.createElement('a');
    tempLink.href = "data:text/csv;charset=utf8," + "\uFEFF" + csvURL;
    tempLink.setAttribute('download', filename);
    tempLink.click();

    //document.body.removeChild(tempLink);
}

function utf8_to_str(a) {
    for (var i = 0, s = ''; i < a.length; i++) {
        var h = a[i].toString(16)
        if (h.length < 2) h = '0' + h
        s += '%' + h
    }
    return decodeURIComponent(s)
}

export function downloadAsExcel(content, filename) {
    // https://stackoverflow.com/questions/34993292/how-to-save-xlsx-data-to-file-as-a-blob
    var bin = atob(content);
    var ab = s2ab(bin);
    const data = new Blob([ab], {type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;base64,'});
    const csvURL = window.URL.createObjectURL(data);
    let tempLink = document.createElement('a');
    tempLink.href = csvURL;
    tempLink.setAttribute('download', filename);
    tempLink.click();
    //document.body.removeChild(tempLink);
}



export function downloadAsZip(content, filename, type = 'application/zip',) {
    var bin = atob(content);
    var ab = s2ab(bin);
    const url = window.URL.createObjectURL(new Blob([ab], {type, encoding: "UTF-8"}));
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', filename); //or any other extension
    document.body.appendChild(link);
    link.click();
    //document.body.removeChild(tempLink);
}

export function downloadAsPdf(content, filename) {
    const data = new Blob([s2ab(atob(content))], {type: 'application/pdf', name: filename});
    const pdfURL = window.URL.createObjectURL(data);
    if (!isChrome) {
        let tempLink = document.createElement('a');
        tempLink.href = pdfURL;
        tempLink.setAttribute('download', filename);
        tempLink.click();
    } else {
        window.location = pdfURL;
    }
}

function s2ab(s) {
    var buf = new ArrayBuffer(s.length);
    var view = new Uint8Array(buf);
    for (var i = 0; i !== s.length; ++i) view[i] = s.charCodeAt(i) & 0xFF;
    return buf;
}

