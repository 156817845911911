import React, {useMemo, useState} from "react";
import {CheckboxInput, EnumInput, LightContainer, MiniBtn, MyModal, SelectfieldInput, TextfieldInput} from "../core/input_fields";
import {FormContextWrapper} from "../core/form_context";
import Consts from "../core/consts";
import {encodeGetParams} from "../core/helpers";
import {apparatusChoices, SteingruberSelect} from "./steingruberConsts";
import {maxiGet} from "../core/maxios";
import {Container, InfoTooltip, Loader, MaxBtn, MaxLink} from "../core/components";
import {downloadAsExcel} from "../core/download";
import {DownloadLists} from "./eventSquadLists";
import useLocalStorage from "../core/useLocalStorage";
import Status from "../core/status";


export default function EventCompetitionResults({event, offers, superuser}) {
    const now = new Date();
    const [{loading, error}, setStatusVar] = useState({});
    if (!superuser && (now < event.eventStart || (event.showCertificatesAfter !== null && event.showCertificatesAfter > new Date()))) {
        return null
    }

    return <LightContainer name={"Ergebnisse"}>
        {
            event.isCompetition === 1 && <>
                {
                    superuser && <CertificateContainer {...{event, offers}} kind={"certificates"}/>
                }
                <CertificateContainer {...{event, offers, superuser}} kind={"lists"}/>
                {/*<CertificateContainer {...{event, offers}} kind={"apparatuslists"}/>*/}
            </>
        }
        {
            superuser &&
            <SquadListsContainer {...{event, offers}} kind={"squads"}/>
        }

        {
            event.isCompetition === 1 && (superuser || ((event.eventEnd > now || Consts.f(true, false)) && event.lastScoresPublic)) && <div className={"report_container"}>
                <h3>Letzte Wertungen</h3>
                <MaxLink to={`/veranstaltungen/${event.ID}/letztewertungen`}>Letzte Wertungen</MaxLink>
                {
                    superuser && <>&nbsp;
                        &nbsp;
                        <MaxLink to={`/veranstaltungen/${event.ID}/fortschritt`}>Fortschritt</MaxLink>
                        &nbsp;
                        &nbsp;
                        <MaxBtn onClick={() => maxiGet(`/turnr/download_scores/${event.ID}?` + encodeGetParams({}), {setStatusVar}).then(a => downloadAsExcel(a.content, "Ergebnisse.xlsx"))}>Wertungen Herunterladen</MaxBtn>
                    </>
                }
                <Loader loading={loading}/>
                <span>
                    <Status type={"error"} text={error}/>
                </span>

            </div>
        }
    </LightContainer>
}

function CertificateContainer({event, offers, kind, superuser}) {
    const [{loading, error}, setStatusVar] = useState({})
    const [state, setState] = useLocalStorage({certificates: {type: "individual", parts: [], guests: 1, design: "", apparatus: undefined, orientation: "portrait"}}, "certificatesConfig-" + kind, 86400 * 30)
    const {type, parts, guests, apparatus, design, orientation, umlauf, forEach, includeBestNInBeginning, marginTop, translateX, translateY, noEventData, noTeamName, noDetailedResults} = state.certificates;
    const link = Consts.API_PREFIX +
        `/turnr/event/${event.ID}/reports/${{lists: type === "perapparatus" ? "apparatus_results" : "results", "apparatuslists": "apparatus_results", "certificates": "certificate"}[kind] || "certificates"}?` +
        encodeGetParams({
            with_guests: guests ?? true,
            competition: event.eventNoExt,
            competition_parts: parts?.length > 0 ? parts.map(a => a.value).join(",") : undefined,
            umlauf: umlauf?.length > 0 ? umlauf.map(a => a.value).join(",") : undefined,
            type: type + (kind.includes("lists") ? "" : ((design || "").replace("standard", ""))),
            //design_params: (design === "_ooeft" && kind.includes("lists") ? "ooeftTitle" : ""),
            translate_x: translateX,
            translate_y: translateY,
            noEventData,
            apparatus,
            orientation,
            includeBestNInBeginning,
            for_each: forEach,
            marginTop,
            noTeamName, noDetailedResults,
        })
    return <FormContextWrapper
        value={{state, setState: a => setState(b => ({...b, ...a})), updateState: (a, b, c) => setState(old => ({...old, [a]: {...old[a], [b]: c}}))}}
        onSubmit={e => e.preventDefault()}
    >

        <div className={"report_container"}>
            <h3>{{lists: "Ergebnislisten", apparatuslists: "Ergebnislisten pro Gerät", certificates: "Urkunden"}[kind]}</h3>

            <span>
            <Status type={"error"} text={error}/>
            </span>

            <SelectfieldInput inline name={"Typ"} tag={"certificates_type"} type="reactselect" selectives={[
                {value: "individual", label: "Einzel"},

                ...((kind !== "lists" || event.ID !== 171 || superuser || (event.showCertificatesAfter !== null && event.showCertificatesAfter < new Date())) ? [{value: "team", label: "Team"}] : []),
                ...(kind === "lists" ? [
                    {value: "perapparatus", label: "Geräteergebnisse",}
                    //{value: "teamstmk", label: "Mannschaft STMK"},
                ] : [
                    {value: "individualapparatus", label: "Einzel pro Gerät"},
                ]),

            ]}/>


            {
                kind.includes("lists") && <div>
                    <b>Orientierung</b>&nbsp;&nbsp;
                    <MiniBtn onClick={() => setState(b => ({certificates: {...b.certificates, orientation: "portrait"}}))}>
                        <div style={{width: "8px", height: "15px", outline: "2px solid white", border: "1px solid var(--mainColor)", backgroundColor: orientation === "portrait" ? "white" : "transparent"}}/>
                    </MiniBtn>
                    &nbsp;
                    <MiniBtn onClick={() => setState(b => ({certificates: {...b.certificates, orientation: "landscape"}}))}>
                        <div style={{width: "30px", height: "15px", outline: "2px solid white", border: "1px solid var(--mainColor)", backgroundColor: orientation === "landscape" ? "white" : "transparent"}}/>
                    </MiniBtn>
                </div>
            }


            {
                kind !== "certificates" && <div>
                    <CheckboxInput name={"Gäste inkludieren"} tag={"certificates_guests"}/>
                </div>
            }
            {
                kind === "lists" && type === "perapparatus" && <SteingruberSelect f={"apparatus"} scope={"certificates"} fieldName={"Gerät"} toSelect={apparatusChoices}/>
            }
            {
                kind === "certificates" && <SelectfieldInput inline name={"Vorlage"} tag={"certificates_design"} type="reactselect" selectives={[
                    {value: "standard", label: "Standard"},
                    {value: "_ooeft", label: "OÖFT"},
                    ...(kind === "certificates" ? [
                        {value: "_centered", label: "zentriert"},
                        ...(type === "team" ? [{value: "_minicentered", label: "minimal zentriert"}] : []),
                        {value: "_ooetb", label: "OÖTB Turn10®"},
                        {value: "_oetbbund", label: "ÖTB Bundesmeisterschaft"},
                    ] : []),
                    // {value: "oeft", label: "ÖFT"},
                ]}/>
            }

            <div style={{display: "inline-block", height: "auto"}}>
                <EnumInput inline name={"Wettkampfteile"} tag={"certificates_parts"} type="reactselect" autosize multiple selectives={offers
                    .filter(o => [1, 2].includes(o.isCompetitionPart))
                    .filter(o => (o.individualOrTeam === "T" && state.certificates.type.includes("team")) || (o.individualOrTeam === "E" && (state.certificates.type.includes("individual") || state.certificates.type.includes("perapparatus"))))
                    .map(a => ({value: a.ID_ext, label: a.description}))
                }/>
            </div>
            {
                offers.filter(o => o.isCompetitionPart === 3).length > 0 && <div>
                    <EnumInput inline name={"Umlauf"} tag={"certificates_umlauf"} type="reactselect" autosize multiple selectives={offers.filter(o => o.isCompetitionPart === 3).map(a => ({value: a.ID, label: a.description}))}/>
                </div>
            }
            <br/>
            <a className="maxbtn" href={link} target={"_blank"}>Herunterladen</a> &nbsp;
            {
                kind === "certificates" && <MyModal trigger={<MaxBtn>Zusätzliche Einstellungen </MaxBtn>}>
                    <Container name={"Einstellungen"}>
                        <form>
                            <div>
                                <CheckboxInput name={"Gäste inkludieren"} labelWidth={220} tag={"certificates_guests"}/>
                            </div>
                            {
                                type === "team" && <div style={{display: "inline-block"}}><CheckboxInput tag={"certificates_forEach"} labelWidth={220} name={"Für jeden?"}/></div>
                            }
                            <div style={{display: "block", height: "auto", paddingTop: 10}}><TextfieldInput tag={"certificates_includeBestNInBeginning"} labelWidth={220} name={<>
                                Ränge vorne&nbsp;
                                <InfoTooltip>
                                    <p>
                                        Anzahl an Rängen pro Wertungsklassen, deren Urkunden am Anfang gedruckt werden (sortiert nach Wertungsklasse und Rang). Anschließend werden die restlichen Urkunden nach Verein, Wertungsklasse, Rang sortiert gedruckt.
                                    </p>
                                    <p>
                                        Ohne Eingabe werden die Urkunden nach Wertungsklasse, Rang geordnet.
                                    </p>
                                </InfoTooltip>
                            </>} ph={3}/></div>

                            {
                                (design.includes("centered") || ["team_ooetb"].includes(design)) && <div style={{display: "inline-block", height: "auto", paddingTop: 10}}><TextfieldInput tag={"certificates_marginTop"} labelWidth={220} name={"Abstand oben"} ph={"[mm]"}/></div>
                            }
                            <div style={{display: "block", height: "auto", paddingTop: 0}}><TextfieldInput tag={"certificates_translateX"} labelWidth={220} name={<>Horizontal verschieben <InfoTooltip>Erhöht die Rechenzeit zum Teil stark.</InfoTooltip></>} ph={"[mm]"}/></div>
                            <div style={{display: "block", height: "auto", paddingTop: 0}}><TextfieldInput tag={"certificates_translateY"} labelWidth={220} name={<>Vertikal verschieben <InfoTooltip>Erhöht die Rechenzeit zum Teil stark.</InfoTooltip></>} ph={"[mm]"}/></div>
                            <div style={{display: "block", height: "auto"}}><CheckboxInput tag={"certificates_noEventData"} labelWidth={220} name={"Veranstaltungs-Name/Datum ausblenden"}/></div>

                            <div style={{display: "block", height: "auto", paddingTop: 0}}><CheckboxInput tag={"certificates_noTeamName"} labelWidth={220} name={"Mannschaftsname ausblenden"}/></div>
                            <div style={{display: "block", height: "auto", paddingTop: 0}}><CheckboxInput tag={"certificates_noDetailedResults"} labelWidth={220} name={"Ergebnisse ausblenden"}/></div>

                        </form>
                    </Container>
                </MyModal>
            }

            {
                kind === "lists" && <MaxBtn onClick={() => maxiGet(`/turnr/download_results/${event.ID}?` + encodeGetParams({
                    umlauf: umlauf?.length > 0 ? umlauf.map(a => a.value).join(",") : undefined,
                    type: state.certificates.type,
                }), {setStatusVar}).then(a => downloadAsExcel(a.content, "Ergebnisse.xlsx"))}>als Excel Herunterladen</MaxBtn>

            }
            &nbsp;
            <Loader loading={loading}/>
            <br/>
        </div>
    </FormContextWrapper>
}


function SquadListsContainer(
    {
        event, offers, kind
    }
) {
    const [state, setState] = useState({certificates: {squadsSelected: [], rotation: 1}})
    const {type, squadsSelected, rotation, apparatus} = state.certificates;
    const link = Consts.API_PREFIX +
        `/turnr/event/${event.ID}/reports/${{squads: "squads"}[kind] || "certificates"}?` +
        encodeGetParams({
            rotation,
            competition: event.eventNoExt,
            squads: squadsSelected?.length > 0 ? squadsSelected.map(a => a.value).join(",") : undefined,
        })

    const [{error, loading, success}, setStatusVar] = useState({});
    const [squads, setSquads] = useState([]);
    const load = () => maxiGet(`/turnr/event/${event.ID}/squads`, {setStatusVar}).then(setSquads);
    useMemo(() => event.ID && load(), [event.ID])

    const umlaufExistent = offers.filter(o => o.isCompetitionPart === 3).length > 0
    return <FormContextWrapper
        value={{state, setState: a => setState(b => ({...b, ...a})), updateState: (a, b, c) => setState(old => ({...old, [a]: {...old[a], [b]: c}}))}}
        onSubmit={e => e.preventDefault()}
    >

        <div className={"report_container"}>
            <h3>{{squads: "Riegen-/Kampfrichter-/Umlaufeinteilung"}[kind]}</h3>

            <MaxLink to={`/veranstaltungen/${event.ID}/riegenzuordnung`}>Riegenzuordnung</MaxLink>
            &nbsp;
            <MaxLink to={`/veranstaltungen/${event.ID}/kampfrichterzuordnung`}>Kampfrichterzuordnung</MaxLink>
            &nbsp;
            <MaxLink to={`/veranstaltungen/${event.ID}/umlaufzuordnung/teilnehmer`}>Umlaufzuordnung Teilnehmer</MaxLink>
            &nbsp;
            <MaxLink to={`/veranstaltungen/${event.ID}/umlaufzuordnung/kampfrichter`}>Umlaufzuordnung Kampfrichter</MaxLink>
            &nbsp;
            <MaxLink to={`/veranstaltungen/${event.ID}/gäste`}>Gäste bestimmen</MaxLink>
            &nbsp;
            {
                umlaufExistent &&
                <SelectfieldInput inline name={"Umlauf"} tag={"certificates_umlauf"} type="reactselect" selectives={[{value: undefined, label: "alle"}, ...offers.filter(o => o.isCompetitionPart === 3).map(o => ({value: o.ID, label: o.description}))]}/>
            }
            <br/>

            <DownloadLists {...{offers, event, judgeMode: false, umlaufMode: false, umlauf: state.certificates.umlauf, squads: state.certificates.umlauf ? squads.filter(s => s.umlauf === state.certificates.umlauf) : squads}}/>
            <br/>
            <DownloadLists {...{offers, event, judgeMode: true, umlaufMode: false, umlauf: state.certificates.umlauf, squads: state.certificates.umlauf ? squads.filter(s => s.umlauf === state.certificates.umlauf) : squads}}/>
            {
                umlaufExistent && <>
                    <br/>
                    <DownloadLists {...{offers, event, judgeMode: false, umlaufMode: true, umlauf: state.certificates.umlauf, squads: state.certificates.umlauf ? squads.filter(s => s.umlauf === state.certificates.umlauf) : squads}}/>
                </>
            }
        </div>
    </FormContextWrapper>
}

