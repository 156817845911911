import React, {useMemo, useState} from "react";
import {LightContainer, MiniBtn, MyModal, MyReactTable} from "../core/input_fields";
import {maxiGet, maxiPost} from "../core/maxios";
import Consts from "../core/consts";
import Status from "../core/status";
import {Container, Loader, MaxBtn} from "../core/components";
import PersonList from "../user/personList";
import {OfferTypeMap, PersonTypeMap} from "../core/enums";
import {FaAngleRight, FaAngleDown} from "react-icons/all"
import {dateFormat} from "../core/dateFuncs";

function countRecursive(o) {
    const tmp = Array.isArray(o) ? o : o.suboffers;
    if (tmp?.reduce) {
        return tmp.reduce((sum, obj) => sum + countRecursive(obj), 0)
    }
    return 1
}

export default function OffersShow({registeredPersons: registeredPersonsRaw, registration, offers, event, eventPermitted, loadRegistrationOffers, registeredPersonOffers, sum}) {
    const registeredPersons = registeredPersonsRaw.reduce((obj, curr) => ({...obj, [curr.registeredPerson.ID]: curr}), {})

    const [showOnlyAllowedOffers, setShowOnlyAllowedOffersRaw] = useState(localStorage.getItem("showOnlyAllowedOffers") !== "false" && offers.length > 4);
    const setShowOnlyAllowedOffers = (a) => setShowOnlyAllowedOffersRaw(() => (localStorage.setItem("showOnlyAllowedOffers", a) || true) && a);

    const ids = registeredPersonsRaw.map(a => a.registeredPerson.ID).join(",");
    useMemo(loadRegistrationOffers, [ids, registration?.sonderbuchung?.price])

    const offersCount = useMemo(() => countRecursive(offers), [offers]);

    return <>

        <LightContainer>
            <h1>
                <div style={{float: "right", marginTop: "-10px"}}>
                    {
                        (event.name.toLowerCase().includes("turn10") || event.name.toLowerCase().includes("turn 10")) && registration.editable &&
                        <AutomaticAssociation {...{event, registeredPersons, registration, registeredPersonOffers, offers, reload: loadRegistrationOffers}}/>
                    }
                    &nbsp;
                    {
                        offers.reduce((obj, curr) => obj + 1 + (curr.suboffers?.length || 0), 0) > 4 && <MaxBtn onClick={() => setShowOnlyAllowedOffers(!showOnlyAllowedOffers)}>Unerlaubte {showOnlyAllowedOffers ? "Ein" : "Aus"}blenden</MaxBtn>
                    }
                </div>
                Auswahlmöglichkeiten
            </h1>
            {/*<div style={{float: "right"}}>
                    <MaxBtn onClick={()=>maxiPost("/turnr/e")}>Personen automatisch zuordnen</MaxBtn>
                </div>*/}
            <OfferPrint {...{offers, offersCount, registeredPersons, registeredPersonOffers, event, eventPermitted, loadRegistration: loadRegistrationOffers, registration, showOnlyAllowedOffers}}/>

        </LightContainer>
        {
            sum !== null &&
            <LightContainer name={"Gesamtsumme"}>
                <b>Die Kosten für diese Meldung belaufen sich auf {Consts.money.format(sum)}.</b>
            </LightContainer>
        }
    </>
}
const now = new Date();


function useLocalStorageState(key, def) {
    const [state, setState] = useState(def)
    const setFolded = a => {
        setState(a)
        console.log(key, a)
        localStorage.setItem(key, JSON.stringify(a))
    }
    useMemo(() => {
        const tmp = localStorage.getItem(key)
        if (tmp) {
            setState(JSON.parse(tmp))
        }
    }, [key])
    return [state, setFolded]
}

function flattenSubtree(offers) {
    return offers.reduce((obj, curr) => [...obj, ...(curr.suboffers ? flattenSubtree(curr.suboffers) : [curr])], [])
}


function OfferPrint(props) {
    //const bookersOfAllOptions = offers.filter(({type}) => type.value === OfferTypeMap.selection).reduce((arr, offer) => [...arr, ...(registeredPersonOffers[offer.ID.toString()]?.map(({registeredPerson_ID}) => registeredPerson_ID) || [])], []);
    return props.offers.map((offer, i) => <OfferPrintSub {...{...props, offer, i}}/>)
}

function OfferPrintSub({offer, i, offers, parentOffer, registeredPersons, registeredPersonOffers, event, eventPermitted, loadRegistration, registration, showOnlyAllowedOffers, bookersMother = [], offersInSelectionTree = [], offersCount, allPlacesUsedUp}) {
    const foldMode = offersCount > 10 && offer.suboffers;
    const [folded, setFolded] = useLocalStorageState(`offer_${offer.ID}_folded`, foldMode)
    const [partsFolded, setPartsFolded] = useLocalStorageState(`offer_${offer.ID}_partsFolded`, true)

    const offersInSelectionTreeForward = offersInSelectionTree.length === 0 ?
        offers.filter(o => o.type.value === OfferTypeMap.selection && o.ID !== offer.ID) :
        [...offersInSelectionTree, offer, ...offersInSelectionTree.reduce((obj, curr) => [...obj, ...(curr.suboffers || []).filter(o => offer.type.value !== OfferTypeMap.optional || o.parent_ID !== offer.ID)], [])]


    const bookersOfAllOptionsIDs = offersInSelectionTreeForward
        .reduce((arr, offer) => [...arr, ...(registeredPersonOffers[offer.ID.toString()]?.map(({registeredPerson_ID}) => registeredPerson_ID) || [])], []);

    const allRequiredSubOptions = [
        ...[{offer, anyOf: offer.suboffers?.filter(o => o.type.value === OfferTypeMap.selection)}],  // offer is mandatory and suboffers are selections // not necessary because with first booking all booking is done
        ...(offer.suboffers ? offer.suboffers.filter(o => o.type.value === OfferTypeMap.mandatory).map(suboffer => ({offer: suboffer, anyOf: suboffer.suboffers})) : []), // suboffers are mandatory and sub sub offers are selections
    ];

    const allSuboffers = flattenSubtree(offer?.suboffers || [])


    if (offers.length === 0) {
        return <>Keine vorhanden.</>
    }

    const bookers = registeredPersonOffers[offer.ID.toString()] || [];
    const bookersIDs = [
        ...(offer.type.value === OfferTypeMap.optional ? [] : bookersOfAllOptionsIDs),
        ...(registeredPersonOffers[offer.ID.toString()]?.map(({registeredPerson_ID}) => registeredPerson_ID) || [])
    ];


    const registeredPersonsAvailable = Object.values(registeredPersons)
        .filter(({registeredPerson}) => !bookersIDs.includes(registeredPerson.ID) &&
            (offer.onlyOfficials === 0 || ((registeredPerson.role.value === PersonTypeMap.official && offer.onlyOfficials === 1) || (registeredPerson.role.value === PersonTypeMap.participant && offer.onlyOfficials === 2)))
        )
        .map(({registeredPerson}) => registeredPerson.ID)

    const bookersRP = bookers.map((booking) => ({
            ...booking,
            ...registeredPersons[booking.registeredPerson_ID]
        })
    );

    const bookedAnySuboffer = bookers.length > 0 ? bookers /* in case this option has a price that the booking of any suboption triggers*/ : allSuboffers.reduce((obj, curr) => [...obj, ...(registeredPersonOffers[curr.ID.toString()] || [])], [])
    const bookedAnySubofferRP = bookedAnySuboffer.map((booking) => ({
            ...booking,
            ...registeredPersons[booking.registeredPerson_ID]
        })
    );

    const missingBookingWarnings = bookedAnySuboffer.reduce((obj, booker) => ({
        ...obj,
        [booker?.registeredPerson_ID]: allRequiredSubOptions.reduce((obj, curr) => {
            if (!curr.anyOf || curr.anyOf.length === 0) {
                return obj
            }
            const any = registeredPersonOffers && curr.anyOf?.some(sso => registeredPersonOffers[sso?.ID.toString()]?.map(rp => rp.registeredPerson_ID).includes(booker.registeredPerson_ID))
            return any ? obj : [...obj, curr.offer.description]
        }, [])
    }), {})
    if ((findAllowedPersons(registeredPersons, offer).length === 0 && showOnlyAllowedOffers)) {
        //console.log(findAllowedPersons(registeredPersons, offer).length === 0 , showOnlyAllowedOffers , !offer.competition_part?.automaticAssignment, offer.competition_part?.automaticAssignment, event.isCompetition === 1 ,bookers.length === 0)
        return null;
    }


    if (offer.onlyOfficials === 1 && (event.bookingOfficialsStart > now || (findAllowedPersons(registeredPersons, offer).length === 0 && offer.isCompetitionPart === 3))) {
        return null
    }

    return <div key={offer.ID}>
        {
            i === 0 && offer.type.value === OfferTypeMap.selection && parentOffer?.type?.value === OfferTypeMap.mandatory && registeredPersonsAvailable.length > 0 && <div style={{color: "red"}}>
                Es {registeredPersonsAvailable.length === 1 ? "muss" : "müssen"} noch {registeredPersonsAvailable.length === 1 ? "eine" : registeredPersonsAvailable.length} Person{registeredPersonsAvailable.length > 1 && "en"} in
                eine dieser Optionen eingebucht werden.
                <br/>
                <br/>
            </div>
        }
        {
            (bookers.length > 0 || offer.suboffers?.length > 0 || registration.editable) &&
            <div style={{
                padding: 15, marginBottom: 15,
                ...(offer.type.value === OfferTypeMap.selection ?
                        {border: "2px solid var(--mainColor)", paddingLeft: 15, backgroundColor: "white"} :
                        {
                            backgroundColor: "rgb(3, 151, 180, 0.2)",
                        }
                )
            }}>

                {
                    !allPlacesUsedUp && (
                        ((offer.suboffers?.length || 0) === 0 && offer.type.value !== OfferTypeMap.mandatory && !event.onlyAutomaticAssociation && registration.editable && offer.bookable && (offer.maxParts - offer.num_bookings > 0)) ?
                            <RegisteredPersonAddWrapper {...{registeredPersons, registeredPersonsAvailable, offer, event, registration, loadRegistration}}/> :
                            <>{!offer.bookable && offer.isCompetitionPart > 0 && <div style={{float: "right", marginTop: -5}}>Anmeldung über Team</div>}</>
                    )
                }


                <h3 style={{margin: "10px 0", cursor: foldMode ? "pointer" : ""}} onClick={() => foldMode && setFolded(!folded)}>
                    {
                        foldMode && (folded ? <FaAngleRight/> : <FaAngleDown/>)
                    }
                    {offer.description}&nbsp;
                    {[1, 2].includes(offer.isCompetitionPart) && <>{{T: "- Team", E: "- Einzel"}[offer.individualOrTeam]}&nbsp;</>}
                    {!offer.isCompetitionPart && `(${offer.type.label})`}&nbsp;
                    {(offer.maxParts < 100 || offer.num_bookings / offer.maxParts > 0.8) && <>(<span style={{color: (offer.maxParts - offer.num_bookings) || allPlacesUsedUp <= 0 ? "red" : undefined}}>
                            {
                                allPlacesUsedUp ? "keine Plätze verfügbar" : <>{Math.max(0, offer.maxParts - offer.num_bookings)} von {offer.maxParts} Plätzen verfügbar</>
                            }
                            </span>)</>}&nbsp;
                </h3>
                {
                    /*offer.competition_part?.automaticAssignment === true && event.isCompetition === 1 && "Nur über Teamteil anmeldbar."
                    * todo: correct
                    * */
                }

                {
                    ((offer.suboffers?.length || 0) === 0 || offer.suboffers[0].price < offer.price) ? <p>
                        {offer.price > 0 && "Kosten: " + Consts.moneyMax(offer.price)}
                        {
                            offer.discountedPrice > 0 && offer.discountedPrice !== offer.price &&
                            <>, nur {Consts.moneyMax(offer.discountedPrice)} {!!event?.prebookingEnd ? <>für Buchungen bis zum {dateFormat(event.prebookingEnd)}</> : "für ÖTB OÖ Mitgliedsvereine"}</>
                        }

                        {
                            offer.type.value !== OfferTypeMap.mandatory ? (bookers.length > 0 && <>
                                {/*gebucht für:*/}
                                <PrintParticipants {...{bookersRP, offer, event, eventPermitted, loadRegistration, bookersMother, registration: {...registration, editable: !event.onlyAutomaticAssociation && registration.editable}, bookers, missingBookingWarnings}}/>
                            </>) : ""
                        }
                    </p> : <>


                    </>
                }
                {
                    offer.type.value === OfferTypeMap.mandatory && offer.parent_ID == offer.ID && bookedAnySuboffer.length != findAllowedPersons(registeredPersons, offer).length && <p style={{color: "red"}}>Achtung! Noch mindestens eine Person hat keine Zuordnung.</p>
                }

                {
                    !(offer.type.value !== OfferTypeMap.mandatory && bookers.length > 0) && (
                        partsFolded ?
                            ((bookersRP.length > 0 ? bookersRP : bookedAnySubofferRP).length === 0 ?
                                <p>Gesamt 0 Personen</p> :
                                <p onClick={() => setPartsFolded(false)} style={{cursor: "pointer"}}>
                                    <FaAngleRight/> Gesamt {PersonCountFormatter(bookedAnySuboffer.length)}
                                </p>) :
                            <>
                                <PrintParticipants {...{
                                    bookersRP: (bookersRP.length > 0 ? bookersRP : bookedAnySubofferRP),
                                    offer,
                                    event,
                                    eventPermitted,
                                    loadRegistration,
                                    registration: {...registration, editable: !event.onlyAutomaticAssociation && registration.editable},
                                    bookers,
                                    missingBookingWarnings
                                }}/>
                                &nbsp; <em onClick={() => setPartsFolded(true)} style={{marginBottom: 6}}>ausblenden</em>
                                <br/>
                                <br/>
                            </>
                    )
                }
                {
                    offer.suboffers && !folded && <OfferPrint {...{
                        offers: offer.suboffers, offersCount,
                        registeredPersons, registration, registeredPersonOffers, showOnlyAllowedOffers,
                        event, eventPermitted,
                        loadRegistration: loadRegistration,
                        bookersMother: [
                            ...(bookersMother ?? []),
                            ...(registeredPersonOffers[offer.ID.toString()] ?? []).map(a => ({...a, offer}))
                        ],
                        offersInSelectionTree: offersInSelectionTreeForward,
                        allPlacesUsedUp: (allPlacesUsedUp || offer.num_bookings >= offer.maxParts),
                    }}/>
                }
            </div>
        }
    </div>
}

function PersonCountFormatter(count) {
    return ({1: "eine"}[count] ?? count) + (count === 1 ? " Person" : " Personen")
}

function RemoveParticipant({bookersMother, loadRegistration, original, offer, registration}) {
    const [{loading, error}, setStatusVar] = useState({});
    const bookerMother = bookersMother?.filter(a => a.registeredPerson_ID === original.registeredPerson_ID) || [];
    const remove = (removeMother = false) => maxiPost(`/registration/${registration.ID}/delete_person_from_offer`, {
        registeredPerson_ID: original.registeredPerson_ID,
        offer_ID: offer.ID,
        removeMother,
    }, {setStatusVar}).then(() => {
        loadRegistration()
    })
    if (bookerMother.length === 0) {
        return <>
            <em onClick={() => window.confirm("Wirklich entfernen?") && remove()}>entfernen</em>
            {error && <MyModal trigger={<span style={{color: "red"}}>{error}</span>}>{error}</MyModal>}
        </>
    }


    return <MyModal trigger={<em>entfernen</em>}>
        <Container name={"Person entfernen?"}>

            <Loader loading={loading}/>
            <Status type={"error"} text={error}/>

            <MiniBtn onClick={() => remove(false)}>entfernen und in Mutteroption „{bookerMother.map(a => a.offer?.description).join(", ")}” eingebucht lassen</MiniBtn>
            <br/>
            <br/>
            <MiniBtn onClick={() => remove(true)}>entfernen und aus Mutteroption „{bookerMother.map(a => a.offer?.description).join(", ")}” ebenfalls entfernen</MiniBtn>
        </Container>
    </MyModal>


}


function PrintParticipants({bookersRP, offer, event, eventPermitted, loadRegistration, bookersMother, registration, bookers, missingBookingWarnings}) {
    const [{error}, setStatusVar] = useState({})


    return <>
        <Status type={"error"} text={error}/>
        <MyReactTable
            data={bookersRP}
            defaultSorted={[{id: "art", desc: true}, {id: "zuordnung", desc: true}, {id: "team"}, {id: "person.fullname"}]}
            columns={[
                {
                    Header: "Name",
                    filterable: true,
                    accessor: "person.fullname",
                },
                {
                    Header: "Art",
                    id: "art",
                    maxWidth: 150,
                    filterable: true,
                    accessor: original => original?.registeredPerson?.role?.value === PersonTypeMap.official ? event.allowOfficialsLabel : "Teilnehmer",
                    show: offer.onlyOfficials === 0 || offer.isCompetitionPart === 3,
                },
                {
                    Header: "Team",
                    id: "team",
                    filterable: true,
                    show: offer.individualOrTeam === "T" || bookersRP.some(a => !!a.registeredPerson?.teamName),
                    accessor: "registeredPerson.teamName",
                },
                {
                    Header: "Vereine",
                    filterable: true,
                    show: registration.club_ID === 0,
                    accessor: "person.clubs",
                },
                {
                    Header: "Kosten",
                    accessor: "price",
                    show: bookers.some(a => a.price > 0),
                    Cell: ({value}) => Consts.moneyMax(value)
                },
                {
                    Header: "Zuordnung",
                    id: "zuordnung",
                    accessor: original => missingBookingWarnings[original.registeredPerson_ID],
                    show: Object.values(missingBookingWarnings).some(a => !!a && a.length > 0),
                    minWidth: Object.values(missingBookingWarnings).some(a => !!a && a.length > 1) ? 250 : 100,
                    Cell: ({row, original, value: warnings}) => {
                        if (!warnings || warnings.length === 0) {
                            return null;
                        }
                        if (warnings.length === 1 && warnings[0] === offer.description) {
                            return "noch keine Auswahl"
                        }
                        return ["keine Auswahl bei ", <MyModal trigger={<em>{warnings.join(", ")}</em>}> {warnings.map(a => <li>{a}</li>)}</MyModal>]
                    },
                },
                {
                    Cell: ({original}) => <>
                        {
                            offer.individualOrTeam === "E" && ((original.registeredPerson?.role?.value === PersonTypeMap.official && (event.bookingOfficialsStart < now && now < event.bookingOfficialsEnd)) || (original.registeredPerson?.role?.value === PersonTypeMap.participant && (event.bookingStart < now && now < event.bookingEnd)) || eventPermitted)
                            && <RemoveParticipant {...{bookersMother, original, loadRegistration, offer, registration}}/>
                        }
                        {
                            offer.individualOrTeam === "T" && <em onClick={() => window.confirm("Wirklich entfernen?") && maxiPost(`/registration/${registration.ID}/delete_team_from_offer`, {
                                teamName: original.registeredPerson?.teamName,
                                offer_ID: offer.ID
                            }, {setStatusVar}).then(() => {
                                loadRegistration()
                            })
                            }>Team entfernen</em>
                        }

                        {
                            offer.individualOrTeam === "T" && bookersRP.some(({person}) => person?.clubs !== bookersRP[0]?.person?.clubs) && <MyModal trigger={<em>Vereins-Anzeige</em>}>
                                <SelectClubNamesToShow team_ID={original?.registeredPerson?.team_ID} bookersRP={bookersRP} event_ID={event.ID}/>
                            </MyModal>
                        }

                    </>,
                    maxWidth: offer.individualOrTeam === "T" ? 270 : 250,
                    show: registration.editable && offer.type?.value !== OfferTypeMap.mandatory,
                },

            ]}
        />
        <br/>
        <b>Gesamt {PersonCountFormatter(bookersRP.length)}</b>

    </>
}

function SelectClubNamesToShow({team_ID, event_ID, bookersRP}) {

    const [selectedClubs, setSelectedClubs] = useState([]);
    const [{loading, error}, setStatusVar] = useState({})


    const clubs = useMemo(() => Array.from(new Set(bookersRP.filter(a => a?.registeredPerson?.team_ID === team_ID).map(a => a.person?.clubs))).sort((a, b) => a < b ? 1 : -1), [])
    return <>
        <Status type={"error"} text={error}/>
        {
            clubs.map(club => <label>
                <input type={"checkbox"} onClick={() => setSelectedClubs(a => ({...a, [club]: !a[club]}))}/>
                {club}
            </label>)
        }
        <MaxBtn onClick={() => {
            const names = clubs.filter(club => selectedClubs[club])
            if (names.length === 0) {
                alert("Es muss zumidnest ein Verein ausgewählt werden")
                return false
            }
            !loading && maxiPost(`/turnr/event/${event_ID}/team/updateClubs`, {team_ID_ext: bookersRP.filter(a => a?.registeredPerson?.team_ID === team_ID)[0]?.registeredPerson?.team_ID_ext, names}, {setStatusVar})
        }}>Speichern</MaxBtn><Loader loading={loading}/>
    </>
}

function findAllowedPersons(registeredPersons, offer) {
    return Object.values(registeredPersons).filter(({registeredPerson, person}) => {

            //console.log(offer.onlyOfficials === 0 , (registeredPerson.role.value === PersonTypeMap.official && offer.onlyOfficials === 1) , (registeredPerson.role.value === PersonTypeMap.participant && offer.onlyOfficials === 2) ,
            // offer.isCompetitionPart === false ,offer.minAge <= person.age , person.age <= offer.maxAge, ["W", "M"][person.sex] === offer.allowedSex)
            return (offer.onlyOfficials === 0 || ((registeredPerson.role.value === PersonTypeMap.official && offer.onlyOfficials === 1) || (registeredPerson.role.value === PersonTypeMap.participant && offer.onlyOfficials === 2))) &&
                (offer.isCompetitionPart === false || (offer.minAge <= person.age && person.age <= offer.maxAge && (["W", "M"][person.sex] === offer.allowedSex || !["M", "W"].includes(offer.allowedSex))))
        }
    )
}


function RegisteredPersonAddWrapper({registeredPersons, registeredPersonsAvailable, offer, event, registration, loadRegistration}) {
    const persons = findAllowedPersons(registeredPersons, offer).sort((a, b) => a.person.fullname < b.person.fullname ? -1 : 1)
    const {competition_part} = offer;

    const teams = useMemo(() => {
        if (offer.individualOrTeam === "E") {
            return
        }
        const teamsRaw = Object.values(persons)
            .map(({person, registeredPerson}) => ({...person, registeredPerson_ID: registeredPerson.ID, registeredPerson}))
            .filter(({person, registeredPerson}) => !!registeredPerson.team_ID)
            .reduce((obj, curr) => ({
                ...obj, [curr.registeredPerson?.teamName]: {
                    teamName: curr.registeredPerson.teamName,
                    participants: [...((obj[curr.registeredPerson?.teamName] || {}).participants || []), curr],
                }
            }), {})

        return Object.values(teamsRaw).map(team => {
            const teamSexCount = team.participants.reduce((obj, curr) => ({...obj, [curr.sex]: (obj[curr.sex] || 0) + 1}), {});
            const teamSex = teamSexCount[0] > 0 && teamSexCount[1] > 0 ? "X" : (teamSexCount[0] > 0 ? "W" : "M")
            const teamAge = team.participants.some(a => a.age > 18) ? Math.min(team.participants.map(a => a.age)) : Math.max(team.participants.map(a => a.age));
            if (competition_part.minTeamSize !== null && competition_part.minTeamSize > team.participants.length) {
                return {...team, allowed: false, disallowedReason: `Team zu klein, mind. ${competition_part.minTeamSize} erforderlich`};
            }
            if (!!competition_part.maxTeamSize && competition_part.maxTeamSize < team.participants.length) {
                return {...team, allowed: false, disallowedReason: `Team zu groß, max. ${competition_part.maxTeamSize} zulässig`};
            }
            if (competition_part.minFemaleParticipants != null && competition_part.minFemaleParticipants > team.participants.filter(a => a.sex === 0).length) {
                return {...team, allowed: false, disallowedReason: `zu wenige weibliche Teilnehmer, min. ${competition_part.minFemaleParticipants} zulässig`};
            }
            if (competition_part.minMaleParticipants != null && competition_part.minMaleParticipants > team.participants.filter(a => a.sex === 1).length) {
                return {...team, allowed: false, disallowedReason: `zu wenige männliche Teilnehmer, min. ${competition_part.minMaleParticipants} zulässig`};
            }
            if (competition_part.allowedSex !== teamSex && competition_part.allowedSex !== "X") {
                return {...team, allowed: false, disallowedReason: `Geschlecht der Team-Mitglieder passt nicht ${competition_part.allowedSex} ${teamSex}`};
            }
            if (competition_part.maxAge < teamAge || competition_part.minAge > teamAge) {
                return {...team, allowed: false, disallowedReason: `Alter der Team-Mitglieder ${teamAge} passt nicht zu dieser Klasse ${competition_part.description}`};
            }

            return {...team, allowed: true}
        })
    }, persons.map(a => a.registeredPerson?.ID))

    return <div style={{float: "right", marginTop: -5}}>
        <MyModal trigger={<MaxBtn>{offer.individualOrTeam === "E" ? "Person" : "Team"} hinzufügen ({teams?.length || persons.length})</MaxBtn>}> {/*.filter(p => registeredPersonsAvailable.includes(p.ID))*/}
            {
                offer.individualOrTeam === "E" ?
                    <RegisteredPersonsAdd {...{
                        persons,
                        personsAvailable: registeredPersonsAvailable,
                        offer, registration, event,
                        reload: loadRegistration
                    }}/> :
                    <RegisteredTeamAdd {...{
                        persons,
                        teams,
                        personsAvailable: registeredPersonsAvailable,
                        offer, registration, event,
                        reload: loadRegistration
                    }}/>
            }
        </MyModal>
    </div>
}

function RegisteredPersonsAdd({persons, personsAvailable, offer, registration, event, close, reload}) {

    const [{error, loading, success}, setStatusVar] = useState({});
    const handleSubmit = (person) => {
        !loading && maxiPost(`/registration/${registration.ID}/add_person_to_offer`, {offer_ID: offer.ID, registeredPerson_ID: person.registeredPerson_ID}, {setStatusVar})
            .then(() => {
                reload && reload()
            })
    }

    return <LightContainer>
        <Status type={"error"} text={error}/>
        <Loader loading={loading}/>
        <PersonList
            persons={Object.values(persons)
                .sort((a, b) => a.person?.fullname > b.person?.fullname ? 1 : -1)
                .map(({person, registeredPerson}) => ({...person, registeredPerson_ID: registeredPerson.ID, registeredPerson}))}
            nameCell={({original, value}) => {
                return personsAvailable.includes(original.registeredPerson_ID) ? <em onClick={() => {
                    handleSubmit(original)
                }}>{value} (hinzufügen) </em> : <>{value} (bereits ausgewählt)</>
            }}
            additionalColumns={[
                {
                    Header: "Art",
                    id: "role",
                    filterable: true,
                    maxWidth: 150,
                    accessor: original => original.registeredPerson.role?.value === PersonTypeMap.official ? event.allowOfficialsLabel : "Teilnehmer"
                }
            ]}
            error={""}
            loadPersons={() => null}
            match={{}}
            loading={false}
        />
    </LightContainer>
}

function RegisteredTeamAdd({persons, personsAvailable, offer, registration, close, reload, teams}) {

    const [{error, loading, success}, setStatusVar] = useState({});
    const handleSubmit = (person) => {
        !loading && maxiPost(`/registration/${registration.ID}/add_team_to_offer`, {offer_ID: offer.ID, teamName: person.teamName}, {setStatusVar})
            .then(() => {
                reload && reload()
            })
    }


    return <LightContainer>
        <h2>Für {offer.description} anmelden</h2>
        <Status type={"error"} text={error}/>
        <Loader loading={loading}/>
        <MyReactTable
            data={teams}
            columns={[
                {
                    Header: "Name",
                    accessor: "teamName",
                    Cell: ({value, original}) => original.allowed ?
                        (original.participants.some(p => !personsAvailable.includes(p.registeredPerson_ID)) ?
                            <>{value} (bereits ausgewählt)</> :
                            <em onClick={() => {
                                handleSubmit(original)
                            }}>{value} (hinzufügen)</em>) :
                        value
                }, {
                    Header: "Ablehnungsgrund",
                    show: teams.some(a => !a.allowed),
                    accessor: "disallowedReason",
                },
                {
                    Header: "Mitglieder",
                    id: "participants",
                    accessor: ({participants}) => <MyModal trigger={<em>{participants.map(a => a.fullname).join(" | ")}</em>}>
                        <ul>
                            {
                                participants.map(a => <li>{a.fullname}</li>)
                            }
                        </ul>
                    </MyModal>
                },
            ]}
        />
    </LightContainer>
}

function AutomaticAssociation({registeredPersons, registeredPersonOffers, registration, event, offers, reload}) {
    const offersMap = offers.reduce((obj, curr) => ({...obj, [curr.ID]: curr}), {})
    const offersOfPerson = Object.entries(registeredPersonOffers).reduce((obj, [key, rps]) => ({...obj, ...rps.reduce((subobj, rp) => ({...subobj, [rp.registeredPerson_ID]: offersMap[key]?.type?.value !== OfferTypeMap.mandatory}), {})}), {})
    const [{error, loading, success}, setStatusVar] = useState({loading: false});
    const [selectedIds, setSelectedIds] = useState({});
    const associate = type => () => !loading && maxiPost(
        `/turnr/event/${event.ID}/registration/${registration.ID}/automatic_assocation/${type}`,
        Object.entries(selectedIds).filter(([key, val]) => val).map(([key, val]) => registeredPersons[key]),
        {setStatusVar}
    ).then((resp) => {
        reload()
        setStatusVar({success: resp.message})
    })
    const data = Object.values(registeredPersons).filter((a) => (!offersOfPerson[a.registeredPerson.ID] && a.registeredPerson.role?.value === PersonTypeMap.participant) || event.onlyAutomaticAssociation || Consts.f(true, true));
    /*if (data?.length < 0) {
        return null
    }*/
    return <MyModal trigger={<MaxBtn>Personen automatisch zuordnen</MaxBtn>}>
        <Container name={"Automatische Zuordnung"}>
            <Status type={"error"} text={error}/>
            <Status type={"success"} text={success}/>
            <MyReactTable
                defaultSorted={[
                    {id: "registeredPerson.teamName"},
                    {id: "person.fullname"},
                ]}
                loading={loading}
                columns={[
                    {
                        Header: "Ausw",
                        maxWidth: 80,
                        Cell: ({original}) => <input
                            type={"checkbox"}
                            checked={selectedIds[original.registeredPerson.ID] ?? false}
                            onChange={a => setSelectedIds(old => ({
                                ...old,

                                ...(!!original.registeredPerson.teamName ?
                                        data.filter(a => a.registeredPerson.teamName === original.registeredPerson.teamName).reduce((obj, curr) => ({...obj, [curr.registeredPerson.ID]: !selectedIds[curr.registeredPerson.ID]}), {}) : {
                                            [original.registeredPerson.ID]:
                                                !selectedIds[original.registeredPerson.ID]
                                        }
                                )
                            }))
                            }
                        />
                    },
                    {
                        Header: "Name",
                        accessor: "person.fullname",
                        filterable: true,
                    },
                    {
                        Header: "Team-Name",
                        accessor: "registeredPerson.teamName",
                        show: data?.some(a => !!a.registeredPerson.teamName),
                        filterable: true,
                    },
                    {
                        Header: "Geburtsjahr",
                        filterable: true,
                        accessor: "person.dateOfBirth",
                        //Cell: ({value}) => value ? dateFormatDateInline(new Date(value)) : "",
                    },
                ]}
                data={data}
            />
            <MaxBtn onClick={associate("Turn10Basis")}>ausgewählte Personen automatisch Turn 10 Basis zuordnen</MaxBtn>
            &nbsp;
            &nbsp;
            <MaxBtn onClick={associate("Turn10Ober")}>ausgewählte Personen automatisch Turn 10 Oberstufe zuordnen</MaxBtn>
            <br/>
            <br/>
            Teams können immer nur gemeinsam automatisch zugeordnet werden. Die automatische Zuordnung löscht die bereits bestehende Zuordnung falls vorhanden.
        </Container>
    </MyModal>
}
