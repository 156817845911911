import React, {useContext, useEffect, useRef, useState} from "react";
import Player from "@vimeo/player";
import {TextfieldInput} from "../../core/input_fields";
import {maxiPost} from "../../core/maxios";
import {Club} from "../../core/custom";
import {FormContext} from "../../core/form_context";

export default function VideoR({content, data, editMode, slot, showing, ...rest}) {
    const context = useContext(FormContext);

    const [showIFrame, setShowIFrame] = useState((data || {}).thumbnailID === undefined);
    const [playerGlob, setPlayerGlob] = useState(null);
    const [videoName, setVideoName] = useState(null);
    const videoContainer = useRef();
    const imageContainer = useRef();

    useEffect(() => {
        if (showIFrame && !editMode && content.includes("vimeo")) {
            //console.log(videoContainer);
            if (imageContainer.current) {
                imageContainer.current.style.display = "none";
            }
            if (videoContainer.current) {
                videoContainer.current.style.width = "480px";
                videoContainer.current.style.height = "260px";
                window.setTimeout(() => {
                    videoContainer.current.style.width = "auto";
                    videoContainer.current.style.height = "auto";
                }, 300);
            }
            const player = new Player(videoContainer.current, {
                id: content.slice(31),
                width: 480,
                height: 270,
                autoplay: (data || {}).thumbnailID !== undefined,
                title: true,
                color: Club.color.replace("#", ""),
            });
            player.on("ended", () => maxiPost("/page_blocks/" + rest.ID + "/add_history_entry", {type: "view", text: "fertig angeschaut"}))
            setPlayerGlob(player)
            playerGlob && playerGlob.getVideoTitle().then(setVideoName)
        }
    });
    if (editMode) {
        return <>
            {
                <TextfieldInput name={"Video URL"} tag={"block_content"} style={{width: "400px"}}/>
            }
        </>
    } else {
        if (content.includes("youtube") || content.includes("youtu.be")) {
            const s = content.split("/");
            return <iframe width="480" height="270" src={"https://www.youtube.com/embed/" + s[s.length - 1]} frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen/>
        }
        if (!content.includes("vimeo")) {
            return <iframe src={content} width="480" height="270" frameBorder="0" allow="autoplay; fullscreen" allowFullScreen/>
        }
        const thumbUrl = "https://i.vimeocdn.com/video/" + ((data || {}).thumbnailID) + ".jpg?mw=480&mh=270&q=70";
        return <>
            <h3>{videoName}</h3>
            <div ref={videoContainer} style={{opacity: showing ? 1 : 0.5}}/>
            {showIFrame ?
                <>
                    {/*<!--<iframe src={content} width="480" height="270" frameBorder="0" allow="autoplay; fullscreen"
                        allowFullScreen/>-->
                        */}

                </> :
                /*<img ref={imageContainer} onClick={() => setShowIFrame(true)} className={"videoContainer"} width="100%" height="100%" style={{cursor: "pointer"}} src={"https://i.vimeocdn.com/video/" + ((data || {}).thumbnailID || 869648478) + ".jpg?mw=480&mh=270&q=70"}/>*/
                <img ref={imageContainer} onClick={() => setShowIFrame(true)} className={"videoContainer"} width="480" height="270" style={{cursor: "pointer"}} src={thumbUrl}/>
            }
        </>
    }
}

