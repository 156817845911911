import React, {useContext, useState} from "react";

import {Container, Loader, MaxBtn} from "../core/components";
import {LightContainer, MyModal, TextfieldInput} from "../core/input_fields";
import {FormContextWrapper} from "../core/form_context";
import {maxiPost} from "../core/maxios";
import Status from "../core/status";
import UserHistory from "../user/userHistory";
import "./style.sass"
import ChangeAddressExt from "../user/addressExt";
import {UserContext} from "../user/UserContext";

const fields = {
    recipient: "Name des Ansprechpartners",
    //...Consts.f({recipientAddition: "Verein"}, {}),
    zvr: "ZVR Nummer",
    email: "E-Mail-Adresse",
    // country: "Land",
};


export default function BillingAddressEdit({billingAddress, reload, close}) {
    const [{error, loading, success}, setStatusVar] = useState({});
    const [state, setState] = useState({billingAddress})
    const [selectClubs, setSelectClubs] = useState(null);
    const user = (useContext(UserContext) || {}).user || {}
    const handleSubmit = (club_ID = null) => {
        !loading && maxiPost("/billing_address", {...state.billingAddress, club_ID}, {setStatusVar})
            .then((resp) => {
                if (resp.select_clubs) {
                    setSelectClubs(resp.select_clubs)
                } else {
                    reload()
                    close()

                }
            })
    }

    return <Container name={"Rechnungsadresse bearbeiten"}>
        <Status type={"error"} text={error}/>
        {
            selectClubs && <MyModal defaultOpen={true} key={Math.random()}>
                <LightContainer name={"Verein, für den die Rechnungsadresse ist, auswählen"}>
                    {
                        selectClubs.map(({value, label}) => <li><MaxBtn onClick={() => handleSubmit(value)}>{label}</MaxBtn></li>)
                    }
                </LightContainer>
            </MyModal>
        }
        {
            state.billingAddress.club && <p><b>Verein</b>: {state.billingAddress.club}</p>
        }
        <FormContextWrapper value={{state, setState}} onSubmit={()=>handleSubmit()}>
            {Object.entries(fields).map(([f, fieldName], i) => {
                switch (f) {
                    default:
                        return <>
                            <TextfieldInput class={"form-control "} width={480} name={fieldName + (f === "recipient" ? "*" : "")} tag={"billingAddress_" + f}/>
                            <br/>
                        </>;
                }

            })}
        </FormContextWrapper>

        {/* change address needs to be outside of FormContextWrapper as it interferes */}
        <ChangeAddressExt child={billingAddress} required changeAddress={a => {
            setState(old => ({billingAddress: {...old.billingAddress, ...a}}))
        }}/>
        <div>
            <br/>
            <MaxBtn onClick={()=>handleSubmit()}>Speichern</MaxBtn><Loader loading={loading}/>
            <br/>
            {
                billingAddress.ID && user.role > 80 && <LightContainer name={"Veränderungen"}>
                    <UserHistory userID={billingAddress.ID} personType={"billingAddress"}/>
                </LightContainer>
            }
        </div>
    </Container>


}
