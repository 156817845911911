import React, {useContext, useMemo, useState} from "react";

import {Loader, MaxBtn} from "../core/components";
import {LightContainer, MiniBtn, MyModal, MyReactTable, SelectfieldInput} from "../core/input_fields";
import {UserContext} from "../user/UserContext";
import Consts from "../core/consts";
import {OfferType, OfferTypeMap} from "../core/enums";
import OfferEdit from "./offerEdit";
import {FaAngleRight, FaClone, FaInfoCircle, FaPlus} from "react-icons/all";
import {dateFormat, dateFormatTimeShort} from "../core/dateFuncs";
import {maxiGet, maxiPost} from "../core/maxios";
import Status from "../core/status";

import "./style.sass"
import {ageGroup} from "./scores/helper";
import {PersonEditWrapper} from "../user/personHelpers";
import {Link} from "react-router-dom";
import UserHistory from "../user/userHistory";
import {TURNrFunctions} from "./offerHelpers";
import {FormContextWrapper} from "../core/form_context";


export default function OfferList({event_ID, offers, event, reload}) {
    const user = useContext(UserContext)?.user || {}
    const [{error, loading, success}, setStatusVar] = useState({});

    return <LightContainer name={"Optionen"}>
        <Status type={"success"} text={success}/>


        <div id={"optionButtons"}>
            <TURNrFunctions event={event} reload={reload} setStatusVar={setStatusVar} loading={loading}/>
            &nbsp;
            {
                offers.length > 5 && <MaxBtn onClick={() => {
                    if (window.confirm("Wirklich alle Auswahlmöglichkeiten löschen?")) {
                        maxiPost(`/event/${event.ID}/offers/delete`, offers.map(a => a.ID), {setStatusVar}).then(reload)
                    }
                }}>
                    Alle löschen?
                </MaxBtn>
            }
            &nbsp;
            <MyModal trigger={<MaxBtn>Option/Wettkampf/Umlauf hinzufügen</MaxBtn>}>
                {close => <OfferEdit offer={{event_ID, price: 0, discountedPrice: 0, onlyOfficials: 0, description: "", bookable: true}} reload={reload} close={close}/>}
            </MyModal>&nbsp;

        </div>

        <Status type={"error"} text={error}/>
        <MyReactTable
            data={offers}
            pageSizeDef={400}
            columns={[

                {
                    Header: "Bezeichnung",
                    accessor: "description",
                    filterable: offers.length > 10,
                    Cell: ({value, original}) => <>
                        <div style={{width: 10 * original.level, height: 10, display: "inline-block"}}></div>
                        {original.parent_ID !== original.ID && <FaAngleRight/>}
                        <MyModal trigger={<em>{value} {original.isCompetitionPart > 0 && <> - {original.individualOrTeam}</>}</em>}>
                            {close => <OfferEdit event={event} offer={original} offers={offers} reload={reload} close={close}/>}
                        </MyModal>
                    </>
                },
                {
                    Header: "Art",
                    accessor: "type",
                    filterable: true,
                    Cell: ({value}) => value.label,
                    maxWidth: 120,
                },
                {
                    Header: "Buchbar bis",
                    accessor: "bookingEnd",
                    show: false,
                    Cell: ({value}) => dateFormat(value),
                    maxWidth: 150,
                },
                {
                    Header: "Kosten",
                    accessor: "price",
                    filterable: true,
                    maxWidth: 180,
                    Cell: ({value, original}) => `${Consts.moneyMax(value)} (${Consts.moneyMax(original.discountedPrice)})`
                },
                {
                    Header: "Buchungen",
                    accessor: "num_bookings",
                    filterable: true,
                    maxWidth: 140,
                    Cell: ({original}) => original.isCompetitionPart !== 3 && <>
                        <MyModal trigger={<MiniBtn>{original.num_bookings}{!!original.num_booking_aggr && " / " + original.num_booking_aggr}{original.individualOrTeam === "T" && " / " + original.num_bookings_teams + "T"}</MiniBtn>}>
                            {close => <ShowBookingsOfOption offer_ID={original.ID} offer={original} offers={offers} year={event?.eventStart?.getFullYear()} reload={() => {
                                reload()
                            }}/>}
                        </MyModal>
                        &nbsp;

                        <MyModal trigger={<MiniBtn style={{height: 26, verticalAlign: "bottom"}}><FaPlus/></MiniBtn>}>
                            {close => <OfferEdit offer={{
                                event_ID, parent_ID: original.ID, price: 0, type: OfferType.find(o => o.value === OfferTypeMap.selection), discountedPrice: 0, onlyOfficials: 0, description: ""
                            }} reload={reload} close={close}/>}
                        </MyModal>
                        &nbsp;

                        <MyModal trigger={<MiniBtn style={{height: 26, verticalAlign: "bottom"}}><FaClone/></MiniBtn>}>
                            {close => <OfferEdit offer={{
                                ...original, ID: undefined, parent_ID: original.ID === original.parent_ID ? undefined : original.parent_ID, clone_ID: original.ID,
                            }} reload={reload} close={close}/>}
                        </MyModal>
                    </>,
                },
            ]}
        />

    </LightContainer>
}

function MoveToOffer({offer: moveFrom, offers, selected, reload}) {
    const [state, setState] = useState({})
    const [{error, loading}, setStatusVar] = useState({})
    return <FormContextWrapper value={{state, setState}} onSubmit={() => {
        window.confirm("Wirklich?") && moveFrom.ID!==state.moveTo && maxiPost("/registration/move_persons", {moveFrom, moveTo: state.moveTo, selected}, {setStatusVar}).then(reload)
    }}>
        <Status type={"error"} text={error || ( moveFrom.ID===state.moveTo?"Die Personen können nicht von der Option in die gleiche Option verschoben werden.":"")}/>
        <SelectfieldInput name={"Verschieben nach"} tag={"moveTo"} type={"reactselect"} selectives={[{"label": "Löschen?","value":"delete"}, ...offers.map(o => ({label: o.description, value: o.ID}))]}/>
        <MaxBtn>Alle {selected.length} ausgewählten Personen verschieben</MaxBtn> <Loader loading={loading}/>
    </FormContextWrapper>
}


function ShowBookingsOfOption({offer_ID, offer, offers, reload, year}) {
    const [bookings, setBookings] = useState([]);

    const [{error, loading, success}, setStatusVar] = useState({});

    const [selected, setSelected] = useState([]);
    const [currentlyShown, setCurrentlyShown] = useState([]);
    const tableCount = currentlyShown.length

    const loadBooking = () => maxiGet("/registration/per_offer/persons/" + offer_ID, {setStatusVar}).then(({bookings}) => {
        setBookings(bookings)
    });

    useMemo(() => loadBooking(), [offer_ID])


    const anyTeam = bookings.some(a => !!a.registered_person?.teamName);
    return <LightContainer name={`Buchungen von ${offer.description} (${tableCount || bookings.length})`}>
        <Status type={"error"} text={error}/>

        <MaxBtn onClick={() => setSelected(currentlyShown.map(a => a.registered_person?.ID))}>
            Alle angezeigten auswählen
        </MaxBtn> &nbsp;
        <MaxBtn onClick={() => setSelected(old => bookings.map(a => a.registered_person?.ID).filter(a => !old.includes(a)))}>
            Auswahl umkehren
        </MaxBtn> &nbsp;
        <MyModal trigger={<MaxBtn>Verschieben</MaxBtn>} onClose={reload}>
            <MoveToOffer {...{offer, offers, selected, reload}}/>
        </MyModal>

        <MyReactTable
            data={bookings}
            loading={loading}
            onTableChange={a => setCurrentlyShown(a.map(b => b._original))}
            defaultSorted={[{id: "person.fullname"}]}
            columns={[
                {
                    id: "selection",
                    width: 20,
                    Cell: ({original}) => {
                        const rpid = original?.registered_person?.ID
                        return (
                            <input
                                type="checkbox"
                                className="checkbox"
                                checked={selected.includes(rpid)}
                                onChange={(e) => setSelected(old => old.includes(rpid) ? old.filter(a => a !== rpid) : [...old, rpid])}
                            />
                        );
                    },
                },
                {
                    Header: "Person",
                    accessor: "person.fullname",
                    filterable: true,
                    Cell: ({original, value}) => <PersonEditWrapper {...{personLight: original.person}}><em>{value}</em></PersonEditWrapper>
                },
                {
                    Header: "Jg",
                    accessor: "person.dateOfBirth",
                    filterable: true,
                    maxWidth: 50,
                },
                ...(offer?.isCompetitionPart === 2 ? [{
                    Header: "AK",
                    id: "AK",
                    accessor: a => ageGroup((year - a.person?.dateOfBirth, offer.ageGroups.split(",").map(a => parseInt(a.trim())))),
                    filterable: true,
                    maxWidth: 60,
                }] : []),
                {
                    Header: "G",
                    id: "sex",
                    accessor: a => ["W", "M"][a.person.sex],
                    filterable: true,
                    maxWidth: 40,
                },
                {
                    Header: "Team",
                    show: anyTeam,
                    accessor: "registered_person.teamName",
                    filterable: true,
                },
                {
                    Header: "Registrant",
                    accessor: "owner.fullname",
                    filterable: true,
                    Cell: ({original, value}) => <PersonEditWrapper {...{personLight: original.owner}}><em>{value}</em></PersonEditWrapper>
                },
                {
                    Header: "Vereine",
                    accessor: "clubs",
                    filterable: true,
                },
                {
                    Header: "Optionen",
                    accessor: "offer.names",
                    filterable: true,
                    Cell: ({original, value}) => <MyModal trigger={<em>{value}</em>}>{value}</MyModal>
                },
                {
                    Header: "Anzahl",
                    accessor: "offer.count",
                    filterable: true,
                    maxWidth: 60,
                },
                {
                    Header: "Kosten",
                    accessor: "registered_person_offer.price",
                    filterable: true,
                    maxWidth: 70,
                    Cell: ({value}) => Consts.moneyMax(value / 100),
                },

                /* {
                     Header: "Abgesendet",
                     accessor: data => data.registration.timeFinalized ? dateFormatTime(new Date(data.registration.timeFinalized)) : "nicht abgeschickt",
                     id: "timeFinalized",
                     filterable: true,
                     //Cell: ({value}) => value ? dateFormatTime(new Date(value)) : "nicht abgeschickt"
                 },*/
                {

                    Header: "Abgesendet",
                    accessor: "registered_person_offer.timeAdded",//data => data.registration.timeFinalized ? dateFormatTime(new Date(data.registration.timeFinalized)) : "nicht abgeschickt",
                    filterable: true,
                    Cell: ({value}) => value ? dateFormatTimeShort(new Date(value)) : "",
                    maxWidth: 150,
                },
                {
                    Header: "",
                    maxWidth: 150,
                    Cell: ({original}) => {
                        return <>
                            <MyModal trigger={<FaInfoCircle/>}><ShowHistoryOfferBooking registered_person_ID={original.registered_person.ID}/></MyModal>
                            &nbsp;
                            <Link to={"/anmeldungen/" + original.registration.ID}>anschauen</Link>
                        </>
                    },
                }
            ]
            }
        />
    </LightContainer>
}

function ShowHistoryOfferBooking({registered_person_ID}) {
    return <LightContainer name={"Protokoll"}>
        <UserHistory personType={"registered_person"} userID={registered_person_ID}/>
    </LightContainer>
}
