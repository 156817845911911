import {Container, InfoTooltip, Loader, MaxBtn} from "../core/components";
import React, {useMemo, useState} from "react";
import {maxiGet, maxiPost} from "../core/maxios";
import {LightContainer, MyModal, SelectfieldInput} from "../core/input_fields";
import Status from "../core/status";
import PersonList from "../user/personList";
import {dateFormatTime} from "../core/dateFuncs";
import {FaWindowClose} from "react-icons/all";
import {PersonEditWrapper} from "../user/personHelpers";
import UserHistory from "../user/userHistory";
import {FormContextWrapper} from "../core/form_context";

export default function EventPermissions({event}) {
    //const [state, setState] = useState({})
    const [{error, loading, success}, setStatusVar] = useState({});
    const [persons, setPersons] = useState([]);
    const [permissions, setPermissions] = useState([])

    const loadPermissions = () => maxiGet(`/event/${event.ID}/permissions`, {setStatusVar}).then(setPermissions)
    useMemo(loadPermissions, [event.ID])
    const permittedPersons = permissions.filter(({permission}) => !permission.timeRevoked).map(({person}) => person.ID)

    return <LightContainer name={"Berechtigung für diese Veranstaltung"}>
        <Status type={"error"} text={error}/>
        <div id={"eventPermissionsButtons"}>

            <MyModal trigger={<MaxBtn>Person hinzufügen</MaxBtn>}>
                <LightContainer name={"Berechtigung erteilen"}>
                    <PersonList
                        persons={persons}
                        error={""}
                        setPersons={setPersons}
                        nameCell={({original, value}) => permittedPersons.includes(original.ID) || original.role > 20 ?
                            <>&nbsp;{value} (bereits erlaubt)</> :

                            <><em onClick={() => {

                            }}></em>
                                <MyModal trigger={<em>{value} (hinzufügen)</em>}>
                                    <EventPermissionAddDialog person_ID={original.ID} event_ID={event.ID} loadPermissions={loadPermissions}/>
                                </MyModal>
                            </>
                        }
                        loadPersons={loadPermissions}
                        match={{}}
                        loading={false}
                    />
                </LightContainer>
            </MyModal>
        </div>
        <ul>
            {
                permissions.map(({person, permission}) => <li style={{opacity: permission.timeRevoked ? 0.4 : 1}}>
                    <PersonEditWrapper personLight={person} reload={loadPermissions}><em>{person.fullname}</em></PersonEditWrapper> - {permission.level?.label} ({permission.timeRevoked ? <>entzogen am {dateFormatTime(permission.timeRevoked)}</> : <>seit {dateFormatTime(permission.timeAdded)}</>})

                    {
                        !permission.timeRevoked && <b style={{cursor: "pointer"}} onClick={() => maxiPost(`/event/${event.ID}/permissions/revoke`, {permission_ID: permission.ID}).then(loadPermissions)}>
                            &nbsp;<FaWindowClose/>
                        </b>
                    }
                    &nbsp;
                    <InfoTooltip>
                        <UserHistory userID={permission.ID} personType={"permission"}/>
                    </InfoTooltip>
                </li>)
            }
        </ul>
    </LightContainer>
}


function EventPermissionAddDialog({person_ID, event_ID, loadPermissions}) {
    const [{error, loading, success}, setStatusVar] = useState({});
    //const add = () =>
    const [state, setState] = useState({})
    return <Container name={"Art der Berechtigung"}>
        <Status type={"error"} text={error}/>
        <FormContextWrapper value={{state, setState}} onSubmit={() => {
            !loading && maxiPost(`/event/${event_ID}/permissions/add`, {person_ID, kind: state.kind}, {setStatusVar}).then(loadPermissions);
        }}>
            <SelectfieldInput type={"reactselect"} tag={"kind"} name={"Art der Berechtigung"} selectives={[
                {
                    value: "eventAllPersons",
                    label: "Zugriff auf Veranstaltung und alle Personen",
                },
                {
                    value: "eventWriter",
                    label: "Zugriff auf Veranstaltung",
                },
                {
                    value: "eventReader",
                    label: "nur Leseberechtigung",
                }
            ]}/>
            <MaxBtn>Erteilen</MaxBtn> <Loader loading={loading}/>
        </FormContextWrapper>
    </Container>
}
