import React, {useContext, useMemo, useState} from "react";
import {Container, Loader, MaxBtn} from "../core/components";
import {maxiGet, maxiPost} from "../core/maxios";
import {CheckboxInput, DateInput, MyModal, MyReactTable, SelectfieldInput, TextfieldInput} from "../core/input_fields";
import {UserContext} from "./UserContext";
import {FormContextWrapper} from "../core/form_context";
import {PersonEditWrapper} from "./personHelpers";
import Status from "../core/status";
import {dateFormat, dateFormatInline} from "../core/dateFuncs";
import ChangeAddressExt from "./addressExt";
import UserHistory from "./userHistory";
import {ListPerClubAndRole} from "./clubs";
import {AddressPrinterFull, encodeGetParams} from "../core/helpers";
import PersonList from "./personList";
import Consts from "../core/consts";

export default function ClubEditWrapper({match}) {
    const club_ID = match.params.club_ID;
    return <ClubEdit club={{ID: club_ID}}/>
}

export function ClubEdit({club, reload}) {
    const [{error, loading}, setStatusVar] = useState({});
    const [state, setState] = useState({club: {}});
    const [isClubAdmin, setIsClubAdmin] = useState(null);
    const {user} = useContext(UserContext);
    const handleSubmit = (e) => {
        e.preventDefault()
        setStatusVar({loading: true})
        maxiPost("/clubs/" + club.ID, {...state.club, type: "club"}, {setStatusVar}).then(resp => {
            reload && reload()
        });
    };
    const loadClub = () => maxiGet(Consts.f("http://10.0.0.5:3000/api/clubs/", "https://meldung.turnfest.at/api/clubs/") + club.ID, {noApi: true}).then(({club, isClubAdmin}) => {
        setState({club})
        setIsClubAdmin(isClubAdmin)
    });
    useMemo(loadClub, [club.ID])
    if (!state.club.ID) {

    }
    const MyTextfieldInput = isClubAdmin ? TextfieldInput : ({name, tag}) => {
        const s = tag.split("_")
        return <div>{state.club[s[1]] && <label><span>{name}</span>{tag === "club_website" ? <a target={"_blank"} rel={"noopener noreferrer"} href={"http://" + state.club[s[1]]}>{state.club[s[1]]}</a> : state.club[s[1]]}</label>}</div>
    }
    return <Container name={"Verein " + (isClubAdmin ? "bearbeiten" : "")}>


                <FormContextWrapper value={{state, setState: a => setState(b => ({...b, ...a}))}} onSubmit={handleSubmit}>
                    <MyTextfieldInput tag={"club_name"} name={"Name"}/><br/>
                    <MyTextfieldInput tag={"club_website"} name={"Website"} ph={"www.turnverein.at"}/><br/>
                    <MyTextfieldInput tag={"club_est"} name={"Gründungsjahr"} ph={"1900"}/><br/>
                    {
                        user?.role > 80 && <>
                            <CheckboxInput tag={"club_ooetb"} name={"OÖTB Verein?"}/>
                            <br/>
                            <CheckboxInput tag={"club_oetb"} name={"ÖTB Verein?"}/>
                            <br/>
                        </>
                    }

                    {
                        isClubAdmin ? <ChangeAddressExt child={state.club} required changeAddress={a => {
                            setState(old => ({club: {...old.club, ...a}}))
                        }}/> : <label><span>Adresse</span><AddressPrinterFull {...state.club}/></label>
                    }

                    <br/>
                    <Status type={"error"} text={error}/>
                    {
                        isClubAdmin && <>
                            <MaxBtn>Speichern</MaxBtn>
                            <Loader loading={loading}/>&nbsp;
                            <MyModal trigger={<MaxBtn>Veränderungen anzeigen</MaxBtn>}>
                                <h2>Veränderungen</h2>
                                <UserHistory userID={club.ID} personType={"club"}/>
                            </MyModal>
                        </>}

                </FormContextWrapper>
                <ClubRoles {...{club, isClubAdmin}}/>
        {
            user?.role > 80 ?
                <ListPerClubAndRole club={{ID: club.ID, "name": state.club.name}}/> :
                <>
                    {!state.club.ID && "Der Verein wurde nicht gefunden oder ist nicht Mitglied im ÖTB OÖ."}
                </>
        }

        <br/>

    </Container>


}

const roleSelection = {
    "410": "Ehrenmitglied",
    "430": "Ehrenobmann",
    "445": "Beirat Tennis",
    "450": "Beirat Tennis Stellvertretung",
    "455": "Beirat Vereinsheim",
    "460": "Beirat Vereinsheim Stellvertretung",
    "465": "Beirat Volleyball",
    "470": "Fitsportwart",
    "475": "Pressewart Stellvertretung",
    "480": "Pressewart",
    "500": "Zeugwart Stellvertretung",
    "501": "Beisitzer",
    "510": "Jugendwart",
    "515": "Jugendwart Stellvertretung",
    "520": "Leiter Arbeitskreis Stellvertretung",
    "525": "Leiter Arbeitskreis",
    "530": "Leiter Stockschützen Stellvertretung",
    "535": "Leiter Stockschützen",
    "540": "EDV-Wart",
    "550": "Skiwart",
    "560": "Fahnenträger",
    "570": "Kassenprüfer",
    "580": "Vorstand/Beiräte",
    "600": "Zeugwart",
    "601": "Fachwart",
    "610": "Fachwart Klettern Stv.",
    "620": "Fachwart Klettern",
    "630": "Fachwart Rhythmische Gymnastik",
    "640": "Fachwart Kinderturnen",
    "650": "Fachwart Turn 10",
    "655": "Hüttenwart Stellvertretung",
    "660": "Fachwart Gruppen-Gymnastik",
    "661": "Hüttenwart",
    "670": "Fachwart Leistungsturnen wbl.",
    "671": "Rechnungsprüfer",
    "680": "Fachwart Leistungsturnen ml.",
    "681": "Hauswart",
    "690": "Fachwart Kondtionstr. & Geräteturnen",
    "694": "Dietwart Stv.",
    "696": "Dietwart",
    "700": "Sportliche Leitung Stellvertretung",
    "701": "Turnwart Stellvertretung",
    "702": "Gerätewart Stellvertretung",
    "703": "Standaufsicht",
    "740": "2. Säckelprüfer/in",
    "750": "1. Säckelprüfer/in",
    "780": "Beirat",
    "790": "Organisation",
    "800": "Sportliche Leitung",
    "801": "Turnausschuss",
    "802": "Turnwart",
    "803": "Gerätewart",
    "804": "Matchwart",
    "810": "Jugendausschuss",
    "820": "Vereinszeitung",
    "850": "Wanderwart",
    "900": "Kassier Stellvertretung",
    "901": "Säckelwart Stellvertretung",
    "1000": "Kassier",
    "1001": "Säckelwart",
    "1100": "Schriftführer Stellvertretung",
    "1101": "Schriftwart Stellvertretung",
    "1200": "Schriftführer",
    "1201": "Schriftwart",
    "1300": "Obfrau/Obmann Stellvertretung",
    "1400": "Obfrau/Obmann",
}

function ClubRoles({club, isClubAdmin}) {
    const [roles, setRoles] = useState([]);
    const [{error}, setStatusVar] = useState({});
    const [deadline, setDeadline] = useState({"deadline": new Date().toISOString().slice(0, 10)})
    const context = useContext(UserContext)
    const loadClub = () => maxiGet("https://meldung.turnfest.at/api/roles/club/" + club.ID + "?deadline=" + deadline.deadline, {setStatusVar, noApi: true}).then(({roles}) => {
        setRoles(roles)
    });
    useMemo(loadClub, [club.ID, deadline.deadline])
    return <>
        {
            isClubAdmin && <div style={{float: "right"}}>
                <MyModal trigger={<MaxBtn>Position hinzufügen</MaxBtn>}>
                    {close =>
                        <ClubAddRole club={club} reload={() => {
                            close()
                            loadClub()
                        }}/>
                    }</MyModal>
            </div>
        }
        <h1>
            Vorstand zum Stichtag <MyModal trigger={<em>{dateFormat(deadline.deadline, true)}</em>}>
            <FormContextWrapper value={{state: deadline, setState: setDeadline}}>
                <DateInput tag={"deadline"} name={"Stichtag"}/>
            </FormContextWrapper>
        </MyModal>
        </h1>
        <MyReactTable
            data={roles}
            columns={[
                {
                    Header: "Position",
                    id: "role",
                    accessor: ({role}) => roleSelection[role.role] || role.role,
                    filterable: true,
                },
                {
                    Header: "Name",
                    accessor: "person.fullname",
                    filterable: true,
                    Cell: ({value, original}) => isClubAdmin ? <PersonEditWrapper personLight={original.person}><em>{value}</em></PersonEditWrapper> : value
                },
                {
                    Header: "Zeitraum",
                    accessor: "role.start",
                    filterable: true,
                    Cell: ({value, original}) => {
                        const text = <>{dateFormatInline(value)} bis {original.role.end ? dateFormatInline(original.role.end) : "heute"}</>;
                        return isClubAdmin ?
                            <MyModal trigger={<em>{text}</em>}>
                                {
                                    close => <ClubEditRole {...{...original}} reload={() => {
                                        close()
                                        loadClub();
                                    }
                                    }/>
                                }
                            </MyModal> :
                            text;
                    }
                }
            ]}
        />
    </>
}


function ClubAddRole({club, reload}) {
    const [state, setState] = useState({role: {}});
    const [persons, setPersons] = useState([]);
    const context = useContext(UserContext)
    const [{error}, setStatusVar] = useState({});
    const loadPersons = (onlyRegistrants = false) => context.user?.role < 80 && maxiGet("/person?" + encodeGetParams({onlyRegistrants}), {setStatusVar, fields: ["fullname", "dateOfBirth", "ID", "clubs"]}).then(({persons}) => {
        setPersons(persons)
    })

    useMemo(loadPersons, [club.ID])
    const submit = () => maxiPost("/roles", {...state.role, person_ID: state.role.person?.ID, club_ID: club.ID}, {setStatusVar}).then(reload)


    return <Container name={"Position hinzufügen"}>

        <Status type={"error"} text={error}/>

        <label><span style={{fontWeight: "bold", width: "170px", display: "inline-block"}}>Person auswählen</span>
            {state.role?.person?.fullname} &nbsp;&nbsp;
            <MyModal trigger={<MaxBtn>auswählen</MaxBtn>}>
                {close => <PersonList
                    persons={persons}
                    setPersons={setPersons}
                    nameCell={({original, value}) => <em onClick={() => {
                        setState(a => ({role: {...a.role, person: original}}))
                        close()
                    }}>{value} (auswählen)</em>}
                    error={""}
                    loadPersons={() => null}
                    match={{}}
                    loading={false}
                />}
            </MyModal></label>
        <FormContextWrapper value={{state, setState}} onSubmit={submit}>

            <SelectfieldInput tag={"role_role"} type={"reactselect"} name={"Position"} selectives={Object.entries(roleSelection).reverse().map(([key, value]) => ({label: value, value: parseInt(key)}))}/>
            <DateInput tag={"role_start"} name={"Beginn"}/>
            <br/>
            <MaxBtn>Hinzufügen</MaxBtn>
        </FormContextWrapper>
    </Container>
}

function ClubEditRole({role, person, reload}) {
    const [state, setState] = useState({role: {...role}});//, role: {label: roleSelection[role.role], value: role.role}
    const [{error}, setStatusVar] = useState({});

    const submit = () => maxiPost("/roles", state.role, {setStatusVar}).then(reload)

    return <Container name={"Position bearbeiten"}>
        <Status type={"error"} text={error}/>
        <FormContextWrapper value={{setState, state}} onSubmit={submit}>
            <label><span>Person</span>
                {person?.fullname} &nbsp;&nbsp;
            </label>
            <SelectfieldInput tag={"role_role"} type={"reactselect"} name={"Position"} readOnly selectives={Object.entries(roleSelection).reverse().map(([key, value]) => ({label: value, value: parseInt(key)}))}/>
            <DateInput tag={"role_start"} name={"Beginn"}/><br/>
            <DateInput tag={"role_end"} name={"Ende"}/>
            <br/>
            <MaxBtn>Speichern</MaxBtn>
        </FormContextWrapper>
        <UserHistory personType={"role"} userID={role.ID}/>
    </Container>
}

