import React from "react"
import {instanceOf} from "prop-types";

function dateFormatUnix(date) {
    if (date === 0) {
        return ""
    }
    let d = new Date(date * 1000);
    //return date_string
    //return d.toLocaleDateString("en-US")
    return new Intl.DateTimeFormat('de-AT', {
        year: 'numeric',
        month: 'short',
        day: '2-digit',

    }).format(d);
}

function dateFormatUnixSmall(date) {
    if (date === 0) {
        return ""
    }
    let d = new Date(date * 1000);
    //return date_string
    return new Intl.DateTimeFormat('de-AT', {
        year: '2-digit',
        month: 'short',
        day: '2-digit',

    }).format(d).split(".").join("").replace("i", "");
}

function dateFormatTime(inp) {
    if (inp) {
        return new Intl.DateTimeFormat('de-AT', {
            year: 'numeric',
            month: 'short',
            day: '2-digit',
            hour: 'numeric',
            minute: "2-digit",
            weekday: "short"
        }).format(new Date(inp));
    }
    return "unbekannt"
}

function dateFormatTimeShort(inp) {
    try {

        if (inp !== 0) {
            return new Intl.DateTimeFormat('de-AT', {
                year: '2-digit',
                month: 'short',
                day: '2-digit',
                hour: 'numeric',
                minute: "2-digit",
            }).format(new Date(inp));
        }
    } catch (e) {
        console.log(e)
    }
    return "unbekannt"
}

function timeFormat(inp) {
    if (!!inp) {
        return new Intl.DateTimeFormat('de-AT', {
            hour: 'numeric',
            minute: "2-digit",
        }).format(inp);
    }
    return "unbekannt"
}

function dateFormat(date, natural = false) {

    try {
        if (!date) {
            throw Error();
        }
        const d = date instanceof Date ? date : new Date(date);
        return new Intl.DateTimeFormat('de-AT', {
            year: 'numeric',
            month: 'short',
            day: natural ? "numeric" : '2-digit',
        }).format(d);
    } catch (e) {
        return ""
    }
}

function dateFormatLong(date) {

    try {
        if (!date) {
            throw Error();
        }
        const d = date instanceof Date ? date : new Date(date);
        return new Intl.DateTimeFormat('de-AT', {
            weekday: "long",
            year: 'numeric',
            month: 'long',
            day: '2-digit',
        }).format(d);
    } catch (e) {
        return ""
    }
}

const monthNames = [
    "Jan.", "Feb.", "Mär.", "Apr.", "Mai", "Jun.", "Jul.", "Aug.", "Sep.", "Okt.", "Nov.", "Dez."
]

function dateFormatUnixInline(date) {
    if (date === 0) {
        return ""
    }
    let d = new Date(date * 1000);
    return dateFormatDateInline(d)
}

const dateFormatDateInline = d => <span className={"dateFormat"}>
        <span>{String("0" + d.getDate()).slice(-2)}.</span>&nbsp;
    <span>{monthNames[d.getMonth()]}</span>&nbsp;
    {d.getFullYear()}
    </span>;

function dateFormatInline(date) {
    try {
        const d = typeof date == "Date" ? date : new Date(date);
        return dateFormatDateInline(d)
    } catch (e) {
        return ""
    }
}

//741

export {dateFormatUnix, dateFormat, dateFormatLong, dateFormatInline, dateFormatTime, timeFormat, dateFormatUnixSmall, dateFormatUnixInline, dateFormatTimeShort, dateFormatDateInline}
