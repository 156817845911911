import React, {useContext} from "react";
import {EditorInput, LightContainer, MyModal, TextfieldInput} from "../core/input_fields";
import {FormContext, FormContextWrapper} from "../core/form_context";
import {Loader, MaxBtn, MaxLink} from "../core/components";
import {apiPost} from "../core/api";
import Status from "../core/status";
import {FaArrowCircleDown, FaArrowCircleUp, FaEye, FaEyeSlash, FaInfoCircle, FaTrash} from "react-icons/fa";
import {dateFormatTime} from "../core/dateFuncs";
import UserHistory from "../user/userHistory";
import DocumentR from "./blocks/document";
import GalleryR from "./blocks/gallery";
import VideoR from "./blocks/video";
import {CloudImageReplacer} from "../core/helpers";


function LinkR({content, editMode, data, ...rest}) {
    const context = useContext(FormContext);

    if (editMode) {
        console.log("editmode")
        return <>
            <TextfieldInput name={"URL"} tag={"block_content"} style={{width: "400px"}}/>
            <TextfieldInput name={"Name"} tag={"data_label"} onChange={(e) => {
                context.setState({block: {...context.state.block, data: {...data, label: e.target.value}}});
            }
            } style={{width: "400px"}}/><br/>
            <MaxLink target={content.includes("://")?"_blank":""} to={content}>Link aufrufen</MaxLink>
        </>
    } else {
        return <a target={content.includes("://")?"_blank":""} href={content}>{data?.label || "Link"}</a>
    }
}


function TextR({content, editMode, data, ...rest}) {
    if (editMode) {
        return <>
            <EditorInput name={"Inhalt"} tag={"block_content"} style={{width: "400px"}}/>
        </>
    } else {
        return <CloudImageReplacer>
            {content}
        </CloudImageReplacer>;

        return <LightContainer name={data.heading}>
        </LightContainer>

    }
}


function withEditableBlock(WrappedComponent) {
    // ...and returns another component...
    return class extends React.Component {
        constructor(props) {
            super(props);
            this.setState = this.setState.bind(this);
            this.handleSubmit = this.handleSubmit.bind(this);
            this.state = {
                block: this.props.block,
                loading: false,
            };
            this.apiPost = apiPost.bind(this);
        }

        handleSubmit = (e) => {
            e.preventDefault();
            this.setState({loading: true});
            this.apiPost("/page_blocks/update", this.state.block, resp => {
                this.setState({loading: false})
            })
        };

        render() {
            const {editMode, block, pageLength, last, first} = this.props;
            const EyeOrSlashed = this.state.block?.showing ? FaEye : FaEyeSlash;
            if (editMode) {

                return <FormContextWrapper value={{state: {...this.state, data: this.state.block.data}, setState: this.setState}} onSubmit={this.handleSubmit}>
                    <div className={"blockMover"}>
                        {
                            <EyeOrSlashed onClick={e => {
                                this.setState({block: {...this.state.block, showing: !this.state.block.showing}})
                                this.apiPost("/page_blocks/update", {...this.state.block, showing: !this.state.block.showing})
                            }}/>
                        }
                        {
                            <FaTrash onClick={() => {
                                if (window.confirm("Möchtest du diesen Block wirklich löschen?")) {
                                    this.apiPost("/page_blocks/delete", {
                                        block_ID: this.state.block.ID,
                                    }, _ => this.props.reload())
                                }
                            }
                            }/>
                        }
                        {
                            !first &&
                            <FaArrowCircleUp onClick={() => {
                                this.apiPost("/page_blocks/move", {
                                    direction: "up",
                                    block_ID: this.state.block.ID,
                                }, _ => this.props.reload())

                            }}/>
                        }
                        {
                            !last &&
                            <FaArrowCircleDown onClick={() => {
                                this.apiPost("/page_blocks/move", {
                                    direction: "down",
                                    block_ID: this.state.block.ID,
                                }, _ => this.props.reload())

                            }}/>
                        }
                        {
                            <MyModal trigger={<FaInfoCircle/>} on={"hover"}>
                                <div>
                                    Zuletzt bearbeitet von: <a href={"/benutzer/profil/" + block.editor_ID}>{block.editor_name}</a>
                                    <br/>
                                    Zuletzt bearbeitet am: {dateFormatTime(block.timeModified * 1000)}
                                    <br/>
                                    Erstellt am: {dateFormatTime(block.timeAdded * 1000)}

                                    <br/>

                                    <br/>
                                    <h3>Veränderungen</h3>
                                    <UserHistory userID={block.ID} personType={"block"}/>
                                </div>
                            </MyModal>
                        }
                    </div>
                    <WrappedComponent {...this.state.block} editMode={editMode}/>
                    <Status type={"error"} text={this.state.error}/>
                    {
                        WrappedComponent.name !== "FormR" && <MaxBtn>Speichern</MaxBtn>
                    }
                    <Loader loading={this.state.loading}/>
                </FormContextWrapper>
            }
            return <WrappedComponent {...this.props.block} />;
        }
    };
}

const Video = withEditableBlock(VideoR);
const Text = withEditableBlock(TextR);
const Document = withEditableBlock(DocumentR);
const Link = withEditableBlock(LinkR);
const Gallery = withEditableBlock(GalleryR);

export default {Video, Text, Document, Link, Gallery}
