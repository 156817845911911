import React, {useMemo, useState} from "react";

export default function AsyncComponentWrapper({myImport, children, ...props}) {
    const [Component, setComponent] = useState(React.Fragment);
    useMemo(() => {
        myImport().then(component => setComponent(component.default));
    }, []);
    return <Component {...props}>
        {children}
    </Component>
}
