export const ageGroups = [0, 7, 9, 11, 13, 15, 17, 19, 22, 25, 30, 35, 40, 45, 50, 55, 60, 65, 70, 75, 80];


export function ageGroup(a, config = ageGroups) {
    if (a >= Math.max(...config)) return Math.max(...config) + "+"
    //if (a >= 25) return Math.floor(a / 5) * 5

    for (let i = config.length - 1; i >= 0; i--) {
        const b = config[i];

        if (a >= b) {
            return b.toString() + "-" + (config[i + 1] - 1 || "");
        }
    }
}

