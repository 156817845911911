import React, {Component, useEffect,} from 'react';
import {BrowserRouter as Router, Route} from "react-router-dom";

import {Switch} from "react-router";
import {Container} from "./core/input_fields";
// ie stuff
import "babel-polyfill";
import {polyfill} from 'es6-promise';
import cssVars from 'css-vars-ponyfill';
import {maxiGet} from "./core/maxios";
import {Club, clubMap, domainMap} from "./core/custom";
import {UserContext} from "./user/UserContext";
import ErrorBoundary from "./core/error_boundary";
import ChangePW from "./user/changepw";
import AsyncComponent from "./core/AsyncComponent";

import Nav from "./nav";
import CheckLogin from "./user/check_login";
import Login from "./user/login";
import UserEdit from "./user/userEdit";
import EventList from "./event/eventList";
import EventView from "./event/eventView";
import BillingAddressList from "./booking/billingAddressList";
import PersonTeamView from "./user/personTeamView";
import RegistrationView from "./booking/registrationView";
import RegistrationList from "./booking/registrationList";
import EventListEmbedded from "./event/eventListEmbedded";
import {ListClubs} from "./user/clubs";
import {ClubEditWrapper} from "./user/clubEdit";
import Consts from "./core/consts";

polyfill();

// end ie stuff
function ScrollToTop({location}) {
    useEffect(() => {
        window.scrollTo(0, 0);
    }, [location.pathname]);
    return null;
}

class App extends Component {
    state = {
        navOpen: localStorage.getItem("mainContainer") === "true",
        navToggle: () => {
            localStorage.setItem("mainContainer", !this.state.navOpen);
            this.setState({navOpen: !this.state.navOpen})
        },
        mounted: false,
        club: Consts.f(Club, (!!localStorage.getItem("vereinID_cache") ? domainMap[clubMap[(parseInt(localStorage.getItem("verein_ID_cache")))]] : Club)),
        clubs: JSON.parse(localStorage.getItem("clubs") || "{}"),
    };

    addUserFromStatus = resp => {
        !!resp.club && this.setCssVars(resp.club);
        this.setState(resp);
    };

    setCssVars = club => cssVars({
        rootElement: document,
        variables: {
            "mainColor": window.VEREINfachtConfig?.color || club.color,
            "maincolorlight": club.colorLight,
            "fontColor": club.fontColor || "#444",
            "fontColorInverse": club.fontColorInverse || "#fff",
        },
    });

    componentWillMount() {
        const club = (!!localStorage.getItem("vereinID_cache") ? domainMap[clubMap[(parseInt(localStorage.getItem("verein_ID_cache")))]] : Club);
        this.setCssVars(club)
    }

    componentDidMount = () => {
        this.clubsLoad()
    };

    clubsLoad = () => {
        if (!window.embeddedMode && (localStorage.getItem("clubsUpdated") === null || (new Date()).getTime() - localStorage.getItem("clubsUpdated") > 1000 * 60 * 30)) {
            maxiGet("/clubs").then(({clubs}) => {
                    const clubsMap = clubs.reduce((obj, curr) => ({...obj, [curr[0]]: {ID: curr[0], name: curr[1]}}), {})
                    localStorage.setItem("clubsUpdated", (new Date()).getTime());
                    localStorage.setItem("clubs", JSON.stringify(clubsMap));
                    this.setState({clubs: clubsMap})
                }
            )
        }

    };

    render() {
        //window.embeddedMode=true;
        //return <ListClubs/>;
        if (window.embeddedMode) {
            return {
                "events": <EventListEmbedded/>,
                "clubs": <ListClubs/>,
            }[window.page] || null;
        }
        return (
            <Router>

                <UserContext.Provider value={this.state}>
                    <div style={{
                        position: "fixed",
                        bottom: 0,
                        right: 0,
                        zIndex: 10000,
                        fontSize: "10px",
                        backgroundColor: "white",
                        padding: "5px"
                    }}>
                        version {window.vatomatorVersion}
                    </div>
                    <div className={"App " + (window.location.hostname === "localhost" ? "blue" : "tvg")}>
                        <Route path="/" render={(props) => <CheckLogin {...props} addUserFromStatus={this.addUserFromStatus}/>}/>
                        {
                            /*!!this.state.user && this.state.user.status === "logged_in" &&
                             */
                        }
                        <Route path="/" component={Nav}/>
                        <Route path="/" component={ScrollToTop}/>
                        <MainContainer>
                            <div id={"mainOverlayForNav"}/>
                            <ErrorBoundary key={"ljljfs"}>
                                <Switch>
                                    <Route exact path="/benutzer/changepw/:token" component={ChangePW}/>
                                    <Route exact path={"/profil"} component={AsyncComponent(() => import("./user/profil"))}/>

                                    <Route exact path={"/personen"} component={AsyncComponent(() => import("./user/personTeamView"))}/>
                                    <Route exact path={"/personen/:person_ID"} component={AsyncComponent(() => import("./user/personTeamView"))}/>
                                    <Route exact path={"/abrechnung"} component={AsyncComponent(() => import("./booking/billingAddressList"))}/>
                                    <Route exact path={"/registrieren"} component={AsyncComponent(() => import("./user/userEdit"))}/>
                                    <Route exact path={"/"} component={AsyncComponent(() => import("./event/eventList"))}/>
                                    <Route exact path={"/veranstaltungen"} component={AsyncComponent(() => import("./event/eventList"))}/>
                                    <Route exact path={"/veranstaltungen/:event_ID"} component={AsyncComponent(() => import("./event/eventView"))}/>
                                    <Route exact path={"/veranstaltungen/:event_ID/riegenzuordnung"} component={AsyncComponent(() => import("./event/eventSquads"))}/>
                                    <Route exact path={"/veranstaltungen/:event_ID/riegenzuordnung/:umlauf"} component={AsyncComponent(() => import("./event/eventSquads"))}/>
                                    <Route exact path={"/veranstaltungen/:event_ID/kampfrichterzuordnung"} component={AsyncComponent(() => import("./event/eventSquads"))}/>
                                    <Route exact path={"/veranstaltungen/:event_ID/kampfrichterzuordnung/:umlauf"} component={AsyncComponent(() => import("./event/eventSquads"))}/>
                                    <Route exact path={"/veranstaltungen/:event_ID/umlaufzuordnung/teilnehmer"} component={AsyncComponent(() => import("./event/eventSquads"))}/>
                                    <Route exact path={"/veranstaltungen/:event_ID/umlaufzuordnung/kampfrichter"} component={AsyncComponent(() => import("./event/eventSquads"))}/>
                                    <Route exact path={"/veranstaltungen/:event_ID/gäste"} component={AsyncComponent(() => import("./event/eventGuests"))}/>
                                    <Route exact path={"/eventListEmbedded"} component={EventListEmbedded}/>
                                    <Route exact path={"/clubListEmbedded"} component={ListClubs}/>
                                    <Route exact path={"/bewerbe/:event_ID"} component={AsyncComponent(() => import("./event/competition/list"))}/>
                                    <Route exact path={"/bewerbe/:offer_ID/eingabe"} component={AsyncComponent(() => import("./event/competition/participate"))}/>
                                    <Route exact path={"/bewerbe/:offer_ID/eingabe/:competition_ID"} component={AsyncComponent(() => import("./event/competition/participate"))}/>
                                    <Route exact path={"/bewerbe/:offer_ID/ergebnisse"} component={AsyncComponent(() => import("./event/scores/list"))}/>
                                    <Route exact path={"/bewerbe/:offer_ID/rangliste"} component={AsyncComponent(() => import("./event/scores/leaderboard"))}/>
                                    <Route exact path={"/bewerbe/:offer_ID/rangliste/:club_ID"} component={AsyncComponent(() => import("./event/scores/leaderboard"))}/>
                                    <Route exact path={"/gerätesätze"} component={AsyncComponent(() => import("./scoreInput/apparatusSets"))}/>
                                    <Route exact path={"/veranstaltungen/:event_ID/letztewertungen"} component={AsyncComponent(() => import("./scoreInput/lastScores"))}/>
                                    <Route exact path={"/veranstaltungen/:event_ID/fortschritt"} component={AsyncComponent(() => import("./scoreInput/progress"))}/>
                                    <Route exact path={"/anmeldungen"} component={AsyncComponent(() => import("./booking/registrationList"))}/>
                                    <Route exact path={"/vereine/:club_ID"} component={AsyncComponent(() => import("./user/clubEdit"))}/>
                                    <Route exact path={"/vereine"} component={ListClubs}/>
                                    <Route exact path={"/kampfrichter"} component={AsyncComponent(() => import("./user/judgesList"))}/>
                                    <Route exact path={"/anmeldungen/:registration_ID"} component={AsyncComponent(() => import("./booking/registrationView"))}/>
                                    <Route exact path={"/eingabe/:competitionKind/:event_ID"} component={AsyncComponent(() => import("./scoreInput/scoreInputView"))}/>
                                    <Route exact path={"/eingabe/:competitionKind/:event_ID/:apparatusCode"} component={AsyncComponent(() => import("./scoreInput/scoreInputView"))}/>
                                    <Route exact path={"/eingabe/:competitionKind/:event_ID/:apparatusCode/:numJudges"} component={AsyncComponent(() => import("./scoreInput/scoreInputView"))}/>
                                    <Route exact path={"/eingabe/:competitionKind/:event_ID"} component={AsyncComponent(() => import("./scoreInput/scoreInputView"))}/>
                                    <Route exact path={"/eingabe/:competitionKind/:event_ID/:apparatusCode"} component={AsyncComponent(() => import("./scoreInput/scoreInputView"))}/>
                                    <Route exact path={"/eingabe/:competitionKind/:event_ID/:apparatusCode/:numJudges"} component={AsyncComponent(() => import("./scoreInput/scoreInputView"))}/>
                                    <Route exact path={"/getCustomClub"} component={AsyncComponent(() => import("./core/custom_exporter"))}/>
                                    <Route path="/benutzer/login" component={Login}/>
                                    <Route path="/benutzer/logout" component={Login}/>

                                    <Route path={"/"} component={Notfound}/>
                                </Switch>

                                <Container name={"footer"} hideHeading>
                                    <div id={"divider"}/>


                                    <div id={"text"}>

                                        {Club.fullName},&nbsp;
                                        {new Date().getFullYear()},&nbsp;
                                        <a target={"_blank"} href={"https://"+Club.domain}>{Club.domain}</a>, <a target={"_blank"} rel={"noopener noreferrer"} href={Club.links.imprint}>Impressum</a>
                                    </div>
                                    <div id={"images"}>
                                        powered by <a href={"//vereinfacht.at"} target={"_blank"} rel={"noopener noreferrer"}>VEREINfacht.at</a>
                                    </div>
                                    <br/>
                                    <br/>
                                    <br/>
                                    <br/>
                                    <br/>
                                    <br/>
                                    <br/>
                                    <br/>
                                    <br/>
                                    <br/>
                                </Container>

                            </ErrorBoundary>
                        </MainContainer>

                    </div>
                </UserContext.Provider>
            </Router>
        );
    }
}


class MainContainer extends React.Component {
    state = {
        mounted: false,
    };
    componentDidMount = () => {
        window.setTimeout(() => {
            this.setState({mounted: true})
        }, 2000);
    };
    static contextType = UserContext;

    render() {
        const userContext = this.context;
        return <article style={{zIndex: 10}} id={"mainContainer"}
                        className={
                            (userContext.navOpen ?
                                    "open" :
                                    "closed"
                            ) +
                            (this.state.mounted ? " postLoad" : "")}
                        onClick={() => userContext.navOpen && !!userContext.user && userContext.user.status === "logged_in" && window.innerWidth < 500 && userContext.navToggle()}
        >
            {this.props.children}
        </article>
    }
}

function Notfound(props) {
    if (props.location.pathname.includes("/benutzer") && !props.location.pathname.includes("/benutzer/view")) {
        return null
    }
    return <Container name={"Fehler"}>Diese Funktion wird in kürze hinzugefügt!</Container>
}

export default App;
