import PersonList from "./personList";
import TeamList from "./teamList";
import React, {useContext, useMemo, useState} from "react";
import {maxiGet} from "../core/maxios";
import {Container, MaxBtn, MaxLink} from "../core/components";
import {UserContext} from "./UserContext";
import {LightContainer, MyModal} from "../core/input_fields";
import {encodeGetParams} from "../core/helpers";
import {ListPerClubAndRole} from "./clubs";
import {PermissionLevel, PermissionLevelMap} from "../core/enums";

export default function PersonTeamView(props) {
    const [persons, setPersons] = useState([]);
    const [isClubAdmin, setIsClubAdmin] = useState(false);
    const [{error, loading, success}, setStatusVar] = useState({loading: true});
    const context = useContext(UserContext);
    const loadPersons = (onlyRegistrants = false) => context.user?.role < 80 && maxiGet("/person?" + encodeGetParams({onlyRegistrants}), {setStatusVar, fields: ["fullname", "dateOfBirth", "ID", "clubs"]}).then(({persons, isClubAdmin}) => {
        setPersons(persons)
        setIsClubAdmin(isClubAdmin)
    })


    useMemo(loadPersons, [context.user?.role]);
    return <Container name={"Personen und Teams"} hideHeading>
        <PersonList {...{...props, persons, setPersons, loading, error, loadPersons, isClubAdmin}}/>
        {/*
        <TeamList {...{...props, persons}}/>
        */}
        {
            context.user?.role > 80 && <LightContainer name={"Vereine"}>
                <MaxLink to={"/vereine"}>Vereine anzeigen</MaxLink>
                &nbsp;
                <MaxLink to={"/kampfrichter"}>Kampfrichter anzeigen</MaxLink>
                &nbsp;
                <MyModal trigger={<MaxBtn>nach Rolle filtern</MaxBtn>}>
                    <ListPerClubAndRole club={{ID: 0, "name": "in allen Vereinen"}} defaultPermission={PermissionLevel.find(({value}) => PermissionLevelMap.admin)}/>
                </MyModal>
            </LightContainer>
        }
    </Container>
}
