import React, {useRef, useState} from "react";
import {maxiGet, maxiPost} from "../core/maxios";
import {Container, Loader, MaxBtn, MaxLink} from "../core/components";
import {MyModal} from "../core/input_fields";
import Status from "../core/status";
import {FaDownload} from "react-icons/fa/index";
import {downloadAsExcel} from "../core/download";

export function TURNrFunctions({event, reload, loading, setStatusVar, parent_ID}) {
    return <>
        <Loader loading={loading}/>
        {
            event.isCompetition > 0 &&
            <>

                <MaxBtn onClick={() => {
                    setStatusVar({loading: true})
                    maxiPost(`/turnr/event/${event.ID}/export_to_turnr`, {}, {setStatusVar}).then()
                }}>
                    Personen überspielen
                </MaxBtn>
                &nbsp;

            </>
        }
        <TURNRImport {...{event, reload, parent_ID}}/>
    </>
}


export function TURNRImport({event, reload, parent_ID}) {
    const [{error: errorLocal, loading: loadingLocal}, setStatusVarLocal] = useState({});
    const file = useRef();
    const submit = close => () => {
        var formData = new FormData();
        setStatusVarLocal({loading: true})
        formData.append("file", file.current.files[0]);
        console.log(parent_ID)
        formData.append("parent_ID", parent_ID || null);
        maxiPost(`/turnr/event/${event.ID}/import_competitionparts`, formData, {setStatusVar: setStatusVarLocal}).then(() => {
            reload()
            close()
        })
    }
    return <MyModal trigger={<MaxBtn>Wettkampfteile im-/exportieren</MaxBtn>}>
        {close =>
            <Container name={"Wettkampfteile im-/exportieren"}>
                <Status type={"success"} text={errorLocal}/>
                <label>
                    Excel Wettkampfteile
                    <br/>
                    <input type={"file"} ref={file}/>
                </label>
                <br/>
                <MaxBtn onClick={submit(close)}>Hochladen</MaxBtn><Loader loading={loadingLocal}/>

                <br/><br/>
                Beispiele für solche Tabellen sind:<br/>
                <li><a href={"/turnr/Turn10_allg.xlsx"}>Turn 10 <FaDownload/></a></li>
                <li><a href={"/turnr/Kunstturnen_allg.xlsx"}>Kunstturnen <FaDownload/></a></li>
                <br/>
                <h3>Wettkampfteile herunterladen</h3>
                <MaxBtn onClick={() => !event ? alert("Veranstaltung nicht definiert") : maxiGet(`/turnr/event/${event.ID}/export_competitionparts`, {setStatusVar: setStatusVarLocal}).then(({content}) => {
                    downloadAsExcel(content, event.name + " Wettkampfteile.xlsx")
                })
                }>Herunterladen</MaxBtn>

                <h3>Regelsätze</h3>
                {
                    [
                        "Kunstturnen - Kinderstufen weiblich",
                        "Kunstturnen allgemein",
                        "Turn10 - Basis",
                        "Turn10 - Oberstufe",
                        "Turnfest"
                    ].join(", ")
                }
                <h3>Gerätesätze</h3>
                <MaxLink to={"/gerätesätze"}>Gerätesätze anschauen / verwalten</MaxLink>
            </Container>
        }
    </MyModal>

}
