import React, {useContext, useMemo, useState} from "react";
import {maxiGet, maxiPost} from "../core/maxios";
import {CheckboxInput, LightContainer, MiniBtn, MyModal, MyReactTable, TextfieldInput} from "../core/input_fields";
import {Loader, MaxBtn} from "../core/components";
import Status from "../core/status";
import PersonList from "../user/personList";
import {PersonTypeMap} from "../core/enums";
import ShowOffersOfPerson from "../event/eventOffersOfPerson";
import {UserContext} from "../user/UserContext";
import {PersonEditWrapper} from "../user/personHelpers";
import {encodeGetParams} from "../core/helpers";
import {FormContextWrapper} from "../core/form_context";
import ImportOldRegistration from "./importOldRegistration";
import {FaPlus} from "react-icons/fa"

const now = new Date();

export function RegisteredPersonsList({event, registeredPersons, registration, eventPermitted, reload}) {
    const [teamMode, setTeamMode] = useState(false);
    const [teams, setTeams] = useState([]);
    const [persons, setPersons] = useState([]);
    const [persons2, setPersons2] = useState([]);
    const [tableCount, setTableCount] = useState(null)
    const [{error, loading, success}, setStatusVar] = useState({});

    const context = useContext(UserContext);
    const loadPersons = () => (context.user?.role < 80 && context.user?.allPersonsPermissionTill < new Date().getTime() / 1000) &&
        maxiGet(`/person?${encodeGetParams({club_ID: registration.club_ID})}`, {setStatusVar}).then(data => {
            setPersons(data.persons)
        })

    useMemo(loadPersons, [context.user?.role]);
    const loadRegistrationsAndPersons = () => {
        reload()
        loadTeams()
        loadPersons()
    }
    const loadTeams = () => teamMode && maxiGet(`/registration/${registration.ID}/teams`, {setStatusVar}).then(data => {
        setTeams(data)
    })
    useMemo(loadTeams, [teamMode, registration?.ID])

    const registeredPersonIds = registeredPersons.map(r => r.person.ID);
    const registeredPersonIdsParticipants = registeredPersons.filter(r => r.registeredPerson.role.value === PersonTypeMap.participant).map(r => r.person.ID);
    const registeredPersonIdsOfficials = registeredPersons.filter(r => r.registeredPerson.role.value === PersonTypeMap.official).map(r => r.person.ID);
    return <LightContainer>
        <h2>{
            ((registration.editable) || eventPermitted) &&
            <div style={{float: "right", marginTop: "-10px"}}>
                {
                    ((event.bookingStart < now && now < event.bookingEnd) || eventPermitted) && [0, 1, 2].includes(event.teamsRequired) && <><MyModal onClose={reload} trigger={<MaxBtn>Teilnehmer hinzufügen</MaxBtn>}>
                        {close => <RegisteredPersonsAdd close={close} personType={PersonTypeMap.participant} event={event}
                                                        persons={persons} setPersons={setPersons} personsAvailable={persons.filter(p => registeredPersonIdsParticipants.indexOf(p.ID) === -1).map(p => p.ID)}
                                                        registration={registration} reload={loadRegistrationsAndPersons}/>}
                    </MyModal></>

                }

                {
                    ((event.bookingOfficialsStart < now && now < event.bookingOfficialsEnd) || eventPermitted) && event.allowOfficials && <>&nbsp;&nbsp;<MyModal onClose={reload}
                                                                                                                                                                 trigger={<MaxBtn>{event.allowOfficialsLabel || "Offiziellen"} hinzufügen</MaxBtn>}>
                        {close => <RegisteredPersonsAdd close={close} personType={PersonTypeMap.official} event={event}
                                                        persons={persons} setPersons={setPersons} personsAvailable={persons.filter(p => registeredPersonIdsOfficials.indexOf(p.ID) === -1).map(p => p.ID)}
                                                        registration={registration} reload={loadRegistrationsAndPersons}/>}
                    </MyModal></>

                }

                {
                    ((event.bookingStart < now && now < event.bookingEnd) || eventPermitted) && [1, 2].includes(event.teamsRequired) && <>&nbsp;&nbsp;<MyModal onClose={reload} trigger={<MaxBtn>Team hinzufügen</MaxBtn>}>
                        {close => <RegisteredTeamAdd close={close}
                                                     newTeam
                                                     persons={registeredPersons} setPersons={setPersons}
                                                     registration={registration} reload={loadRegistrationsAndPersons}/>}
                    </MyModal></>

                }

                {
                    event.bookingStart < now && now < event.bookingEnd && eventPermitted && !event.allowOfficials && !(context?.user?.role > 20) && <>&nbsp;&nbsp;<MyModal onClose={reload} trigger={<MaxBtn>Externen hinzufügen</MaxBtn>}>
                        {close => <RegisteredPersonsAdd {...{close, event, persons: persons2, setPersons: setPersons2}}
                                                        personType={PersonTypeMap.official}
                                                        name={"Externen"}
                                                        externalAdminEventID={event.ID}
                                                        personsAvailable={persons2.filter(p => registeredPersonIds.indexOf(p.ID) === -1).map(p => p.ID)}
                                                        registration={registration}
                                                        reload={loadRegistrationsAndPersons}
                        />
                        }
                    </MyModal></>
                }

                {
                    (eventPermitted || context?.user?.role > 20) && <>
                        {
                            registeredPersons.some(a => a.registeredPerson.teamName) && <>
                                &nbsp;&nbsp;
                                <MaxBtn onClick={() => setTeamMode(a => !a)}>{teamMode ? "Personen anzeigen" : "Teams anzeigen"}</MaxBtn>
                            </>
                        }
                        &nbsp;&nbsp;<MyModal onClose={reload} trigger={<MaxBtn>alte Meldung importieren</MaxBtn>}>
                        {
                            close => <ImportOldRegistration {...{event, registration, close, persons, setPersons}} />
                        }
                    </MyModal>
                        &nbsp;&nbsp;<MyModal onClose={reload} trigger={<MaxBtn>Sonderbuchung hinzufügen/bearbeiten</MaxBtn>}>
                        <Sonderbuchungen {...{event, registration, reload}} />

                    </MyModal>

                    </>
                }
            </div>
        }
            {`Angemeldete ${teamMode ? "Teams" : "Personen"} (${tableCount || registeredPersons?.length})`}</h2>
        <Status type={"error"} text={error}/>
        <MyReactTable
            data={teamMode ? teams : registeredPersons}
            loading={loading}
            onTableChange={a => setTableCount(a.length)}
            defaultSorted={[{id: "role"}, {id: "registeredPerson.teamName"}, {id: "person.fullname"}]}
            columns={

                teamMode ? [
                        {
                            Header: "Name",
                            accessor: "teamName",
                            filterable: true,
                            Cell: ({original, value}) => <MyModal onClose={reload} trigger={<em>{value}</em>}>
                                {close => <RegisteredTeamAdd close={close}

                                                             team={{name: value, team_ID: original.team_ID, members: registeredPersons.filter(a => a.registeredPerson?.team_ID === original.team_ID).map(a => a.registeredPerson.ID)}}
                                                             persons={registeredPersons} setPersons={setPersons}
                                                             registration={registration} reload={loadRegistrationsAndPersons}/>}
                            </MyModal>
                        },
                        {
                            Header: "Anzahl an Teilnehmern",
                            accessor: "person_count",
                            filterable: true,
                        },
                        /*{
                            Header: "Team-Options-Buchung",
                            accessor: "count_teamOffers",
                            filterable: true,
                        },*/
                        {
                            Header: "Teilnehmer ohne Team-Buchung",
                            accessor: "person_without_teamOffer",
                            filterable: true,
                        },
                    ] :
                    [
                        {
                            Header: "Name",
                            accessor: "person.fullname",
                            filterable: true,
                            Cell: ({original, value}) => <PersonEditWrapper reload={() => {
                                loadPersons()
                                reload()
                            }} personLight={original.person}>
                                <em>{value}</em>
                            </PersonEditWrapper>
                        },
                        {
                            Header: "JG",
                            show: event.isCompetition === 1,
                            accessor: "person.dateOfBirth",
                            filterable: true,
                            sortable: true,
                            maxWidth: 50,
                        },
                        {
                            Header: "Teamname",
                            accessor: "registeredPerson.teamName",
                            show: registeredPersons?.some(a => !!a.registeredPerson?.teamName),
                            filterable: true,
                            Cell: ({original, value}) => <MyModal onClose={reload} trigger={<em>{value}</em>}>
                                {close => <RegisteredTeamAdd close={close}

                                                             team={{name: value, team_ID: original.registeredPerson?.team_ID, members: registeredPersons.filter(a => a.registeredPerson?.team_ID === original.registeredPerson?.team_ID).map(a => a.registeredPerson.ID)}}
                                                             persons={registeredPersons} setPersons={setPersons}
                                                             registration={registration} reload={loadRegistrationsAndPersons}/>}
                            </MyModal>
                        },
                        {
                            Header: "Art",
                            id: "role",
                            accessor: (a) => a?.registeredPerson?.role?.label === "Offizieller" ? event.allowOfficialsLabel || "Offiziellen" : a?.registeredPerson?.role?.label,
                            filterable: true,
                        },

                        {
                            maxWidth: 200,
                            accessor: "offersCount",
                            filterable: true,
                            Cell: ({original, value}) => <>
                                {
                                    registration.editable && ((original.registeredPerson?.role.value === PersonTypeMap.official && (event.bookingOfficialsStart < now && now < event.bookingOfficialsEnd)) || (original.registeredPerson?.role.value === PersonTypeMap.participant && (event.bookingStart < now && now < event.bookingEnd)) || eventPermitted) &&
                                    <em onClick={() => {
                                        if (window.confirm("Person aus Registrierung entfernen?")) {
                                            maxiPost(`/registration/${registration.ID}/delete_person`, {person_ID: original.person.ID, registeredPerson_ID: original.registeredPerson.ID}, {setStatusVar}).then(reload)
                                        }
                                    }
                                    }>entfernen</em>
                                }
                                &nbsp;
                                <MyModal trigger={<em>Buchungen ({original.offersCount || 0})</em>}>
                                    <ShowOffersOfPerson event={event} registration={original.registration} person={original.person}/>
                                </MyModal>
                            </>
                        }
                    ]}
        />
    </LightContainer>
}


function RegisteredPersonsAdd({persons, setPersons, personsAvailable, personType, registration, event, close, reload, name, externalAdminEventID}) {
    const [{error, loading, success}, setStatusVar] = useState({});

    const [locallyAdded, setLocallyAdded] = useState([]);

    const handleSubmit = (person) => {
        !loading && maxiPost(`/registration/${registration.ID}/add_person`, {person_ID: person.ID, personType}, {setStatusVar})
            .then(() => {
                reload()
                setLocallyAdded([...locallyAdded, person.ID])
            })
    }
    const personTypeMapDeclined = {[PersonTypeMap.participant]: "Teilnehmer", [PersonTypeMap.official]: event.allowOfficialsLabel || "Offiziellen"}; // somehow is too complicated
    return <LightContainer name={(name || personTypeMapDeclined[personType]) + " hinzufügen"}>
        <Status type={"error"} text={error}/>
        <Loader loading={loading}/>
        <PersonList
            externalAdminEventID={externalAdminEventID}
            persons={persons}
            judgeMode={event.allowOfficialsCertifiedOnly && personType === PersonTypeMap.official}
            setPersons={setPersons}
            nameCell={({original, value}) => personsAvailable.includes(original.ID) && !locallyAdded.includes(original.ID) ? <em onClick={() => {
                handleSubmit(original)
            }}>{value} (hinzufügen)</em> : <>{value} (bereits ausgewählt)</>}
            error={""}
            loadPersons={reload}
            match={{}}
            loading={false}
        />
    </LightContainer>
}

function RegisteredTeamAdd({persons, personsAvailable, team, registration, newTeam, close, reload}) {
    const [{error, loading, success}, setStatusVar] = useState({});

    const [state, setState] = useState({members: [], ...team})

    return <LightContainer name={"Team hinzufügen"}>
        <Status type={"error"} text={error}/>

        <FormContextWrapper value={{state, setState: a => setState(b => ({...b, ...a}))}}>
            <TextfieldInput name={"Team"} tag={"name"}/> <Loader loading={loading}/>
            {newTeam && <CheckboxInput name={"Bereits zugeordnete anzeigen"} tag={"showAll"}/>}
        </FormContextWrapper>

        <MyReactTable
            defaultSorted={[{"id": "added"}]}
            data={persons.filter(({registeredPerson: rp}) => rp.role.value === PersonTypeMap.participant && (((state.showAll || !rp.teamName) && newTeam) || (!newTeam && (state.showOthers || state.members.includes(rp.ID)))))}
            columns={[
                {
                    Header: "Name",
                    accessor: "person.fullname",
                    filterable: true,
                },
                {
                    Header: "G",
                    id: "sex",
                    accessor: row => ["W", "M"][row.person.sex],
                    filterable: true,
                    maxWidth: 50,
                },
                {
                    Header: "Jahrgang",
                    accessor: "person.dateOfBirth",
                    filterable: true,
                    maxWidth: 100,
                },
                {
                    Header: "Team",
                    id: "team",
                    accessor: original => state.members.includes(original.registeredPerson.ID) ? state.name : original.registeredPerson.teamName,
                    filterable: true,
                },
                {
                    Header: "",
                    id: "added",
                    accessor: original => state.members.includes(original.registeredPerson.ID) ? "member" : ((original.registeredPerson?.team_ID === null || original.registeredPerson?.team_ID === state.team_ID) ? "unzugeordnet" : "zugeordnet"),
                    Cell: ({original, value: member}) => (member !== "zugeordnet") ?
                        <MiniBtn style={{backgroundColor: member === "member" ? "rgba(255,0,0,.6)" : null}} onClick={() => {
                            setState({...state, members: member === "member" ? state.members.filter(id => id !== original.registeredPerson.ID) : [...state.members, original.registeredPerson.ID]})
                        }
                        }>{member === "member" ? "von diesem Team entfernen" : "zu diesem Team hinzufügen"}</MiniBtn> :
                        "bereits zu Team zugeordnet"
                }
            ]}/>

        {!newTeam && !state.showOthers && [<MaxBtn onClick={() => setState(os => ({...os, showOthers: true}))}>Personen hinzufügen</MaxBtn>, <br/>]}

        {
            !newTeam && <>Eine bestehende Zuordnung zu Optionen wird nicht automatisch bei einer Team-Änderung verändert.<br/></>
        }

        <MaxBtn onClick={() => {
            if (!state.name && newTeam) {
                alert("Bitte zuerst Team-Name eingeben!")
                return
            }
            if (state.name.length > 35) {
                alert("Team-Name darf maximal 35 Zeichen lang sein.")
                return
            }
            if (state.members?.length < 1 && newTeam) {
                alert("Bitte zuerst Team-Mitglieder hinzufügen!")
                return
            }

            !loading && maxiPost(`/registration/${registration.ID}/create_team`, state, {setStatusVar})
                .then(() => {
                    reload()
                    close()
                })
        }
        }>
            Speichern
        </MaxBtn>

    </LightContainer>
}

function Sonderbuchungen({registration, reload}) {
    const [additional, setAdditional] = useState([])

    return <LightContainer name={"Sonderbuchungen"}>

        <ul>
            {
                [...(registration.sonderbuchungen || []), ...additional].map(sonderbuchung => <li>
                    <SonderbuchungAdd {...{registration, sonderbuchung}}/>
                </li>)
            }
        </ul>
        <MaxBtn onClick={() => setAdditional(b => ([...b, {}]))}><FaPlus/> Sonderbuchung hinzufügen</MaxBtn>


    </LightContainer>
}

function SonderbuchungAdd({registration, sonderbuchung}) {
    const [{error, loading, success}, setStatusVar] = useState({});

    const [state, setState] = useState({...sonderbuchung})

    const submit = () => !loading && maxiPost(`/registration/${registration.ID}/add_sonderbuchung`, state, {setStatusVar}).then((a) => {
        setState(a)
    });

    return <FormContextWrapper value={{state, setState: a => setState(b => ({...b, ...a}))}} onSubmit={submit}>
        <Status type={"error"} text={error}/>
        <TextfieldInput noLabel name={"Beschreibung"} tag={"note"}/>
        <TextfieldInput labelWidth={80} name={"Preis"} tag={"price"} width={80}/>
        <Loader loading={loading}/>
        <MaxBtn onClick={submit}>
            Speichern
        </MaxBtn>
    </FormContextWrapper>
}

/*

function RegisteredTeamAddOld({persons, personsAvailable, registration, close, reload}) {
    const [{error, loading, success}, setStatusVar] = useState({});

    const handleSubmit = (team) => {
        !loading && maxiPost(`/registration/${registration.ID}/add_team`, {team: team.ID}, {setStatusVar})
            .then(() => {
                reload()
            })
    }
    return <LightContainer name={"Mannschaft hinzufügen"}>
        <Status type={"error"} text={error}/>
        <Loader loading={loading}/>
        <TeamList
            persons={persons}
            nameCell={({original, value}) => <em onClick={() => {
                setStatusVar({loading: true})
                handleSubmit(original)
            }}>{value} (hinzufügen)</em>}
            error={""}
            loadPersons={reload}
            match={{}}
            loading={false}
        />
    </LightContainer>
}
*/
