import React, {useState} from "react";

import {Loader, MaxBtn} from "../core/components";
import {CheckboxInput, DateTimeInput, EditorInput, FileUpload, InputContainer, LightContainer, SelectfieldInput, TagsInput, TextfieldInput} from "../core/input_fields";
import {FormContextWrapper} from "../core/form_context";
import {maxiPost} from "../core/maxios";
import Status from "../core/status";
import "./style.sass"
import UserHistory from "../user/userHistory";
import {Redirect} from "react-router";
import EventPermissions from "./eventPermissions";

const fields = {
    name: "Name",
    //status: "Status",
    eventStart: "Veranstaltungs-Start",
    eventEnd: "Veranstaltungs-Ende",
    bookingStart: "Buchbar ab",
    bookingEnd: "Buchbar bis",
    bookingOfficialsStart: "Buchbar ab (Offizielle)",
    bookingOfficialsEnd: "Buchbar bis (Offizielle)",
    showStart: "Anzeigen ab",
    showCertificatesAfter: "Zeige Zertifikate ab",
    prebookingEnd: "Frühbuchungs-Ende",
    allowOfficials: "Offizielle erlauben",
    allowOfficialsLabel: "Bezeichnung Offizielle",
    allowOfficialsCertifiedOnly: "Erlaube nur bestätigte Kampfrichter",
    registrantRequired: "Anmeldung nur für Meldeverantwortliche",
    teamsRequired: "Anmeldung als Team",
    onlyAutomaticAssociation: "Nur automatische Zuordnung",
    maxParts: "maximale Teilnehmeranzahl",
    city: "Ort",
    emailAddressesRequired: "E-Mail Adressen erforderlich",
    lastScoresPublic: "Letzte Wertungen öffentlich",
    signature: "Unterschrift für Urkunden",
    logoLeft: "Linkes Logo für Urkunden",
    logoRight: "Rechtes Logo für Urkunden",
    environment: "Umgebung",
    description: "Beschreibung",
    noteForBookers: "Bemerkung für Angemeldete",
};


export default function EventEdit({event, reload}) {

    const [{error, loading, success}, setStatusVar] = useState({});
    const [state, setState] = useState({event})
    const handleSubmit = () => {
        if (loading) {
            return
        }
        setStatusVar({loading: true})
        maxiPost("/event", state.event, {setStatusVar})
            .then((resp) => {
                if (!event.ID) {
                    setState(old => ({event: {...old.event, ID: resp.event_ID}}))
                } else {
                    reload()
                }
            })
    }
    if (event.ID !== state.event.ID && !!state.event.ID) {
        return <Redirect to={`/veranstaltungen/${state.event.ID}`}/>
    }
    return <LightContainer name={event.ID && "Veranstaltung bearbeiten"}>
        <FormContextWrapper value={{state, setState}} onSubmit={handleSubmit}>
            <Status type={"error"} text={error}/>
            {Object.entries(fields).map(([f, fieldName], i) => {
                switch (f) {
                    case "description":
                    case "noteForBookers":
                        return <EditorInput name={fieldName} key={"event_" + f} tag={"event_" + f} style={{width: "100%"}}/>;
                    case "allowOfficials":
                    case "emailAddressesRequired":
                    case "allowOfficialsCertifiedOnly":
                    case "registrantRequired":
                    case "lastScoresPublic":
                    case "onlyAutomaticAssociation":
                        return <InputContainer>
                            <CheckboxInput name={fieldName} tag={"event_" + f}/>
                            {i % 2 === 0 && <br/>}
                        </InputContainer>;
                    case "teamsRequired":
                        return <InputContainer>
                            <SelectfieldInput name={fieldName} demandSelect selectives={[
                                ["0", "Nur als Einzelpersonen"],
                                ["1", "Nur als Teams"],
                                ["2", "beides"],
                            ]} tag={"event_" + f}/>
                            {i % 2 === 0 && <br/>}
                        </InputContainer>;

                    case "name":
                        return <>
                            <TextfieldInput class={"form-control "} width={500} name={fieldName} tag={"event_" + f}/>
                            <br/>
                        </>;
                    case "signature":
                        return <>
                            <FileUpload name={"Unterschrift"} tag={"event_signature"} prefix={"signature"} accept={".png, .jpeg, .jpg"}/>
                        </>;
                    case "environment":
                        return <>
                            <TagsInput name={"Umgebung"} entity={"env"} ID={0} tag={"event_environment"}/>
                        </>;
                    case "logo":
                    case "logoLeft":
                    case "logoRight":
                        return <>
                            <FileUpload name={fieldName} tag={"event_" + f} prefix={"logo"} accept={".png, .jpeg, .jpg"}/>
                        </>;
                    case "showCertificatesAfter":
                        return <InputContainer>
                            <DateTimeInput name={fieldName}
                                           midNight tag={"event_" + f}/>
                            {i % 2 === 0 && <br/>}
                        </InputContainer>;
                    default:

                        if (f.endsWith("Start") || f.endsWith("End")) {
                            return <InputContainer>
                                <DateTimeInput name={fieldName}
                                               {...{[f.endsWith("Start") ? "maxDate" : "minDate"]: state.event[f.endsWith("Start") ? f.replace("Start", "End") : f.replace("End", "Start")]}}
                                               midNight tag={"event_" + f}/>
                                {i % 2 === 0 && <br/>}
                            </InputContainer>;
                        }
                        return <InputContainer>
                            <TextfieldInput class={"form-control "} name={fieldName} tag={"event_" + f}/>
                            {i % 2 === 0 && <br/>}
                        </InputContainer>;
                }

            })}

            <MaxBtn>Speichern</MaxBtn><Loader loading={loading}/>
        </FormContextWrapper>
        {
            event.ID &&
            <MaxBtn onClick={() => window.confirm("Wirklich löschen?") && maxiPost("/event/delete", {ID: event.ID}, {setStatusVar}).then(() => window.location.href = "/")}>Löschen</MaxBtn>
        }
        {
            event.ID && <EventPermissions event={event}/>
        }
        {
            event.ID && <LightContainer name={"Veränderungen"}>
                <UserHistory userID={event.ID} personType={"event"}/>
            </LightContainer>
        }
    </LightContainer>

}
