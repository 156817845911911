import React, {useMemo} from "react";

export const countriesConverter = require("i18n-iso-countries");
countriesConverter.registerLocale(require("i18n-iso-countries/langs/de.json"));

export const embrace = (t) => t > 0 ? `(${t})` : "";
//const t0 = new Date() - (new Date().getTime() % (3600 * 1000));

export const encodeGetParams = p => Object.entries(p).filter(([,v])=>v!==undefined).map(kv => kv.map(encodeURIComponent).join("=")).join("&");

export function AddressPrinter({street, streetNumber, postcode, city, country,}) {
    return `${street} ${streetNumber}, ${postcode} ${city}${country !== "AT" ? ", " + countriesConverter.getName(country, "de") : ""} `
}

export function AddressPrinterFull({recipient, recipientAddition, street, streetNumber, streetNumberAdd, postcode, city, country,}) {
    return <div>
        {recipient && <b>{recipient}<br/></b>}
        {recipientAddition && <>{recipientAddition}<br/></>}
        {street} {streetNumber}{!!streetNumberAdd && "/" + streetNumberAdd} <br/>
        {postcode} {city}
        {country !== "AT" ? <><br/>{countriesConverter.getName(country, "de")}</> : ""}
    </div>
}


export function CloudImageReplacer({children: content}) {
    const contentImageEnhaned = useMemo(() => {
        const div = document.createElement("div")
        div.innerHTML = content;
        const images = div.getElementsByTagName("img");
        for (let i = 0; i < images.length; i++) {
            images[i].src = `https://azamqtcdeo.cloudimg.io/v7/${images[i].src.replace("https://", "")}?width=${images[i].width || 400}`
        }
        return div.innerHTML
    }, [content])
    return <div className={"HTMLCloudImageContainer"} dangerouslySetInnerHTML={{__html: contentImageEnhaned}}/>
}


