import React, {useContext, useMemo, useState} from "react";

import {Container, MaxLink} from "../core/components";
import {MyReactTable} from "../core/input_fields";
import {maxiGet} from "../core/maxios";
import Status from "../core/status";
import {UserContext} from "../user/UserContext";
import "./style.sass"
import {Link} from "react-router-dom";
import {dateFormatInline, timeFormat} from "../core/dateFuncs";
import {encodeGetParams} from "../core/helpers";
import {Club} from "../core/custom";


const dateformatterMonthYear = new Intl.DateTimeFormat('de-AT', {
    year: 'numeric',
    month: 'short',
});
export default function EventList({}) {
    const [events, setEvents] = useState([]);
    const [envPermission, setEnvPermission] = useState(false);
    const [{error, loading, success}, setStatusVar] = useState({loading: true});
    const user = useContext(UserContext)?.user || {}
    const loadEvents = () => {
        maxiGet("/event?"+encodeGetParams({env_ID: Club.env_ID}), {setStatusVar}).then(data => {
            setEnvPermission(data.envPermission)
            const eventsParsed = data.events.map(a => ({...a, eventStart: new Date(a.eventStart), eventEnd: new Date(a.eventEnd)}))
            const now = new Date()
            //adding the event headings
            let eventsWithHeadings = [];
            let archiveReached = false;
            eventsParsed.forEach((event, i) => {
                if (!archiveReached && event.eventEnd < now) {
                    eventsWithHeadings.push({
                        name: "Archiv",
                        heading: true,
                        superheading: true,
                    })
                    archiveReached = true
                }
                if (eventsParsed[i - 1]?.eventStart?.getMonth() !== event.eventStart?.getMonth() && event.eventStart.getTime()) {
                    eventsWithHeadings.push({
                        name: dateformatterMonthYear.format(event.eventStart),
                        heading: true,
                    })
                }

                eventsWithHeadings.push(event)
            })
            setEvents(eventsWithHeadings)
        })
    }
    useMemo(loadEvents, []);
    return <Container name={"Veranstaltungen"} addName={<> - <a href={"#Archiv"}>zum Archiv</a></>}>
        {
            (user.role === 100 || envPermission) &&
            <div style={{float: "right", marginTop: "-50px"}}>
                <MaxLink to={"/veranstaltungen/neu"}>Veranstaltung hinzufügen</MaxLink>
            </div>
        }
        <Status type={"error"} text={error}/>

        <br/>
        <br/>

        <MyReactTable
            data={events}
            loading={loading}
            pageSizeDef={400}
            getTrProps={(state, rowinfo) => {
                return {className: rowinfo?.original?.superheading ? "superHeading" : (rowinfo?.original?.heading ? "dividerRow" : "")}
            }}
            columns={[
                {
                    Header: "Datum",
                    accessor: "eventStart",
                    sortable: false,
                    maxWidth: 250,
                    Cell: ({value, original}) => original.heading ? <h4 id={original.name} style={{marginTop: -100, paddingTop: 100}}>{original.name}</h4> : <>
                        {dateFormatInline(value)}
                        {
                            original.eventEnd && (original.eventEnd.getDate() !== original.eventStart.getDate() ?
                                    <> - {dateFormatInline(original.eventEnd)}</> :
                                    `, ${timeFormat(original.eventStart)}-${timeFormat(original.eventEnd)} Uhr`
                            )
                        }</>,
                },
                {
                    Header: "Veranstaltung",
                    filterable: true,
                    accessor: "name",
                    sortable: false,
                    filterMethod: (filter, row) => {
                        return row[filter.id].toLowerCase().indexOf(filter.value.toLowerCase()) > -1
                    },
                    Cell: ({value, original}) => !original.heading && <Link to={`/veranstaltungen/${original.ID}`}>{value}</Link>,
                },
                {
                    Header: "Ort",
                    filterable: true,
                    accessor: "city",
                    sortable: false,
                }
            ]}
        />
    </Container>
}

