import React, {useContext, useMemo, useState} from "react";

import {Container, Loader, MaxBtn, MaxLink} from "../core/components";
import {LightContainer, MyModal, MyReactTable} from "../core/input_fields";
import {maxiGet, maxiPost} from "../core/maxios";
import Status from "../core/status";
import {UserContext} from "../user/UserContext";
import "./style.sass"
import EventEdit from "./eventEdit";
import {dateFormatLong, dateFormatTime, timeFormat} from "../core/dateFuncs";
import OfferList from "./offerList";
import {Redirect} from "react-router";
import {EventRegistrationList} from "./eventRegistrations";
import PersonList from "../user/personList";
import EventCompetitionResults from "./eventCompetitionResults";
import PageView from "../pages/page_view";
import EventCompetitions from "./competition/overview";
import RegistrationList from "../booking/registrationList";

function returnDateIfNotNull(d) {
    return d ? new Date(d) : null;
}


export default function EventView({match}) {
    const event_ID = match?.params?.event_ID;
    const addNew = event_ID === "neu";
    const [event, setEvent] = useState(addNew ? {} : null);
    const [offers, setOffers] = useState([]);
    const [permitted, setPermitted] = useState(null);
    const [eventJudge, setEventJudge] = useState(null);
    const [{error, loading, success}, setStatusVar] = useState({loading: !addNew});
    const user = useContext(UserContext)?.user || {}
    const loadEvent = () => {
        maxiGet(`/event/${event_ID}`, {setStatusVar}).then(({event, offers, permitted, isJudge}) => {
            setOffers(offers)
            setPermitted(permitted)
            setEventJudge(isJudge)
            setEvent({
                ...event,
                eventStart: returnDateIfNotNull(event.eventStart),
                eventEnd: returnDateIfNotNull(event.eventEnd),
                bookingStart: returnDateIfNotNull(event.bookingStart),
                bookingEnd: returnDateIfNotNull(event.bookingEnd),
                showCertificatesAfter: returnDateIfNotNull(event.showCertificatesAfter),
            })
        })
    }
    useMemo(() => {
        !addNew && loadEvent()
    }, [event_ID])
    return <Container name={"Veranstaltung "} addName={event?.name}>

        <Status type={"error"} text={error}/>
        <Loader loading={loading}/>
        {
            event && !addNew && event.ID && <EventPrint {...{event, eventJudge, permitted}}/>
        }
        {
            event && (((now > event.eventStart || !event.showCertificatesAfter && (now > event.showCertificatesAfter || event.ID === 171))) || user.role === 100 || permitted || eventJudge) && <>
                {
                    (event?.isCompetition === 1 || event?.isCompetition === 2) && <EventCompetitionResults {...{offers, event}} superuser={(user.role === 100 || permitted)}/>
                }
                {
                    event?.isCompetition === 2 && <EventCompetitions {...{offers, eventJudge, event, permitted}}/>
                }
            </>
        }
        {
            (user.role === 100 || permitted || addNew) && event && <>

                {event && !addNew && <EventRegistrationList event={event}/>}
                {!addNew && <OfferList {...{reload: loadEvent, event_ID, offers, event}}/>}
                <EventEdit {...{reload: loadEvent, event}}/>
            </>
        }

    </Container>
}
const now = new Date();

function EventPrint({event, eventJudge, permitted}) {
    const [registrationID, setRegistrationID] = useState(null);
    const {city, eventStart, eventEnd, description} = event
    const [{error, loading, success}, setStatusVar] = useState({});
    const [selectClubs, setSelectClubs] = useState(null);

    const addNewRegistration = (club_ID = null) => maxiPost("/registration/add" + (club_ID === 0 ? "_superuser" : ""), {event_ID: event.ID, club_ID}, {setStatusVar}).then(resp => {
        if (resp.registration_ID) {
            setRegistrationID(resp.registration_ID);
        } else if (resp.select_clubs) {
            setSelectClubs(resp.select_clubs)
        }
    }).catch(e => {
        if (e === "nicht eingeloggt") {

            localStorage.setItem("redir", "/veranstaltungen/" + event.ID);
            window.location.href = "/benutzer/login"
        }
    })

    if (registrationID) {
        return <Redirect to={"/anmeldungen/" + registrationID}/>
    }
    return <>
        <b>
            {city}, {eventStart && dateFormatLong(eventStart)}
            {
                eventEnd && (eventEnd.getDate() !== eventStart.getDate() ?
                        <> bis {dateFormatLong(eventEnd)}</> :
                        `, ${timeFormat(eventStart)} bis ${timeFormat(eventEnd)} Uhr`
                )
            }
            {now < event.bookingEnd && <>, Anmeldung bis {dateFormatTime(event.bookingEnd)} Uhr</>}
            {(event.maxParts < 100 || event.num_bookings / event.maxParts > 0.8) && `, ${Math.max(0, event.maxParts - event.num_bookings)} von ${event.maxParts} Plätzen verfügbar`}
        </b>
        <div dangerouslySetInnerHTML={{__html: description}}/>
        {
            event?.ID && <div><PageView slot={"event/" + event?.ID} permitted={permitted}/></div>
        }
        <UserContext>
            {context => <>
                {
                    ((event.bookingStart < now && now < event.bookingEnd) || (event.bookingOfficialsStart < now && now < event.bookingOfficialsEnd) || permitted) && <>

                        {
                            permitted ? <MyModal trigger={<MaxBtn>Neue Anmeldung</MaxBtn>}>
                                    {
                                        close => <div>
                                            <MaxBtn onClick={() => addNewRegistration()}>Neue Anmeldung</MaxBtn>
                                            &nbsp;&nbsp;
                                            {
                                                permitted && <>
                                                    <MaxBtn onClick={() => {
                                                        close();
                                                        addNewRegistration(0)
                                                    }}>
                                                        Neue Anmeldung für unterschiedliche Vereine
                                                    </MaxBtn>    &nbsp;&nbsp;
                                                    <AddRegistrationForPerson event={event} setRegistrationID={setRegistrationID}/>

                                                </>
                                            }

                                        </div>
                                    }
                                </MyModal> :
                                <MaxBtn onClick={() => addNewRegistration()}>Neue Anmeldung</MaxBtn>
                        }&nbsp;&nbsp;

                    </>
                }
                {
                    context.user && !eventJudge && context.user?.role < 80 && <RegistrationList event_ID={event.ID}/>
                }

                {
                    selectClubs && <MyModal defaultOpen={true} key={Math.random()}>
                        <LightContainer name={"Verein, für den die Meldung ist, auswählen"}>
                            <MyReactTable
                                data={selectClubs}
                                defaultSorted={[{id: "club"}]}
                                columns={[
                                    {
                                        Header: "Verein",
                                        id: "club",
                                        accessor: "label",
                                        filterable: true,
                                        Cell: ({original}) => <em onClick={() => addNewRegistration(original.value)}>{original.label}</em>,
                                    }
                                ]}
                            />
                        </LightContainer>
                    </MyModal>
                }

                {
                    (event.isCompetition === 1 && ((event.eventStart < now && now < event.eventEnd && eventJudge) || context.user?.role > 80 || permitted)) && <div style={{display: "inline-block"}}>
                        <MaxLink to={"/eingabe/" + (event.name.toLowerCase().includes("turn10") || event.name.toLowerCase().includes("turn 10") ? "turnen" : "allgemein") + "/" + event.ID}>Werten</MaxLink>
                    </div>
                }
            </>
            }
        </UserContext>
        <Status type={"error"} text={error}/>
    </>
}


function AddRegistrationForPerson({event, setRegistrationID}) {
    const [{error, loading, success}, setStatusVar] = useState({});
    const [persons, setPersons] = useState([]);
    const [selectClubs, setSelectClubs] = useState(null);
    const [tmpPerson, setTmpPerson] = useState(null);
    const context = useContext(UserContext);
    useMemo(() => (context.user?.role < 80 && (context.user?.allPersonsPermissionTill < new Date().getTime() / 1000)) && maxiGet("/person").then(({persons}) => setPersons(persons)), [context.user?.role])

    const handleSubmit = (person, club_ID = null) => {
        !loading && maxiPost(`/registration/add_superuser`, {event_ID: event.ID, person_ID: person.ID, club_ID}, {setStatusVar})
            .then((resp) => {
                if (resp.registration_ID) {
                    setRegistrationID(resp.registration_ID);
                } else if (resp.select_clubs) {
                    setSelectClubs(resp.select_clubs)
                    setTmpPerson(person)
                }
            })
    }
    return <>
        {
            selectClubs && <MyModal defaultOpen={true} key={Math.random()}>
                <LightContainer name={"Verein, für den die Meldung ist, auswählen"}>
                    <MyReactTable
                        data={selectClubs}
                        defaultSorted={[{id: "club"}]}
                        columns={[
                            {
                                Header: "Verein",
                                id: "club",
                                accessor: "label",
                                filterable: true,
                                Cell: ({original}) => <em onClick={() => handleSubmit(tmpPerson, original.value)}>{original.label}</em>,
                            }
                        ]}
                    />
                </LightContainer>
            </MyModal>
        }
        <MyModal trigger={<MaxBtn>Neue Anmeldung für auswählbare Person</MaxBtn>}>
            <LightContainer name={"Person hinzufügen"}>
                <Status type={"error"} text={error}/>
                <PersonList
                    persons={persons}
                    error={""}
                    setPersons={setPersons}
                    nameCell={({original, value}) => <em onClick={() => {
                        handleSubmit(original, null)
                    }}>{value} (hinzufügen)</em>}
                    loadPersons={() => null}
                    match={{}}
                    loading={false}
                />
            </LightContainer>
        </MyModal>
        &nbsp;
        &nbsp;
    </>
}


