import React from "react"
import Consts from "./consts";

type _Name = {
    verein_ID: number,
    fullName: string,
    fullNameDeclined: string,
    shortName: string,
    domain: string,
    title: string,
    logo?: JSX.Element,
    color: string,
    colorLight: string,
    fontColor?: string,
    fontColorInverse?: string,
    showLogo: boolean,
    iban: string,
    facebook?: string,
    memberStates: Record<number, string>,
    memberStatesShort?: Record<number, string>,
    arnName: string,
    arn: string,
    sendReminders: boolean,
    address: string,
    creditorID: string,
    email?: string,
    phone?: number,
    links?: {
        dataprotection?: string
        statuten?: string,
        imprint?: string
    },
    env_ID: number | null
}

type _domainMap = Record<string, _Name>

const domainMapInitial: _domainMap = {
    "meldung.turnfest.at": {
        verein_ID: 1,
        fullName: "ÖTB OÖ",
        fullNameDeclined: "Turnfests",
        shortName: "OÖTB ",
        domain: "meldung.turnfest.at",
        title: "Turnfest",
        logo: <img src={"https://meldung.turnfest.at/static/img/logo.png"} style={{marginBottom: "15px"}} width={150}
                   height={"auto"}/>,
        showLogo: true,
        color: "#034471",
        colorLight: "rgba(6,151,180, 0.5)",
        fontColor: "#fff",
        fontColorInverse: "#fff",
        iban: "AT12 3456 7890 1234 5678",
        facebook: "Turnverein",
        memberStates: {},
        links: {imprint: "https://ooetb.at/impressum/"},
        arnName: "Turnfest.at <turnfest@vereinfacht.at>",
        arn: "arn:aws:ses:eu-west-1:639823883075:identity/vereinfacht.at",
        sendReminders: true,
        address: "Prunerstraße 6, 4020 Linz",
        creditorID: "ATZ12314151515151",
        env_ID: null,
    },

    "wettkampf.oetb.at": {
        "verein_ID": 1,
        "fullName": "Österreichischer Turnerbund",
        "fullNameDeclined": "ÖTBs",
        "shortName": "ÖTB ",
        "domain": "wettkampf.oetb.at",
        "title": "ÖTB Wettkampf",
            logo: <img src={"/static/img/OeTB-Logo.png"} style={{marginBottom: 15, backgroundColor: "#2D4059", padding: 10}} width={250} height={"auto"}/>,

        links: {
            imprint: "https://oetb.at/impressum/",
        },
        "showLogo": true,
        "color": "#034471",
        "colorLight": "rgba(6,151,180, 0.5)",
        "fontColor": "#fff",
        "fontColorInverse": "#fff",
        "iban": "AT12 3456 7890 1234 5678",
        "facebook": "Turnverein",
        "memberStates": {},
        "arnName": "ÖTB.at <oetb@vereinfacht.at>",
        "arn": "arn:aws:ses:eu-west-1:639823883075:identity/vereinfacht.at",
        "sendReminders": true,
        "address": "Linzer Straße 80a, A-4050 Traun",
        "creditorID": "ATZ12314151515151",
        "memberStatesShort": {},
        "env_ID": 4970
    },
};
const domainMap: _domainMap = Object.keys(domainMapInitial).reduce((obj, curr) =>    ({
    ...obj,
    [curr]: {
        ...domainMapInitial[curr],
        iban: domainMapInitial[curr].iban,
        memberStatesShort: Object.keys(domainMapInitial[curr].memberStates)
            .reduce((obj, k) => ({
                ...obj,
                [parseInt(k)]: domainMapInitial[curr].memberStates[parseInt(k)].split(" ").map((u: any) => u[0]).join(".")
            }), {})
    }
}), {});
const domainName = window.location.hostname.split(".").slice(-2).join(".");
const domainNameLong = window.location.hostname.split(".").slice(-3).join(".");
const clubMap = Object.keys(domainMap).reduce((obj, curr) => ({...obj, [domainMap[curr].verein_ID]: curr}), {});


let ClubPart = domainMap[domainNameLong] || (
    (domainName.indexOf("localhost") > -1 || domainName.indexOf("10.0.0") > -1 || !domainMap[domainName]) ?
        domainMap["vereinfacht.at"] :
        domainMap[domainName]
);
if (Consts.f(true, false)) {
    ClubPart = domainMap["meldung.turnfest.at"];
    //ClubPart = domainMap["wettkampf.oetb.at"];
}

const Club = {
    ...ClubPart,
}; // domain: window.location.host


export {Club, domainMap, clubMap};
