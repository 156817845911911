import React, {useMemo, useState} from "react";

import {Container, MaxBtn,} from "../core/components";
import {maxiGet} from "../core/maxios";
import Status from "../core/status";
import "./style.sass"
import {dateFormat} from "../core/dateFuncs";

export default function EventListEmbedded({}) {
    const [events, setEvents] = useState([]);
    const [{error, loading, success}, setStatusVar] = useState({loading: true});
    const loadEvents = () => {
        maxiGet("https://meldung.turnfest.at/api/event?only_future", {setStatusVar, noApi: true}).then(data => {
            setEvents(data.events)
        })
    }

    const [page, setPage] = useState(0)

    useMemo(loadEvents, []);
    return <Container hideHeading name={"Veranstaltungen"}>

        <Status type={"error"} text={error}/>
        {
            events.slice(page * 10, (page + 1) * 10).map(({name, city, ...original}) => <div className={"publicList"}>
                <h2>{name}</h2>
                {!!city && city + ", "}{dateFormat(original.eventStart)} - {dateFormat(original.eventEnd)}
                <br/>
                <a href={`//meldung.turnfest.at/veranstaltungen/${original.ID}`} target={'_blank'}>Mehr Informationen</a>
            </div>)
        }
        {
            page > 0 && <><MaxBtn  className={"button"} onClick={() => setPage(a => a - 1)}>vorherige Seite</MaxBtn> &nbsp;</>
        }
        {
            (page + 1) * 10 < events.length && <MaxBtn className={"button"} onClick={() => setPage(a => a + 1)}>nächste Seite</MaxBtn>
        }
    </Container>
}

