import React, {useContext} from "react";
import {apiGet, apiPost} from "../../core/api";
import axios from "axios";
import Consts from "../../core/consts";
import Status from "../../core/status";
import {MaxBtn} from "../../core/components";
import {FormContext} from "../../core/form_context";
import {FaTrashAlt} from "react-icons/fa";
import {TextfieldInput} from "../../core/input_fields";
import AsyncComponentWrapper from "../../core/asyncComponentWrapper";

export default function GalleryR({content, editMode, data, ...rest}) {
    const context = useContext(FormContext);
    const setFileData = (data) => {
        context.setState({block: {...context.state.block, data: [...(context.state.block?.data || []), ...data]}});
    };

    return <div style={{opacity: rest.showing ? 1 : 0.5}}>

        {editMode ? <>
            <TextfieldInput name={"Name der Galerie"} tag={"block_content"} style={{width: "200px"}}/>
            {
                !!data && context.state.block?.data?.map(fileToken => <div style={{margin: "5px 0"}}><img style={{verticalAlign: "middle"}} src={`https://azamqtcdeo.cloudimg.io/v7/_s3/${fileToken}?height=80`}/>
                    <em onClick={() => {
                        console.log(fileToken, (context.state.block?.data || []).filter(a => a !== fileToken))
                        context.setState({block: {...context.state.block, data: (context.state.block?.data || []).filter(a => a !== fileToken)}})
                    }}><FaTrashAlt/></em>
                </div>)
            }

            <UploadDocument setFileData={setFileData} slot={rest.slot}/>

        </> : <AsyncComponentWrapper myImport={() => import("./galleryWrapper")}>
            <h3>{content}</h3>
            {
                !!data &&
                data.map(fileToken => <a href={`https://azamqtcdeo.cloudimg.io/v7/_s3/${fileToken}?height=1500`} data-attribute={"SRL"}>
                    <img src={`https://azamqtcdeo.cloudimg.io/v7/_s3/${fileToken}?height=200&width=200&func=crop`}/>
                </a>)
            }
        </AsyncComponentWrapper>

        }

    </div>

}

class UploadDocument extends React.Component {
    constructor(props,) {
        super(props);
        this.apiGet = apiGet.bind(this);
        this.apiPost = apiPost.bind(this);
        this.setState = this.setState.bind(this);
        this.fileField = React.createRef();

        this.state = {
            showing: 1,
            loading: false,
            progress: 100,

        };
    }

    saveFile = (close) => (e) => {
        e.preventDefault();
        this.setState({loading: true});

        var formData = new FormData();
        formData.append("slot",this.props.slot)
        Array.from(this.fileField.current.files).forEach((file, i) => {
            formData.append("pictures_" + i, file);
        })
        axios.post(Consts.API_PREFIX + "/page_blocks/gallery/upload", formData, {
            headers: {
                'Content-Type': 'multipart/form-data'
            },
            onUploadProgress: progressEvent => {
                this.setState({
                    progress: Math.round(progressEvent.loaded / progressEvent.totalSize * 100)
                })
            }
        })
            .then(({data}) => {
                if (data.status === "error") {
                    this.setState({
                        error: data.message,
                        status: "",
                        loading: false
                    })
                } else {
                    this.props.setFileData(data.files.map(({fileToken}) => fileToken))
                    this.setState({
                        status: data.message,
                        error: "",
                        [this.fieldName]: data.file_name,
                        loading: false, renew: false
                    });
                }
            })
            .catch((error) => {
                console.log("error", error);
                //this.setState({loading: false})
                this.setState({
                    error: error.message,
                    loading: false,
                    status: ""
                })
            });
    };
    handleChange = (e) => {

        const s = e.target.name.split("_");
        this.setState({
            [s[0]]: Object.assign(
                {},
                this.state[s[0]],
                {[s[1]]: e.target.type === 'checkbox' ? e.target.checked : e.target.value}
            )
        });

    };

    handleSubmit = (event) => {
        event.preventDefault();
    };

    render() {

        return <>
            <Status type={"error"} text={this.state.error}/>
            <form id={"profile_picture_form"} onChange={this.handleChange} onSubmit={this.handleSubmit}>
                <div id="excel_file">
                    <label>
            <span>
            Fotos (PNG / JPG)
            </span>
                        <input
                            multiple
                            style={{paddingTop: "20px"}}
                            type={"file"}
                            ref={this.fileField}
                        />
                        {this.state.loading &&
                        <label>
                            <img alt={"loader"}
                                 src="https://uid-suche.eu/src/img/ajax-loader-fff.gif"/> {this.state.progress} % {this.state.progress === 100 &&
                        <span>(verarbeite Fotos)</span>}
                        </label>
                        }
                    </label>
                    <MaxBtn onClick={this.saveFile(() => {
                    })}>Fotos hochladen</MaxBtn>
                </div>
            </form>
        </>

    }
}
