import React, {useMemo, useState} from "react";
import {maxiGet} from "../core/maxios";
import {LightContainer, MyReactTable} from "../core/input_fields";
import Status from "../core/status";
import {Loader} from "../core/components";
import {dateFormatTime} from "../core/dateFuncs";
import {Link} from "react-router-dom";
import {PersonEditWrapper} from "../user/personHelpers";

export default function ShowOffersOfPerson({event, registration, person}) {
    const [registrations, setRegistrations] = useState([]);
    const [{error, loading, success}, setStatusVar] = useState({});
    const loadRegistration = () => maxiGet(person ? `/registration/per_person/${event.ID}/${person.ID}` : `/registration/${event.ID}/${registration.ID}/booking_overview`, {setStatusVar}).then(data => {
        setRegistrations(data.registrations)
    })
    useMemo(loadRegistration, []);
    const anyTeam = registrations.some(a => !!a.registered_person?.teamName);

    return <LightContainer name={"Optionen" + (person && registrations.length > 0 ? " für " + registrations[0].person.fullname : "")}>
        <Status type={"error"} text={error}/>
        <Loader loading={loading}/>
        <MyReactTable
            data={registrations}
            loading={loading}
            defaultSorted={[
                {id: "role"},
                {id: "person.fullname"},
                {id: "offer.description"},
            ]}
            columns={[
                {
                    Header: "Option",
                    accessor: "offer.description",
                    filterable: true,
                },
                {
                    Header: "Art",
                    id: "role",
                    accessor: "registered_person.role.label",
                    filterable: true,
                    maxWidth: 100,
                },
                {
                    Header: "Team",
                    show: anyTeam,
                    accessor: "registered_person.teamName",
                    filterable: true,
                },
                {
                    Header: "Person",
                    accessor: "person.fullname",
                    filterable: true,
                    show: !person,
                },
                {
                    Header: "Registrant",
                    accessor: "owner.fullname",
                    filterable: true,
                },
                {
                    Header: "Gebucht",
                    accessor: "registered_person_offer.timeAdded",//data => data.registration.timeFinalized ? dateFormatTime(new Date(data.registration.timeFinalized)) : "nicht abgeschickt",
                    id: "timeFinalized",
                    filterable: true,
                    Cell: ({value}) => value ? dateFormatTime(new Date(value)) : "nicht abgeschickt"
                },
                {
                    Header: "",
                    maxWidth: 100,
                    Cell: ({original}) => <>
                        <Link to={"/anmeldungen/" + original.registration.ID}>anschauen</Link>
                    </>,
                }

            ]}
        />
    </LightContainer>
}
