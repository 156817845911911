import React, {Component} from "react";

export default function asyncComponent(importComponent) {
    class AsyncComponent extends Component {
        state = {
            component: null
        };

        async componentDidMount() {
            const {default: component} = await importComponent();

            this.setState({
                component: component
            });
        }

        render() {
            const C = this.state.component;

            return C ? <C {...this.props} /> : null;
        }
    }

    return AsyncComponent;
}

// works well but is a mess with loading css, could be used for some heavy components in the future but not at this stage.
// code from https://serverless-stack.com/chapters/code-splitting-in-create-react-app.html (linked on react website) - 20.11.2019