import React, {useContext, useMemo, useState} from "react";

import {Container, Loader, MaxBtn} from "../core/components";
import {maxiGet, maxiPost} from "../core/maxios";
import Status from "../core/status";
import {dateFormatLong, dateFormatTime, timeFormat} from "../core/dateFuncs";
import "./style.sass"
import {CheckboxInput, LightContainer, MyModal} from "../core/input_fields";
import {Link} from "react-router-dom";
import BillingAddressList from "./billingAddressList";
import {AddressPrinterFull} from "../core/helpers";
import {RegisteredPersonsList} from "./registrationPersonTeamMod";
import OffersShow from "./offersShow";
import {RegistrationStatusMap} from "../core/enums";
import {UserContext} from "../user/UserContext";
import UserHistory from "../user/userHistory";
import {FormContextWrapper} from "../core/form_context";
import {downloadAsPdf} from "../core/download";

function checkNonFreeRecursive(offer) {
    if (offer.price > 0) {
        return true
    }
    if (Array.isArray(offer) || Array.isArray(offer.suboffers)) {
        return (Array.isArray(offer) ? offer : offer.suboffers).some(a => checkNonFreeRecursive(a))
    }
}


export default function RegistrationView({match}) {
    const user = useContext(UserContext)?.user || {}

    const registration_ID = match?.params?.registration_ID;
    const [event, setEvent] = useState(null);
    const [registration, setRegistration] = useState(null);
    const [offers, setOffers] = useState([]);
    const [registeredPersons, setRegisteredPersons] = useState([]);
    const [billingAddress, setBillingAddress] = useState([]);
    const [eventPermitted, setEventPermitted] = useState(false);
    const [eventNonFree, setEventNonFree] = useState(false);
    const [club, setClub] = useState({});
    const [{error, loading, success}, setStatusVar] = useState({loading: true});


    const loadRegistration = () => {
        maxiGet(`/registration/${registration_ID}`, {setStatusVar}).then(({event, offers, registeredPersons, registration, billingAddress, permitted, club}) => {
            setOffers(offers)
            setEventPermitted(permitted)
            setRegisteredPersons(registeredPersons)
            setRegistration(registration)
            setBillingAddress(billingAddress)
            setClub(club)
            setEventNonFree(checkNonFreeRecursive(offers))
            setEvent({
                ...event,
                eventStart: new Date(event.eventStart),
                eventEnd: new Date(event.eventEnd),
                bookingStart: new Date(event.bookingStart),
                bookingEnd: new Date(event.bookingEnd),
                bookingOfficialsStart: new Date(event.bookingOfficialsStart),
                bookingOfficialsEnd: new Date(event.bookingOfficialsEnd),
                prebookingEnd: new Date(event.prebookingEnd),
            })
        })
    }

    const [registeredPersonOffers, setRegisteredPersonOffers] = useState({});
    const [sum, setSum] = useState(null);

    const loadRegistrationOffers = () => {
        maxiGet(`/registration/${registration.ID}/registered_person_offer`, {setStatusVar}).then(({registered_person_offer, sum}) => {
            setRegisteredPersonOffers(registered_person_offer)
            setSum(sum)
        })
    }
    useMemo(() => {
        loadRegistration()
    }, [])


    return <Container name={"Veranstaltung "} addName={event?.name}>

        <Status type={"error"} text={error}/>
        <Loader loading={loading}/>
        {
            event && <EventPrint event={event}/>
        }
        <h2 style={{marginTop: 20}}>Anmeldung für {club.name}</h2>
        {
            registration && event && <>

                {
                    /*registration.editable ? <>
                            Diese Registrierung wurde bereits abgeschickt. Um Sie zu bearbeiten klicke unten auf <i>Wieder öffnen</i>!
                        </> :
                        <div style={{color: "red"}}>
                            Diese Registrierung wurde noch nicht abgeschickt, somit ist die Anmeldung noch nicht abgeschlossen. Bitte unten auf <i>Meldung Abschicken</i> gehen.
                        </div>*/
                }
                {
                    eventNonFree && <BillingAddressSelect {...{registration, reload: loadRegistration, billingAddress, club}}/>
                }
                <Finalize registration={registration} reload={loadRegistration}/>
                <RegisteredPersonsList {...{
                    event, registeredPersons, registration, eventPermitted, reload: () => {
                        loadRegistration()
                        loadRegistrationOffers()
                    }
                }}/>
                <OffersShow {...{
                    registration, registeredPersons, offers, event, eventPermitted, loadRegistrationOffers: () => {
                        loadRegistration()
                        loadRegistrationOffers()
                    }, registeredPersonOffers, sum
                }}/>

                {
                    user.role === 100 &&
                    <UserHistory userID={registration_ID} personType={"registration"}/>
                }
            </>
        }
    </Container>
}

function Finalize({registration, reload}) {
    const [{error, loading, success}, setStatusVar] = useState({});
    const [state, setState] = useState({user: {}})
    const finalize = () => {
        if (state.user.dataprotection !== 1) {
            return setStatusVar({error: "Bitte zuerst die Datenschutzbestimmungen lesen!"})
        }
        if (loading) {
            alert("Diese Anmeldung wird gerade verarbeitet.")
            return
        }

        setStatusVar(e => ({...e, loading: true}))
        maxiPost(`/finalize`, {registration_ID: registration.ID}, {setStatusVar}).then(resp => {
            setStatusVar(o => ({...o, success: "Vielen Dank fürs Akzeptieren der Bedingungen!"}))
            setTimeout(() => {
                reload()
                setStatusVar(o => ({...o, success: ""}))
            }, 5000)
            //reload() // would reload such that the success message is not visible anymore
        })
    }

    return <>
        <Status type={"success"} text={success}/>
        <Status type={"error"} text={error}/>
        {
            registration.status.value === RegistrationStatusMap.draft &&
            <LightContainer name={"Fertigstellen"}>
                <br/>


                <FormContextWrapper
                    value={{state, setState: a => setState(b => ({...b, ...a})), updateState: (a, b, c) => setState(old => ({...old, [a]: {...old[a], [b]: c}}))}}
                    onSubmit={e => e.preventDefault()}
                >
                    <div style={{clear: "both"}}>
                        <div style={{paddingBottom: "", float: "left", paddingRight: "10px"}}>
                            <CheckboxInput name={"akzeptiere"} tag={"user_dataprotection"} noLabel/>
                        </div>
                        <div>
                            <br/>
                            Hiermit bestätige ich, dass ich die <b><a href={"/static/Erklärung%20Meldeverantwortliche.doc"}>Datenschutzbestimmungen</a></b> gelesen und zur Kenntnis genommen habe.
                        </div>
                    </div>
                    <br/>
                </FormContextWrapper>
                <MaxBtn onClick={finalize}>Bedingungen akzeptieren</MaxBtn>

                <br/><br/>

            </LightContainer>
        }
        {
            registration.invoiceNumber && <p>
                <em onClick={() => maxiGet("/invoice/" + registration.ID).then(({content}) => downloadAsPdf(content, "Rechnung_M" + registration.invoiceNumber + ".pdf"))}>Rechnung {registration.invoiceNumber} herunterladen</em>
            </p>
        }
        {
            /*registration.status.value === RegistrationStatusMap.finalized && <MaxBtn onClick={() => {
                maxiPost(`/finalize/reopen`, {registration_ID: registration.ID}, {setStatusVar}).then(() => {
                    reload()
                })
            }}>Wieder öffnen</MaxBtn>*/
        }

        <Loader loading={loading}/>

    </>
}

const now = new Date();

function EventPrint({event}) {
    const {city, eventStart, eventEnd, noteForBookers} = event

    return <div>
        {city}, {eventStart && dateFormatLong(eventStart)}
        {
            eventEnd && (eventEnd.getDate() !== eventStart.getDate() ?
                    <> bis {dateFormatLong(eventEnd)}</> :
                    `, ${timeFormat(eventStart)} bis ${timeFormat(eventEnd)} Uhr`
            )
        }
        {now < event.bookingEnd && <>, Anmeldung bis {dateFormatTime(event.bookingEnd)} Uhr</>}<br/>
        <Link to={"/veranstaltungen/" + event.ID}>mehr anzeigen</Link>
        {!!noteForBookers && <div dangerouslySetInnerHTML={{__html: noteForBookers}}/>}
    </div>
}


function BillingAddressSelect({registration, reload, billingAddress, club}) {
    const [{error, loading, success}, setStatusVar] = useState({});

    const handleSubmit = (ba, close) => {
        !loading && maxiPost(`/registration/${registration.ID}/change_billingAddress`, {billingAddress_ID: ba.ID}, {setStatusVar})
            .then(() => {
                reload()
                close()
            })
    }
    return <LightContainer smallName={"Rechnungsadresse auswählen"}>
        <div>
            {billingAddress && <AddressPrinterFull {...billingAddress}/>}
            {!billingAddress && <>Keine ausgewählt</>}
        </div>
        {
            registration.editable && <MyModal trigger={<MaxBtn>Rechnungsadresse ändern</MaxBtn>}>
                {close => <>
                    <Status type={"error"} text={error}/>
                    <BillingAddressList
                        nameCell={({original, value}) => <em onClick={() => {
                            handleSubmit(original, close)
                        }}>{value} (auswählen)</em>}
                        error={""}
                        club_ID={club.ID}
                        loadPersons={() => null}
                        match={{}}
                        loading={false}
                    />
                </>
                }
            </MyModal>
        }
    </LightContainer>
}
