import React, {useContext, useMemo, useState} from "react";
import {Container, MaxBtn} from "../core/components";
import {maxiGet, maxiPost} from "../core/maxios";
import {EnumInput, LightContainer, MyModal, MyReactTable} from "../core/input_fields";
import {Link} from "react-router-dom";
import {UserContext} from "./UserContext";
import {FaTimes} from "react-icons/all";
import {FormContextWrapper} from "../core/form_context";
import {PermissionLevel} from "../core/enums";
import {PersonEditWrapper} from "./personHelpers";
import Status from "../core/status";
import {downloadAsExcel} from "../core/download";
import {dateFormatTime} from "../core/dateFuncs";
import {ClubEdit} from "./clubEdit";
import {Club} from "../core/custom";


export function ListClubs({match}) {
    const user = (useContext(UserContext) || {}).user || {}

    const [users, setUsers] = useState([]);
    const [loading, setLoading] = useState(true);
    const load = () => maxiGet(
        `https://${Club.domain ?? 'meldung.turnfest.at'}/api/clubs`,
        {noApi: true}
    )
        .then(({clubs}) => {
                setLoading(false)
                setUsers(clubs)
            }
        );
    useMemo(load, []);

    return <Container name={"Alle Vereine "}>

        <MyReactTable
            loading={loading}
            data={users}
            pageSizeDef={500}
            columns={[
                {
                    Header: "Name",
                    accessor: "1",
                    filterable: true,
                    //Cell: ({value, original}) => window.embeddedMode ? <a href={"https://meldung.turnfest.at/vereine/" + original[0]} target={"_blank"} rel={"noopener noreferrer"}>{value}</a>:<Link to={"/vereine/" + original[0]}>{value}</Link>
                    Cell: ({value, original}) => window.embeddedMode ? <MyModal trigger={<em>{value}</em>}><ClubEdit club={{ID: original[0]}}/> </MyModal> : <Link to={"/vereine/" + original[0]}>{value}</Link>
                    //filterMethod: () => true,
                },
                {
                    Header: "Anzahl an Personen",
                    accessor: "2",
                    filterable: true,
                    show: user?.role > 80,
                    /*Cell: ({value, original}) => <MyModal trigger={<em>{value} Personen</em>}>
                        <ListPerClubAndRole club={{ID: original[0], "name": original[1]}}/>
                    </MyModal>,
                    //filterMethod: () => true,
                },
                {
                    Header: <FaEdit/>,
                    show: user.role === 100,
                    maxWidth: 70,
                    Cell: ({original}) => <Link to={"/vereine/"+original[0]}><FaEdit/></Link>,*/
                }
            ]}
        />
    </Container>
}


export function ListPerClubAndRole({club, defaultPermission}) {
    const [{error, loading, success}, setStatusVar] = useState({});
    const [state, setState] = useState({person: {permission: defaultPermission || {}}})
    const [persons, setPersons] = useState([]);
    const [personIds, setPersonIds] = useState([]);

    const loadPersons = () => maxiGet(`/clubs/${club.ID}/permission/${state.person.permission.value}`, {setStatusVar}).then(({persons}) => setPersons(persons));
    useMemo(loadPersons, [club.ID, state.person.permission.value])
    return <LightContainer name={"Personen"}>
        <FormContextWrapper value={{state, setState}}>
            <Status type={"error"} text={error}/>
            <div style={{float: "right"}}>
                <MaxBtn onClick={() => maxiPost("/person/download", personIds, {setStatusVar}).then(({data}) => {
                    downloadAsExcel(data, `Personen_${club.name}_${dateFormatTime(new Date())}.xlsx`)
                })}>Herunterladen</MaxBtn>
            </div>
            <EnumInput name={"Berechtigung"} type={"reactselect"} tag={"person_permission"} selectives={PermissionLevel}/>
            &nbsp;
            <MaxBtn onClick={() => setState({person: {permission: {}}})}><FaTimes/></MaxBtn>


            <MyReactTable
                data={persons}
                onTableChange={a => {
                    setPersonIds(a.map(b => b._original.ID))
                }}
                columns={[
                    {
                        Header: "Name",
                        accessor: "fullname",
                        filterable: true,
                    },
                    {
                        Header: "Vereine",
                        accessor: "clubs",
                        filterable: true,
                    },
                    {
                        Header: "Geburtsdatum",
                        accessor: "dateOfBirth",
                        maxWidth: 100,
                        filterable: true,
                        //Cell: ({value}) => value ? dateFormatDateInline(new Date(value)) : "",
                    },
                    {
                        maxWidth: 250,
                        Cell: ({original, value}) => <>
                            <PersonEditWrapper personLight={original} reload={loadPersons}><em>bearbeiten</em></PersonEditWrapper>
                            <em onClick={() => {
                                if (window.confirm("Person überall löschen?")) {
                                    maxiPost("/person/hide", {ID: original.ID}).then(loadPersons)
                                }
                            }
                            }>löschen</em>
                        </>
                    }
                ]}
            />
            <br/>
            <br/>
            <br/>
            <br/>

            <br/>
            <br/>
            <br/>
            <br/>
        </FormContextWrapper>
    </LightContainer>
}

