import React, {useState} from "react";
import {FormContextWrapper} from "../core/form_context";
import {SelectfieldInput} from "../core/input_fields";
import {MaxLink} from "../core/components";
import Consts from "../core/consts";
import {encodeGetParams} from "../core/helpers";

export function DownloadLists({offers, event, judgeMode, umlaufMode, squads, umlauf}) {
    const [state, setState] = useState({filter: {}});
    return <>
        {
            !judgeMode && event.isCompetition === 2 && <FormContextWrapper value={{state, setState}}>
                <SelectfieldInput inline name={"Wettkampf"} type={"reactselect"} tag={"filters_competition"} selectives={offers?.filter(a => a.isCompetitionPart).map(a => ({value: a.ID_ext, label: a.description}))}/>
            </FormContextWrapper>
        }

        {
            /*judgeMode || event.isCompetition === 2 && */<>
            <MaxLink target={"_blank"} to={Consts.API_PREFIX + `/competition/riegenlisten/${event.ID}/per_squad?${encodeGetParams({
                competition_ID: state?.filters?.competition,
                judgeMode,
                umlaufMode,
                umlauf,
            })}`}>{(umlaufMode ? "Umlaufzuordnung" : (judgeMode ? "Kampfrichterzuordnung" : "Riegenzuordnung"))} herunterladen</MaxLink>
            &nbsp;
        </>
        }
        {
            !judgeMode && !umlaufMode && <>
                <MaxLink target="_blank" to={Consts.API_PREFIX + (event.isCompetition === 2 ?
                    `/competition/riegenlisten/${event.ID}/per_apparatus?${encodeGetParams({competition_ID: state?.filters?.competition})}` :
                    `/turnr/event/${event.ID}/reports/squads?${encodeGetParams({rotation: 0, competition: event.eventNoExt, squads: squads?.filter && squads?.filter(a => a.id !== "None").map(a => a.id).join(",")})}`)
                }>Wertungsblätter Riege</MaxLink>
                &nbsp;
                <MaxLink target="_blank" to={Consts.API_PREFIX + (event.isCompetition === 2 ?
                    `/competition/riegenlisten/${event.ID}/per_apparatus?${encodeGetParams({competition_ID: state?.filters?.competition})}` :
                    `/turnr/event/${event.ID}/reports/squads?${encodeGetParams({rotation: 0, competition: event.eventNoExt, per_person: true, squads: squads?.filter && squads?.filter(a => a.id !== "None").map(a => a.id).join(",")})}`)
                }>Wertungsblätter Person</MaxLink>
                &nbsp;
                {
                    event.isCompetition === 1 && <>
                        <MaxLink target="_blank" to={Consts.API_PREFIX + (
                            `/turnr/event/${event.ID}/reports/squads?${encodeGetParams({rotation: 0, competition: event.eventNoExt, per_team: true})}`)
                        }>Wertungsblätter Team</MaxLink>
                        &nbsp;
                    </>
                }
            </>
        }

        <MaxLink target={"_blank"} to={Consts.API_PREFIX + `/competition/riegenlisten/${event.ID}/per_club?${encodeGetParams({competition_ID: state?.filters?.competition, judgeMode, umlaufMode, umlauf})}`}>
            {judgeMode ? "Kampfrichter" : "Teilnehmer"} nach Verein mit {umlaufMode ? "Umlauf" : (judgeMode ? "Kampfgericht" : "Riegen")}zugehörigkeit
        </MaxLink>

    </>
}
