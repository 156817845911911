import React, {useMemo, useState} from "react";
import {maxiGet} from "../core/maxios";
import {LightContainer, MyModal, MyReactTable} from "../core/input_fields";
import {Container, MaxBtn} from "../core/components";
import Status from "../core/status";
import {Link} from "react-router-dom";
import {downloadAsExcel, downloadAsZip} from "../core/download";
import {dateFormatTime, dateFormatTimeShort} from "../core/dateFuncs";
import {PersonEditWrapper} from "../user/personHelpers";
import ShowOffersOfPerson from "./eventOffersOfPerson";
import {PersonTypeMap, RegistrationStatusMap} from "../core/enums";
import {FaArrowCircleRight, FaCheck, FaCopy} from "react-icons/all";
import {CopyToClipboard} from "react-copy-to-clipboard";
import {FaList} from "react-icons/fa";

function CopyEmailAddresses({registrations, personMode}) {
    const [includeParts, setIncludeParts] = useState(true)
    const emails = registrations
        .filter(({registered_person}) => registered_person?.role?.value === PersonTypeMap.official || includeParts)
        .map(({person, owner}) => personMode ? person : owner)
        .filter(({email}) => !!email)
        .reduce((obj, curr) => ({...obj, [curr.email]: curr.fullname}), {})
    const text = Object.keys(emails).filter(([email,]) => !!email)
    const [copied, setCopied] = useState(false);
    return <Container name={"E-Mail-Adresse " + (personMode ? "Teilnehmern" : "Meldeverantwortlichen") + " kopieren"}>

        {
            personMode && <MaxBtn onClick={() => setIncludeParts(!includeParts)}>Teilnehmer {!includeParts ? "inkludieren" : "ausschließen"}</MaxBtn>
        }<br/>

        <CopyToClipboard text={text.join("; ")} onCopy={() => {
            setCopied(true);
            window.setTimeout(() => setCopied(false), 5000)
        }}>
            <MaxBtn>Kopieren {copied ? <FaCheck/> : <FaCopy/>} ({text.length})</MaxBtn>
        </CopyToClipboard>
        <br/>
        <br/>
        <MyReactTable
            data={Object.entries(emails).filter(([email,]) => !!email).map(([email, name]) => ({email, name}))}
            columns={[
                {
                    Header: "Name",
                    filterable: true,
                    accessor: "name"
                },
                {
                    Header: "E-Mail",
                    filterable: true,
                    accessor: "email"
                }
            ]}
        />
    </Container>
}

export function EventRegistrationList({event}) {

    const [registrations, setRegistrations] = useState([]);
    const [showMode, setShowMode] = useState("persons");
    const personMode = showMode === "persons";
    const registrationMode = showMode === "registrations";
    const teamMode = showMode === "teams";
    const [{error, loading, success}, setStatusVar] = useState({});
    const [tableCount, setTableCount] = useState(null);
    const loadRegistration = (per_person) => () => event.ID && maxiGet(`/registration/per_event/${per_person}/${event.ID}`, {setStatusVar}).then(data => {
        setRegistrations(data.registrations)
        setShowMode(per_person)
    })
    useMemo(loadRegistration(showMode), [event.ID]);

    const anyTeam = registrations?.some(a => !!a.registered_person?.teamName || !!a.count_teams) && !registrationMode;

    const tableCountLocal = tableCount || [registrations.filter(a => a.registered_person?.role?.value === "official").length, registrations.filter(a => a.registered_person?.role?.value !== "official").length]
    const tableCountTeams = Object.keys(registrations.filter(a => a.registered_person?.team_ID).reduce((obj, curr) => ({...obj, [curr.registered_person?.team_ID]: true}), {})).length
    const tableCountClubs = Object.keys(registrations.filter(a => a.clubs).reduce((obj, curr) => ({...obj, [curr.clubs]: true}), {})).length


    return <LightContainer name={

        {
            persons: `Registrierungen (${tableCountLocal[1]} Teilnehmer / ${tableCountLocal[0]} ${event.allowOfficialsLabel || "Offizielle"}${tableCountTeams > 0 ? " / " + tableCountTeams + " Teams" : ""}${tableCountClubs > 0 ? " / " + tableCountClubs + " Vereine" : ""})`,
            registrations: `Registrierungen (${tableCountLocal[1]} Registrierungen, ${tableCountClubs} Vereine)`,
            teams: `Registrierungen (${tableCountLocal[1]} Teams, ${tableCountClubs} Vereine)`,
        }[showMode]
    } addName>

        <div>
            <MyModal trigger={<MaxBtn>E-Mail</MaxBtn>}>
                <CopyEmailAddresses {...{personMode, registrations}}/>
            </MyModal>&nbsp;

            {
                showMode !== "persons" && <><MaxBtn onClick={loadRegistration("persons")}>
                    Personen anzeigen
                </MaxBtn> &nbsp;</>
            }
            {
                showMode !== "registrations" && <><MaxBtn onClick={loadRegistration("registrations")}>
                    Registrierungen anzeigen
                </MaxBtn> &nbsp;</>
            }
            {
                showMode !== "teams" && anyTeam && <><MaxBtn onClick={loadRegistration("teams")}>
                    Teams anzeigen
                </MaxBtn> &nbsp;</>
            }


            <MaxBtn onClick={() => window.confirm("Rechnungen generieren und herunterladen (nicht speichern)?") && maxiGet("/finalize/create_invoices?event_ID=" + event.ID, {setStatusVar}).then((data) => {
                downloadAsZip(data.content, `Rechnungen_${event.name}_${dateFormatTime(new Date())}.zip`)
            })}>
                Rechnung generieren
            </MaxBtn>&nbsp;
            <MaxBtn onClick={() => window.confirm("Rechnungen generieren, versenden und herunterladen (wird gespeichert)?") && maxiGet("/finalize/create_invoices?send=1&event_ID=" + event.ID, {setStatusVar}).then((data) => {
                downloadAsZip(data.content, `Rechnungen_${event.name}_${dateFormatTime(new Date())}.zip`)
            })}>
                Rechnung versenden
            </MaxBtn>&nbsp;
            <MaxBtn onClick={() => maxiGet("/registration/per_event_download/" + event.ID, {setStatusVar}).then(({data}) => {
                downloadAsExcel(data, `Teilnehmer_${event.name}_${dateFormatTime(new Date())}.xlsx`)
            })}>
                Anmeldungen herunterladen
            </MaxBtn>
        </div>
        <Status type={"error"} text={error}/>
        <MyReactTable
            data={registrations}
            loading={loading}
            defaultSorted={[{id: "person.fullname"}]}
            onTableChange={b => setTableCount([b.filter(a => a._original.registered_person?.role?.value === "official").length, b.filter(a => a._original.registered_person?.role?.value !== "official").length])}
            columns={[
                {
                    Header: "Person",
                    accessor: "person.fullname",
                    show: personMode,
                    filterable: true,
                    Cell: ({original, value}) => <PersonEditWrapper {...{personLight: original.person, reload: loadRegistration(showMode)}}><em>{value}</em></PersonEditWrapper>
                },
                {
                    Header: "Art",
                    accessor: "registered_person.role.label",
                    show: personMode,
                    filterable: true,
                    Cell: ({value}) => value && value[0] || "",
                    maxWidth: 35,
                },
                {
                    Header: "Team",
                    show: anyTeam,
                    accessor: "registered_person.teamName",
                    filterable: true,
                },

                {
                    Header: "Registrant",
                    accessor: "owner.fullname",
                    filterable: true,
                    Cell: ({original, value}) => <PersonEditWrapper {...{personLight: original.owner, reload: loadRegistration(showMode)}}><em>{value}</em></PersonEditWrapper>

                },
                {
                    Header: "Teilnehmer",
                    accessor: "count_participant",
                    width: 80,
                    show: !personMode,
                    Cell: ({original, value})=><>{value} ({original.count_participantW}/{original.count_participantM})</>
                },
                {
                    Header: "Teams",
                    accessor: "count_teams",
                    maxWidth: 80,
                    show: !personMode && registrations?.some(a => a.count_teams),
                },
                {
                    Header: event.allowOfficialsLabel,
                    accessor: "count_official",
                    maxWidth: 80,
                    show: !personMode && registrations?.some(a => a.count_official),
                },
                {
                    Header: "Vereine",
                    accessor: "clubs",
                    filterable: true,
                },
                {
                    Header: "Wettkampfteil",
                    accessor: "offer",
                    show: teamMode,
                    filterable: true,
                },
                {
                    Header: "",
                    accessor: "offerCount",
                    filterable: true,
                    maxWidth: 100,
                    Cell: ({original}) => <>
                        <Link to={"/anmeldungen/" + original.registration.ID}><FaArrowCircleRight/></Link>
                        &nbsp;
                        {
                            personMode && <MyModal trigger={<em><FaList/> ({original.offerCount || 0})</em>}>
                                <ShowOffersOfPerson event={event} registration={original.registration} person={original.person}/>
                            </MyModal>
                        }
                    </>
                }

            ]}
        />
    </LightContainer>
}
