import React, {useContext, useMemo, useState} from "react";

import {Container, Loader, MaxBtn, MaxLink} from "../core/components";
import {DateInput, LightContainer, MyModal, MyReactTable} from "../core/input_fields";
import {maxiGet, maxiPost} from "../core/maxios";
import Status from "../core/status";
import {Link} from "react-router-dom";
import {RegistrationStatusMap} from "../core/enums";
import {UserContext} from "../user/UserContext";
import {PersonEditWrapper} from "../user/personHelpers";
import {FaDownload} from "react-icons/fa";
import {downloadAsExcel, downloadAsPdf} from "../core/download";
import {FormContextWrapper} from "../core/form_context";
import {encodeGetParams} from "../core/helpers";


export default function RegistrationList({event_ID}) {
    const [registrations, setRegistrations] = useState([]);
    const userContext = useContext(UserContext);
    const [{error, loading, success}, setStatusVar] = useState({loading: true});
    const loadRegistrations = () => {
        maxiGet("/registration?" + encodeGetParams({event_ID}), {setStatusVar}).then(data => {
            setRegistrations(data.registrations)
        })
    }
    useMemo(loadRegistrations, []);
    const LocalContainer = event_ID ? LightContainer : Container
    return <LocalContainer name={"Anmeldungen"}>
        {
            !event_ID && <div style={{float: "right", marginTop: "-50px"}}>
                {
                    userContext?.user?.role > 80 && <>
                        <MyModal trigger={<MaxBtn>Rechnungen herunterladen</MaxBtn>}>
                            <DownloadInvoices/>
                        </MyModal>&nbsp;
                    </>
                }
                <MaxLink to={"/veranstaltungen"}>Veranstaltungen anzeigen</MaxLink>
            </div>
        }
        <Status type={"error"} text={error}/>
        <MyReactTable
            data={registrations}
            loading={loading}
            pageSizeDef={400}
            defaultSorted={[{id: "registration.timeAdded", desc: true}]}
            columns={[
                {
                    Header: "Veranstaltung",
                    accessor: "event.name",
                    filterable: true,
                    Cell: ({value, original}) => <Link to={"/anmeldungen/" + original.registration.ID}>{value}</Link>
                },
                {
                    Header: "Registrant",
                    accessor: "owner.fullname",
                    filterable: true,
                    Cell: ({original, value}) => <PersonEditWrapper personLight={original.owner} reload={loadRegistrations}><em>{value}</em></PersonEditWrapper>
                },
                {
                    Header: "Verein",
                    accessor: "club.name",
                    filterable: true,
                },
                {
                    Header: "Anz.",
                    accessor: "count",
                    maxWidth: 47,
                },
                {
                    Header: "",
                    Cell: ({original}) => <>
                        {
                            original.registration.status.value === RegistrationStatusMap.finalized && original.registration.invoiceNumber && <>
                                Rg. M{original.registration.invoiceNumber} &nbsp;
                                <FaDownload style={{cursor: "pointer"}} onClick={() => maxiGet("/invoice/" + original.registration.ID).then(({content}) => downloadAsPdf(content, "Rechnung_M" + original.registration.invoiceNumber + ".pdf"))}/>
                            </>
                        }
                        {
                            original.registration.status.value === RegistrationStatusMap.draft && original.registration.timeFinalized === null &&
                            <em onClick={() => {
                                if (window.confirm("Anmeldung abbrechen und verwerfen")) {
                                    maxiPost("/registration/hide", {registration_ID: original.registration.ID}, {setStatusVar}).then(loadRegistrations)
                                }
                            }
                            }>abbrechen</em>
                        }
                    </>
                }
            ]}
        />
    </LocalContainer>
}


function DownloadInvoices({}) {
    const [state, setState] = useState({});
    const [{error, loading}, setStatusVar] = useState({});
    return <Container name={"Rechnungen herunterladen"}>
        <FormContextWrapper onSubmit={e => e.preventDefault()} value={{state, setState}}>
            <Status type={"error"} text={error}/>
            <DateInput tag={"from"} maxDate={state.till} name={"Von"}/><br/>
            <DateInput tag={"till"} minDate={state.from} name={"Bis"}/><br/>
        </FormContextWrapper>
        <MaxBtn onClick={() => maxiGet("/invoice/csv?" + encodeGetParams(state), {setStatusVar}).then(a => downloadAsExcel(a.content, "Rechnungen.xlsx"))}>Herunterladen</MaxBtn><Loader loading={loading}/>
    </Container>
}
