import React, {useMemo, useState} from 'react';
import {apiGet, apiPost} from "../core/api";

import "react-datepicker/dist/react-datepicker.css";
import {Container, LightContainer, Loader, MaxBtn, MyModal, MyModalConv, MyReactTable, TagsInput, TextareaInput} from "../core/input_fields";
import Status from "../core/status";
import {dateFormatTimeShort} from "../core/dateFuncs";
import {Link} from "react-router-dom";
import {FormContextWrapper} from "../core/form_context";
import {maxiGet} from "../core/maxios";
import Select, {components} from "react-select";

const routeMap = {
    "course": "kurs",
};

export default class UserHistory extends React.Component {
    state = {
        loading: true,
        history: [],
        cats: [],
        search: {
            cat: [],
        },
        tags: {},
        viewMode: parseInt(localStorage.getItem("viewMode") || 0),
    };

    constructor(props) {
        super(props);
        this.apiGet = apiGet.bind(this);
        //this.apiPost = apiPost.bind(this);
        this.setState = this.setState.bind(this);
        this.loadData()

    }

    loadData = (viewMode = this.state.viewMode) => {
        const {personType, userID} = this.props;
        this.apiGet(`/user/history/get/${userID}/0?viewMode=${viewMode}&personType=${(personType || "user")}`, (resp) => {
            const cats = resp.history.reduce((obj, h) => {
                if (obj.indexOf(h.history.type) === -1) {
                    return obj.concat(h.history.type)
                }
                return obj
            }, []);
            this.setState({...resp, loading: false, cats: cats});
        });
    };

    getTags = (tags) => {
        this.setState({
            tags: tags.reduce((obj, curr) => {
                obj[curr.value] = curr.label;
                return obj
            }, {})
        })
    };

    render() {
        const {history, search} = this.state;
        const {personType, userID} = this.props;
        const naTags = history.filter(h => isNaN(parseInt(h.history.type)));
        const {addTags} = naTags
            .map(h => ({value: h.history.type, label: prettify(h.history.type)}))
            .reduce((obj, h) => {
                if (obj.ids.indexOf(h.value) === -1) {
                    obj.ids = [...obj.ids, h.value];
                    obj.addTags = [...obj.addTags, h]
                }
                return obj

            }, {addTags: [], ids: []});

        const appearingCats = history.map(h => parseInt(h.history.type)).concat(naTags.map(h => h.history.type));

        //console.log(appearingCats)
        const selectedCats = !!search.cat ? search.cat.map(a => a.value) : [];
        //console.log({search, selectedCats})
        const historyFiltered = history.filter(h => {
            //console.log({type: parseInt(h.history.type), typeId: h.history.type})
            return selectedCats.length === 0 || selectedCats.indexOf(h.history.type) > -1 || selectedCats.indexOf(parseInt(h.history.type)) > -1
        });
        const subjectOrObject = (userID === "all" ? "object" : "subject");
        return <>
            <Status type={"error"} text={this.state.error}/>
            <Loader loading={this.state.loading}/>
            {
               /*userID !== "all" &&
                <>
                    <HistoryAdd child_ID={userID} personType={personType} reload={this.loadData}/>
                </>*/
            }

            {
                history.length > 0 &&
                <>
                    <FormContextWrapper
                        style={{width: "calc(100% - 200px)"}}
                        value={{
                            state: this.state,
                            setState: this.setState,
                            updateState: (a, b, c) => this.setState({[a]: {...this.state[a], [b]: c}})
                        }}
                        afterUpdate={(root, child, value) => {
                            if (root === "viewMode") {
                                localStorage.setItem("viewMode", value);
                                this.loadData(value)
                            }
                        }
                        }
                    >
                        <TagsInput name={"Kategorie"} noLabel multiple entity={"history"} addTags={addTags} appearingTags={appearingCats} pushTags={this.getTags} ID={0} tag={"search_cat"}/>
                    </FormContextWrapper>
                    <MyReactTable
                        data={historyFiltered}
                        columns={[
                            {
                                Header: "Name",
                                filterable: true,
                                accessor: "history.description",
                                Cell:  this.props.textCell || (row => {
                                    let value = row.value;
                                    if (row.original.type === "message") {
                                        value = value.replace(/<([a-z]+)_([0-9]+)>/g, (all, entity, ID) => {
                                            //console.log(all, entity, ID);
                                            return <a href={"/" + routeMap[entity] + "/" + ID}>{this.state.course_names[ID]}</a>
                                        })
                                    }

                                    const content = value.split("\n").map(l => [l, <br/>]);
                                    const subcontent = ((row.original || {}).linked_messages || []).concat({
                                        ...row.original.history,
                                        status: row.original.history.description
                                    });

                                    return <MyModal trigger={content}>
                                        <LightContainer name={"Text"}>
                                            {content}
                                            {(row.original.history || {}).type === "login" && <> (<PrettifyIPAddress ip={(value).split(" von ")[1]}/>)</>}
                                        </LightContainer>

                                        {
                                            (row.original.newsletter || {}).subject &&
                                            <LightContainer name={"Newsletter"}>
                                                {row.original.newsletter.subject}
                                            </LightContainer>
                                        }

                                        {
                                            this.state.viewMode === 1 && !!subcontent && subcontent.length > 1 &&
                                            <LightContainer name={"Vorhergehende Nachrichten"}>
                                                <MyReactTable
                                                    data={subcontent}
                                                    defaultSorted={[{id: "time", desc: true}]}
                                                    columns={[
                                                        {
                                                            Header: "Zeit",
                                                            accessor: "time",
                                                            maxWidth: 300,
                                                            minWidth: 200,
                                                            Cell: row => !!row.value && dateFormatTimeShort(row.value)
                                                        }, {
                                                            Header: "Name",
                                                            accessor: "status",
                                                            minWidth: 300,
                                                            maxWidth: 500
                                                        },
                                                        {
                                                            Header: "Kategorie",
                                                            accessor: "type",
                                                            maxWidth: 300,
                                                            minWidth: 200,
                                                            Cell: ({value, original}) => !isNaN(parseInt(value)) && !!this.state.tags[value] ? this.state.tags[value] : prettify(original.type)
                                                        }
                                                    ]}/>
                                            </LightContainer>

                                        }
                                    </MyModal>
                                }),

                            },
                            {
                                Header: "Kategorie",
                                filterable: true,
                                accessor: "history.type",
                                maxWidth: 230,
                                Filter: () => <Select
                                    isMulti={true}
                                    multi={true}
                                    onChange={entry => {
                                        this.setState({search: {cat: entry}});
                                    }}
                                    components={{MultiValueContainer}}
                                    closeMenuOnSelect={false}
                                    options={this.state.cats.map(c => ({label: prettify(c), value: c}))}
                                    styles={{
                                        menuList: (base) => ({...base, zIndex: 100}),
                                        menu: (base) => ({...base, zIndex: 100, position: 'relative'})
                                    }}
                                    className="multi-select-with-see-more"
                                    classNamePrefix="select"
                                    menuPosition={"fixed"}
                                    placeholder={"Suche"}
                                    autosize={false}
                                    value={this.state.search.cat}
                                />
                                ,
                                Cell: ({value, original}) => !isNaN(parseInt(value)) && !!this.state.tags[value] ? this.state.tags[value] : prettify(original.history.type)
                            },
                            {
                                Header: "Wer",
                                filterable: true,
                                show: true,
                                accessor: subjectOrObject + ".fullname",
                                maxWidth: 250,
                            },
                            {
                                Header: "Zeit",
                                filterable: true,
                                maxWidth: 150,
                                accessor: "history.time",
                                Cell: row => !!row.value && dateFormatTimeShort(row.value)
                            },
                        ]
                        }
                        defaultSorted={[
                            {
                                id: "history.time",
                                desc: true
                            }
                        ]}
                    />
                </>
            }
        </>
    }

    /*toggleViewMode = () => {
    const viewMode = this.state.viewMode === 0 ? 1 : 0;
    this.setState({viewMode}, this.loadData);
    localStorage.setItem("viewMode", viewMode);
}*/
}

// modification for multi value selects, where after the first element a "N mehr" entry should follow.
const MultiValueContainer = props => {
    // Shows the first value element full text and beginning with the second shows "N mehr"
    return props.selectProps.value[0].value === props.data.value && (
            <span>
            <components.MultiValueContainer {...props} />
        </span>
        )
        || props.selectProps.value[1].value === props.data.value && (
            <span>
                <span style={{
                    "margin-left": "5px",
                    "font-size": "12px"
                }}>+ {(props.selectProps.value.length) - 1} mehr</span>
            </span>
        );
};

// Prettifies category titles and translates predefined category titles to their german equivalent.
const prettify = s => {
    const transformations = {
        'email_coursereg': 'Email-Kursanmeldung',
        'pwreset': 'Passwort zurücksetzen',
        'pwreset_req': 'Passwort Zur. Angefragt',
        'role_add': 'Neue Rolle',
        'user_picture': 'Benutzerbild',
        'bev_update': 'BEV Update',
        'role_mod': 'Rolle modifiziert',
        'email_userreg': 'Benutzerregistrierung (Mail)',
        'email_paymentreminder': 'Zahlungserinnerung (Mail)',
        'user_data_change': 'Benutzerdatenänderung',
        'event_data_change': 'Veranstaltungsänderung',
        'club_data_change': 'Vereinsdatenänderung',
        'offer_data_change': 'Optionsdatenänderung',
        'offer_moved': 'Options-Verschiebung',
        'ba_data_change': 'Rechnungsadressänderung',
        'team_data_change': 'Teamänderung',
        'team_person_added': "Team Person hinzu",
        'team_person_deleted': "Team Person entfernt",
        'registered_person_added': "Person hinzu",
        'registered_team_added': "Team hinzu",
        'registration_person_added': "Person hinzu",
        'registration_reopened': "Wieder geöffnet",
        'registration_finalized': "abgeschickt",
        'registration_ba_changed': "Rechnungsadresse geändert",
        'registered_person_deleted': "Person entfernt",
        'registered_person_offer_added': "Person zu Option hinzugefügt",
        'registered_person_offer_deleted': "Person von Option entfernt",
        'registered_team_offer_deleted': "Team von Option entfernt",
        'registration_person_deleted': "Person entfernt",
        'registration_team_added': "Team hinzu",
        'permission_granted': "Berechtigung erteilt",
        'perm_added': "Berechtigung erteilt",
        'club_added': "Verein hinzugefügt",
        'club_removed': "Verein entfernt",
        'permission_revoked': "Berechtigung entzogen",
        'judgeW_added': 'Stufe W hinzugefügt',
        'judgeM5_added': 'Stufe M5 hinzugefügt',
        'judgeM7_added': 'Stufe M7 hinzugefügt',
        'judgeChW_added': 'Stufe ChW hinzugefügt',
        'judgeChM_added': 'Stufe ChM hinzugefügt',
        'judgeChM7_added': 'Stufe Ch7 hinzugefügt',
        'judges_added': 'Kampfrichter importiert',
        'role_added': "Position hinzugefügt",
        'role_changed': "Position verändert",
    }

    for (var index in transformations) {
        if (s === index) {
            return transformations[index];
        }
    }
    return s.split("_").map(a => (a[0] || "").toUpperCase() + a.substr(1)).join(" ");
}

class HistoryAdd extends React.Component {

    setState = this.setState.bind(this);
    state = {
        new: {
            child_ID: this.props.child_ID,
        }
    };
    apiGet = apiGet.bind(this);
    apiPost = apiPost.bind(this);

    handleSubmit = (e, close) => {
        const {personType} = this.props;
        e.preventDefault();
        this.apiPost("/user/history/add?personType=" + personType, this.state.new, () => {
            this.setState({new: {child_ID: this.props.child_ID}});
            this.props.reload();
            close()
        })
    };

    render() {
        return <MyModalConv
            trigger={<MaxBtn style={{float: "right", marginTop: "0px", marginLeft: "20px", marginRight: 0}}>Eintrag
                Hinzufügen</MaxBtn>}>
            {close =>
                <Container name={"Eintrag in Geschichte Hinzufügen"}>
                    <FormContextWrapper value={{state: this.state, setState: this.setState}}
                                        onSubmit={e => this.handleSubmit(e, close)}>
                        <TextareaInput name="Text" tag={"new_text"}/><br/>
                        <TagsInput name={"Kategorie"} entity={"history"} ID={0} tag={"new_category"}/>
                        <MaxBtn>Hinzufügen</MaxBtn>
                    </FormContextWrapper>
                </Container>
            }
        </MyModalConv>
    }
}


function PrettifyIPAddress({ip}) {
    const [prettyIP, setPrettyIP] = useState(null);
    useMemo(() => {
        maxiGet("/user/history/reverse_ip?ip=" + ip).then(({hostname}) => {
            setPrettyIP(hostname)
        })
    }, [ip]);
    return prettyIP || "..."
}
