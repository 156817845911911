export const EventStatus=[{"value": "open", "label": "offen"}]
export const EventStatusMap={"open": "open"}
export const EventStatusLabelMap={"open": "offen"}
export const PersonType=[{"value": "participant", "label": "Teilnehmer"}, {"value": "official", "label": "Offizieller"}]
export const PersonTypeMap={"participant": "participant", "official": "official"}
export const PersonTypeLabelMap={"participant": "Teilnehmer", "official": "Offizieller"}
export const OfferType=[{"value": "mandatory", "label": "Pflicht"}, {"value": "optional", "label": "optional"}, {"value": "selection", "label": "Auswahl"}]
export const OfferTypeMap={"mandatory": "mandatory", "optional": "optional", "selection": "selection"}
export const OfferTypeLabelMap={"mandatory": "Pflicht", "optional": "optional", "selection": "Auswahl"}
export const RegistrationStatus=[{"value": "draft", "label": "Entwurf"}, {"value": "finalized", "label": "fertig"}]
export const RegistrationStatusMap={"draft": "draft", "finalized": "finalized"}
export const RegistrationStatusLabelMap={"draft": "Entwurf", "finalized": "fertig"}
export const PermissionEntity=[{"value": "person", "label": "Person"}, {"value": "event", "label": "Veranstaltung"}, {"value": "env", "label": "env"}]
export const PermissionEntityMap={"person": "person", "event": "event", "env": "env"}
export const PermissionEntityLabelMap={"person": "Person", "event": "Veranstaltung", "env": "env"}
export const PermissionLevel=[{"value": "admin", "label": "Administrator"}, {"value": "eventReader", "label": "nur Leseberechtigung"}, {"value": "eventWriter", "label": "Lese- und Schreibberechtigung"}, {"value": "eventAllPersons", "label": "Zugriff auch auf Personen"}, {"value": "registrant", "label": "Meldeverantwortlicher"}, {"value": "clubAdmin", "label": "Vereinsverantwortlicher"}]
export const PermissionLevelMap={"admin": "admin", "eventReader": "eventReader", "eventWriter": "eventWriter", "eventAllPersons": "eventAllPersons", "registrant": "registrant", "clubAdmin": "clubAdmin"}
export const PermissionLevelLabelMap={"admin": "Administrator", "eventReader": "nur Leseberechtigung", "eventWriter": "Lese- und Schreibberechtigung", "eventAllPersons": "Zugriff auch auf Personen", "registrant": "Meldeverantwortlicher", "clubAdmin": "Vereinsverantwortlicher"}
