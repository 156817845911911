import React from "react";
import {BlankLink, Container, InfoTooltip, Loader, MaxBtn, MaxLink} from "../core/components";
import {FormContextWrapper} from "../core/form_context";
import {apiGet, apiPost} from "../core/api";
import Status from "../core/status";
import {CheckboxInput, DateInput, EnumInput, InputContainer, LightContainer, SelectfieldInput, TagsInput, TextfieldInput} from "../core/input_fields";
import Consts from "../core/consts";
import {FaCheckCircle} from "react-icons/all";
import {Club} from "../core/custom";
import {PersonType} from "../core/enums";
import ChangeAddressExt from "./addressExt";
import VereinID from "./vereinid";
import {Link} from "react-router-dom";

const fieldsRequired = ["forename", "surname", "sex", "dateOfBirth"];

export default class UserEdit extends React.Component {

    setState = this.setState.bind(this);
    apiGet = apiGet.bind(this);
    apiPost = apiPost.bind(this);

    constructor(props) {
        super(props);
        const registerMode = this.props.location?.pathname === "/registrieren"
        this.state = {
            user: registerMode ?
                {
                    country: "AT"
                } :
                null,
            loading: !registerMode,
            registerMode,
        };

    }

    componentDidMount = () => {
        if (!this.state.registerMode) {
            this.apiGet("/user" + (this.props.user_ID ? "/" + this.props.user_ID : ""), ({user}) => {
                this.setState({user}, () => {
                    this.checkPostalCode()
                })
            })
        }
    };

    handleSubmit = (e) => {
        e.preventDefault();
        //console.log(this.state, this.props, this.state[this.props.id])
        if (this.state.registerMode) {
            if (this.state.user.dataprotection !== 1) {
                return this.setState({error: "Bitte zuerst die Datenschutzbestimmungen lesen!"})
            }
        }
        this.setState({loading: true});
        this.apiPost(this.state.registerMode ? "/user/register" : "/user", this.state.user, resp => {
            this.setState({
                success: this.state.registerMode ? "Du hast dich erfolgreich registriert und hast ein E-Mail mit weiteren Anweisungen bekommen" : "Die Daten wurden erfolgreich gespeichert."
            })
            this.props.reload && this.props.reload()
        })
    };
    checkPhone = (e) => {
        const s = e.target.name.split("_");
        const val = this.state[s[0]][s[1]];
        if (s[1].startsWith("phone")) {

            const newVal = Consts.phoneNormalise(val)
            if (newVal !== val) {
                this.setState({[s[0]]: {...this.state[s[0]], [s[1]]: newVal}})
                //console.log(val, newVal)
            }
        }
        if (s[1] === "email") {
            this.apiPost("/user/address/email", {email: val}, resp => {
                if (resp.email === (this.state[s[0]] || {}).email) {
                    this.setState({emailValid: resp.valid})
                }
            })
        }
    };
    checkPostalCode = (e) => {
        if (this.state.user.country !== "AUT" || !this.state.user.postalCode) {
            return
        }
        this.apiGet("/postalCode/" + this.state.user.postalCode, resp => {
            this.setState({postalCode: resp.postalCode})
        })
    };

    render() {
        const {user, error, success, loading, postalCode, registerMode} = this.state;
        return <Container name={registerMode ? "Für die ÖTB OÖ Meldeplattform registrieren" : "Benutzerdaten bearbeiten"}>
            <Status type={"error"} text={error}/>
            <Status type={"success"} text={success}/>
            <Loader loading={loading}/>
            {
                (user || registerMode) && (!registerMode || !success) && <FormContextWrapper value={{state: this.state, setState: this.setState}} onSubmit={this.handleSubmit}>
                    {
                        ["sex", "forename", "surname", "dateOfBirth", "email", "phone", "taID"]
                            .map((f, i) => {
                                switch (f) {
                                    case "country":
                                        return <InputContainer>
                                            <SelectfieldInput name={"Land"} tag={"user_" + f} selectives={[["AUT", "Österreich"], ["GER", "Deutschland"]]}/>
                                            {i % 2 === 0 && <br/>}
                                        </InputContainer>
                                    case "type":
                                        return [<InputContainer>
                                            <EnumInput name={"Art"} tag={"user_" + f} type="reactselect" selectives={PersonType}/>
                                        </InputContainer>, <br/>]
                                    case "sex":
                                        return [<InputContainer>
                                            <SelectfieldInput name={"Geschlecht*"} tag={"user_" + f} demandSelect selectives={[["0", "Weiblich"], ["1", "Männlich"]]}/>
                                        </InputContainer>, <br/>]
                                    case "email":
                                        return <InputContainer>
                                            <TextfieldInput
                                                onBlur={this.checkPhone}
                                                inputBorderColor={this.state.emailValid === false ? "red" : null}
                                                name={<>{Consts.translate(f)}* {this.state.emailValid === true && <FaCheckCircle/>}{this.state.emailValid === false && "(falsch)"}</>}
                                                tag={"user_" + f}
                                            />
                                            {(i % 2 === 1) && <br/>}
                                        </InputContainer>
                                    case "taID":
                                        return <InputContainer>
                                            <TextfieldInput
                                                name={<>{Consts.translate(f)} <InfoTooltip>Zu finden auf mein.turnsport.at unter Turnsport-Austria Lizenzen</InfoTooltip></>}
                                                labelWidth={176}
                                                labelStyle={{paddingRight: 8}}
                                                tag={"user_" + f}
                                                ph={"123456789"}
                                            />
                                            {(i % 2 === 1) && <br/>}
                                        </InputContainer>
                                    case "phone":
                                        return <InputContainer>
                                            <TextfieldInput
                                                ph={Consts.placeHolders[f]}
                                                onBlur={this.checkPhone}
                                                name={Consts.translate(f)}
                                                tag={"user_" + f}
                                            />
                                            {(i % 2 === 1) && <br/>}
                                        </InputContainer>
                                    /*case "dateOfBirth":
                                        return <InputContainer>
                                            <DateInput name={Consts.translate(f)+"*"} tag={"user_" + f}/>
                                            {(i % 2 === 1) && <br/>}
                                        </InputContainer>*/

                                    case "postalCode":
                                        return <>
                                            <TextfieldInput name={Consts.fieldTranslation[f] || f} tag={"user_" + f}/>
                                            {i % 2 === 1 && <br/>}
                                        </>
                                    default:
                                        return <InputContainer>
                                            <TextfieldInput ph={Consts.placeHolders[f]} name={(Consts.fieldTranslation[f] || f) + (fieldsRequired.includes(f) ? "*" : "")} tag={"user_" + f}/>
                                            {i % 2 === 1 && <br/>}
                                        </InputContainer>
                                }
                            })
                    }
                    <br/>
                    <TagsInput multiple name={"Verein"} entity={"club"} ID={0} tag={"user_clubs"}/>
                    <ChangeAddressExt required child={user} changeAddress={a => {
                        this.setState(old => ({user: {...old.user, ...a}}))
                    }}/>
                    <br/>
                    {
                        registerMode && [<div style={{clear: "both"}}>
                            <div style={{paddingBottom: "", float: "left", paddingRight: "10px"}}>
                                <CheckboxInput name={"akzeptiere"} tag={"user_dataprotection"} noLabel/>
                            </div>
                            <div>
                                <br/>
                                Hiermit bestätige ich, dass ich die <b><a href={"/static/Erklärung%20Meldeverantwortliche.doc"}>Datenschutzbestimmungen</a></b> gelesen und zur Kenntnis genommen habe.
                            </div>
                        </div>,/*
                            <br/>,
                            <div style={{clear: "both"}}>
                                <div style={{paddingBottom: "", float: "left", paddingRight: "10px"}}>
                                    <CheckboxInput name={"akzeptiere"} tag={"user_communication"} noLabel/>
                                </div>
                                <br/>
                                <div>
                                    <h2>Einwilligung {Club.fullName} Informationen</h2>
                                    Ja, ich möchte in Zukunft Informationen über {Club.fullName} Veranstaltungen, Wettkämpfe, Aus- und Fortbildungen.
                                    <br/>
                                    <br/>

                                </div>
                            </div>*/]
                    }
                    <br/>
                    <MaxBtn>{registerMode ? "Registrieren" : "Speichern"}</MaxBtn>
                </FormContextWrapper>
            }
            {
                this.props.profil && <>
                    <LightContainer name={"Meine Vereine ansehen"}>
                        {
                            user?.clubs?.map(({label, value}) => <Link to={"/vereine/" + value}>{label}</Link>)
                        }
                    </LightContainer>
                    <VereinID/>

                </>
            }

        </Container>
    }
}
